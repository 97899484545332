@charset "UTF-8";

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  list-style: none;
  scroll-behavior: smooth !important;
  /* font-family: "Poppins",sans-serif !important; */
}

html {
  scroll-behavior: smooth !important;
}

body {
  color: #1a1a1a !important;
  background-color: #fff;
  font-size: 16px;
  font-family: "Poppins", sans-serif;

  overflow-x: hidden !important;
}

body.crev-agency h1,
body.crev-agency h2,
body.crev-agency h3,
body.crev-agency h4,
body.crev-agency h5,
body.crev-agency h6 {
  font-weight: 400 !important;
}

body.crev-portfolio main {
  padding: 0 20px;
}

body.crev-portfolio section {
  position: relative;
}

body.crev-portfolio section .sec-head .fixed-head {
  position: absolute;
  left: 0;
  top: 0;
  padding: 15px 40px;
}

body.crev-portfolio section .sec-head .fixed-head .shap-right-top {
  position: absolute;
  top: -5px;
  right: -1.05rem;
}

body.crev-portfolio section .sec-head .fixed-head .shap-right-top svg {
  width: 1.1rem;
  height: 1.1rem;
}

body.crev-portfolio section .sec-head .fixed-head .shap-left-bottom {
  position: absolute;
  bottom: -1.05rem;
  left: 0;
}

body.crev-portfolio section .sec-head .fixed-head .shap-left-bottom svg {
  width: 1.1rem;
  height: 1.1rem;
}

body.crev-portfolio section .sec-head .fixed-head h5 {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 35px;
}

body.vcard .section-padding {
  padding: 100px 0;
}

main {
  position: relative;
  z-index: 5;
}

.row > * {
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 0;
}

.row.sm-marg {
  margin-left: -5px;
  margin-right: -5px;
}

.row.sm-marg > * {
  padding-left: 5px;
  padding-right: 5px;
}

.row.stand-marg {
  margin-left: -20px;
  margin-right: -20px;
}

.row.stand-marg > * {
  padding-left: 20px;
  padding-right: 20px;
}

.row.md-marg {
  margin-left: -25px;
  margin-right: -25px;
}

.row.md-marg > * {
  padding-left: 0px;
  padding-right: 25px;
}

.row.lg-marg {
  margin-left: -40px;
  margin-right: -40px;
}

.row.lg-marg > * {
  padding-left: 40px;
  padding-right: 40px;
}

.row.xlg-marg {
  margin-left: -50px;
  margin-right: -50px;
}

.row.xlg-marg > * {
  padding-left: 50px;
  padding-right: 50px;
}

.row.xxlg-marg {
  margin-left: -80px;
  margin-right: -80px;
}

.row.xxlg-marg > * {
  padding-left: 80px;
  padding-right: 80px;
}

.container-xxl {
  max-width: 1500px;
}

.container-xl {
  max-width: 1400px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 0.2rem;
}

h1 {
  font-size: 60px;
}

h2 {
  font-size: 38px;
}

h3 {
  font-size: 35px;
}

h4 {
  font-size: 25px;
}

h5 {
  font-size: 18px !important;
}

h6 {
  font-size: 18px;
}

p {
  line-height: 1.8;
  font-size: 15px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  color: #777;
  margin: 0 !important;
  text-align: justify;
}

a,
a:hover {
  color: inherit !important;
}

span {
  display: inline-block;
}

a {
  display: inline-block !important;
  text-decoration: none !important;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

figure {
  margin: 0;
}

.main-color {
  color: #3a4197 !important;
}

.main-colorbg {
  background: #3a4197 !important;
}

.main-color2 {
  color: #caff33 !important;
}

.main-colorbg2 {
  background: #caff33 !important;
}

.main-bg {
  background: #fff !important;
}

.sub-bg {
  background: #f5f7f9 !important;
}

.sub-bg2 {
  background: #eee !important;
}

.alt-bg {
  background: #eee !important;
}

.btn:hover,
.btn:focus,
.btn:active {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.lines {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.lines.two:after {
  content: "";
  position: absolute;
  top: 0;
  left: 30%;
  width: 40%;
  height: 100%;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.lines.four {
  left: 5%;
  width: 90%;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  border-left: 1px solid rgba(0, 0, 0, 0.05);
}

.lines.four:after {
  content: "";
  position: absolute;
  top: 0;
  left: 33.3333%;
  width: 33.3333%;
  height: 100%;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  border-left: 1px solid rgba(0, 0, 0, 0.05);
}

.lines.two-out {
  left: 60px;
  right: 60px;
  width: auto;
  border-right: 1px solid rgba(255, 255, 255, 0.05);
  border-left: 1px solid rgba(255, 255, 255, 0.05);
  z-index: 7;
}

.line-overlay {
  position: absolute;
  top: -10%;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.line-overlay.revers {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.line-overlay.up {
  top: -25%;
}

.line-overlay svg path {
  stroke: #ddd;
}

.in-box {
  width: calc(100% - 30px);
  margin: auto;
}

.blur-left-top {
  position: absolute;
  top: -120px;
  left: 0;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  pointer-events: none;
}

.blur-right-top {
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transform: translateX(50%) translateY(-50%);
  -ms-transform: translateX(50%) translateY(-50%);
  transform: translateX(50%) translateY(-50%);
  pointer-events: none;
}

.bg-pattern {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.bg-pattern.bg-repeat {
  background-repeat: repeat;
}

.noise {
  -webkit-animation: grain 8s steps(10) infinite;
  animation: grain 8s steps(10) infinite;
  background-image: url(../imgs/noise.png);
  content: "";
  height: 300%;
  left: -50%;
  opacity: 0.4;
  position: absolute;
  top: -100%;
  width: 300%;
  pointer-events: none;
}

@-webkit-keyframes grain {
  0%,
  to {
    -webkit-transform: translate(0);
    transform: translate(0);
  }

  10% {
    -webkit-transform: translate(-5%, -10%);
    transform: translate(-5%, -10%);
  }

  20% {
    -webkit-transform: translate(-15%, 5%);
    transform: translate(-15%, 5%);
  }

  30% {
    -webkit-transform: translate(7%, -25%);
    transform: translate(7%, -25%);
  }

  40% {
    -webkit-transform: translate(-5%, 25%);
    transform: translate(-5%, 25%);
  }

  50% {
    -webkit-transform: translate(-15%, 10%);
    transform: translate(-15%, 10%);
  }

  60% {
    -webkit-transform: translate(15%);
    transform: translate(15%);
  }

  70% {
    -webkit-transform: translateY(15%);
    transform: translateY(15%);
  }

  80% {
    -webkit-transform: translate(3%, 35%);
    transform: translate(3%, 35%);
  }

  90% {
    -webkit-transform: translate(-10%, 10%);
    transform: translate(-10%, 10%);
  }
}

@keyframes grain {
  0%,
  to {
    -webkit-transform: translate(0);
    transform: translate(0);
  }

  10% {
    -webkit-transform: translate(-5%, -10%);
    transform: translate(-5%, -10%);
  }

  20% {
    -webkit-transform: translate(-15%, 5%);
    transform: translate(-15%, 5%);
  }

  30% {
    -webkit-transform: translate(7%, -25%);
    transform: translate(7%, -25%);
  }

  40% {
    -webkit-transform: translate(-5%, 25%);
    transform: translate(-5%, 25%);
  }

  50% {
    -webkit-transform: translate(-15%, 10%);
    transform: translate(-15%, 10%);
  }

  60% {
    -webkit-transform: translate(15%);
    transform: translate(15%);
  }

  70% {
    -webkit-transform: translateY(15%);
    transform: translateY(15%);
  }

  80% {
    -webkit-transform: translate(3%, 35%);
    transform: translate(3%, 35%);
  }

  90% {
    -webkit-transform: translate(-10%, 10%);
    transform: translate(-10%, 10%);
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
  background: #888;
  /*  border-radius: 40px;*/
}

/* Buttons
-----------------------------------------------------------------*/
.butn {
  border: 1px solid transparent;
}

.butn.butn-md {
  padding: 14px 35px;
}

.butn.butn-full {
  width: 100%;
  padding: 15px 25px;
  text-align: center;
}

.butn.butn-bord {
  border-color: #1a1a1a;
  background: transparent;
  color: #1a1a1a;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.butn.butn-bord:hover {
  background: #1a1a1a;
  color: #fff;
}

.butn.butn-bord:hover span {
  color: #fff;
}

.butn.butn-bord-thin {
  border-color: rgba(0, 0, 0, 0.12);
  background: transparent;
  color: #1a1a1a;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.butn.butn-bord-thin:hover {
  background: #1a1a1a;
  color: #fff;
}

.butn.butn-bord-thin:hover span {
  color: #fff;
}

.butn.butn-bg {
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.butn.butn-bg:hover {
  background: transparent !important;
  border-color: #1a1a1a;
  color: #1a1a1a !important;
}

.butn .rolling-text {
  margin-bottom: -5px;
}

.btn-circle {
  /*  border-radius: 50%;*/
  border: 1px solid #fff;
  width: 105px;
  height: 105px;
  position: relative;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.btn-circle:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*  border-radius: 50%;*/
  background: #3a4197;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.btn-circle:hover {
  border-color: #3a4197;
}

.btn-circle:hover:after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.btn-circle:hover span {
  color: #1c1c1c;
}

.btn-circle span {
  position: relative;
  z-index: 2;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.btn-circle span:last-of-type {
  display: block;
  padding-left: 10px;
}

.btn-circle.btn-md {
  width: 120px;
  height: 120px;
}

.btn-circle.btn-lg {
  width: 140px;
  height: 140px;
}

.butn-circle-text .text {
  margin-right: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
}

.butn-circle-text .circle {
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.3);
  /*  border-radius: 50%;*/
}

.butn-circle-text .circle svg {
  width: 22px;
  height: 22px;
}

.rmore.to-in {
  margin-left: -100px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.rmore.to-in:hover {
  margin-left: 0;
}

.go-more {
  position: relative;
  padding: 0 20px;
}

.go-more img {
  width: 25px;
}

.go-more:after {
  content: "";
  position: absolute;
  right: 5px;
  top: -10px;
  width: 43px;
  height: 43px;
  /*  border-radius: 40px;*/
  background: rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.go-more .icon {
  margin-left: 15px;
  bottom: -2px;
  position: relative;
  font-size: 18px;
}

.go-more:hover:after {
  width: 93%;
  left: 0;
}

.vew-all {
  position: relative;
  padding: 50px 0 50px 50px;
  z-index: 3;
}

.vew-all:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100px;
  height: 100px;
  /*  border-radius: 50%;*/
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-right-color: transparent;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  z-index: -1;
}

.vew-all:hover:after {
  -webkit-transform: translateY(-50%) rotate(360deg);
  -ms-transform: translateY(-50%) rotate(360deg);
  transform: translateY(-50%) rotate(360deg);
}

/* Cursor
-----------------------------------------------------------------*/
.hover-this .hover-anim {
  pointer-events: none;
  -webkit-transition: -webkit-transform 0.2s linear;
  transition: -webkit-transform 0.2s linear;
  -o-transition: transform 0.2s linear;
  transition: transform 0.2s linear;
  transition: transform 0.2s linear, -webkit-transform 0.2s linear;
}

.cursor {
  pointer-events: none;
  position: fixed;
  padding: 0.1rem;
  background-color: #fff;
  border-radius: 50%;
  mix-blend-mode: difference;
  -webkit-transition: opacity 0.4s ease, -webkit-transform 0.3s ease;
  transition: opacity 0.4s ease, -webkit-transform 0.3s ease;
  -o-transition: transform 0.3s ease, opacity 0.4s ease;
  transition: transform 0.3s ease, opacity 0.4s ease;
  transition: transform 0.3s ease, opacity 0.4s ease,
    -webkit-transform 0.3s ease;
  z-index: 99999;
}

.cursor-active {
  -webkit-transform: translate(-50%, -50%) scale(20);
  -ms-transform: translate(-50%, -50%) scale(20);
  transform: translate(-50%, -50%) scale(20);
  opacity: 0.1;
}

.rolling-text {
  display: inline-block;
  overflow: hidden;
  line-height: 50px;
  height: 50px;
}

.rolling-text.butn {
  padding: 0 35px;
}

.social-text .rolling-text {
  line-height: 30px;
  height: 30px;
}

.rolling-text:hover .letter,
.rolling-text.play .letter {
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
}

.rolling-text .letter {
  display: inline-block;
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
  transition: -webkit-transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
  -o-transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
  transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
  transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1),
    -webkit-transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
}

.letter:nth-child(1) {
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}

.letter:nth-child(2) {
  -webkit-transition-delay: 0.015s;
  -o-transition-delay: 0.015s;
  transition-delay: 0.015s;
}

.letter:nth-child(3) {
  -webkit-transition-delay: 0.03s;
  -o-transition-delay: 0.03s;
  transition-delay: 0.03s;
}

.letter:nth-child(4) {
  -webkit-transition-delay: 0.045s;
  -o-transition-delay: 0.045s;
  transition-delay: 0.045s;
}

.letter:nth-child(5) {
  -webkit-transition-delay: 0.06s;
  -o-transition-delay: 0.06s;
  transition-delay: 0.06s;
}

.letter:nth-child(6) {
  -webkit-transition-delay: 0.075s;
  -o-transition-delay: 0.075s;
  transition-delay: 0.075s;
}

.letter:nth-child(7) {
  -webkit-transition-delay: 0.09s;
  -o-transition-delay: 0.09s;
  transition-delay: 0.09s;
}

.letter:nth-child(8) {
  -webkit-transition-delay: 0.105s;
  -o-transition-delay: 0.105s;
  transition-delay: 0.105s;
}

.letter:nth-child(9) {
  -webkit-transition-delay: 0.12s;
  -o-transition-delay: 0.12s;
  transition-delay: 0.12s;
}

.letter:nth-child(10) {
  -webkit-transition-delay: 0.135s;
  -o-transition-delay: 0.135s;
  transition-delay: 0.135s;
}

.letter:nth-child(11) {
  -webkit-transition-delay: 0.15s;
  -o-transition-delay: 0.15s;
  transition-delay: 0.15s;
}

.letter:nth-child(12) {
  -webkit-transition-delay: 0.165s;
  -o-transition-delay: 0.165s;
  transition-delay: 0.165s;
}

.letter:nth-child(13) {
  -webkit-transition-delay: 0.18s;
  -o-transition-delay: 0.18s;
  transition-delay: 0.18s;
}

.letter:nth-child(14) {
  -webkit-transition-delay: 0.195s;
  -o-transition-delay: 0.195s;
  transition-delay: 0.195s;
}

.letter:nth-child(15) {
  -webkit-transition-delay: 0.21s;
  -o-transition-delay: 0.21s;
  transition-delay: 0.21s;
}

.letter:nth-child(16) {
  -webkit-transition-delay: 0.225s;
  -o-transition-delay: 0.225s;
  transition-delay: 0.225s;
}

.letter:nth-child(17) {
  -webkit-transition-delay: 0.24s;
  -o-transition-delay: 0.24s;
  transition-delay: 0.24s;
}

.letter:nth-child(18) {
  -webkit-transition-delay: 0.255s;
  -o-transition-delay: 0.255s;
  transition-delay: 0.255s;
}

.letter:nth-child(19) {
  -webkit-transition-delay: 0.27s;
  -o-transition-delay: 0.27s;
  transition-delay: 0.27s;
}

.letter:nth-child(20) {
  -webkit-transition-delay: 0.285s;
  -o-transition-delay: 0.285s;
  transition-delay: 0.285s;
}

.letter:nth-child(21) {
  -webkit-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.progress-wrap {
  position: fixed;
  bottom: 30px;
  right: 30px;
  height: 44px;
  width: 44px;
  cursor: pointer;
  display: block;
  /*  border-radius: 50px;*/
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: all 400ms linear;
  -o-transition: all 400ms linear;
  transition: all 400ms linear;
  mix-blend-mode: difference;
}

.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.progress-wrap::after {
  position: absolute;
  font-family: "Font Awesome 5 Free";
  content: "\f077";
  text-align: center;
  line-height: 44px;
  font-size: 13px;
  font-weight: 900;
  color: #ccc;
  left: 0;
  top: 0;
  height: 44px;
  width: 44px;
  cursor: pointer;
  display: block;
  z-index: 1;
  -webkit-transition: all 400ms linear;
  -o-transition: all 400ms linear;
  transition: all 400ms linear;
}

.progress-wrap svg path {
  fill: none;
}

.progress-wrap svg.progress-circle path {
  stroke: #ccc;
  stroke-width: 4;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 400ms linear;
  -o-transition: all 400ms linear;
  transition: all 400ms linear;
}

/* Extra
-----------------------------------------------------------------*/
.d-rotate.animated .rotate-text {
  -webkit-transform: translate3d(0px, 0px, 0px) rotateX(0deg) scale(1);
  transform: translate3d(0px, 0px, 0px) rotateX(0deg) scale(1);
  opacity: 1;
}

.d-rotate .rotate-text {
  display: block;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: all 0.8s;
  -o-transition: all 0.8s;
  transition: all 0.8s;
  -webkit-transform-origin: 50% 0%;
  -ms-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  /* -webkit-transform: translate3d(0px, 50px, -50px) rotateX(-45deg) scale(0.95); */
  /* transform: translate3d(0px, 50px, -50px) rotateX(-45deg) scale(0.95); */
  opacity: 1;
  -webkit-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.d-rotate.animated .rotate-text {
  -webkit-transform: translate3d(0px, 0px, 0px) rotateX(0deg) scale(1);
  transform: translate3d(0px, 0px, 0px) rotateX(0deg) scale(1);
  opacity: 1;
}

.d-slideup .sideup-text {
  overflow: hidden;
  display: block;
}

.d-slideup .sideup-text:first-of-type .up-text {
  -webkit-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.d-slideup .sideup-text:nth-of-type(2) .up-text {
  -webkit-transition-delay: 0.6s;
  -o-transition-delay: 0.6s;
  transition-delay: 0.6s;
}

.d-slideup .sideup-text:nth-of-type(3) .up-text {
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}

.d-slideup .sideup-text:nth-of-type(4) .up-text {
  -webkit-transition-delay: 1.4s;
  -o-transition-delay: 1.4s;
  transition-delay: 1.4s;
}

.d-slideup .sideup-text:nth-of-type(5) .up-text {
  -webkit-transition-delay: 1.8s;
  -o-transition-delay: 1.8s;
  transition-delay: 1.8s;
}

.d-slideup .sideup-text .up-text {
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: all 0.8s;
  -o-transition: all 0.8s;
  transition: all 0.8s;
}

.d-slideup.animated .up-text {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.main-marq {
  position: relative;
  padding: 0;
}

.main-marq:after {
  position: absolute;
  top: -30px;
  bottom: -30px;
  left: -30px;
  right: -30px;
  pointer-events: none;
  background: -webkit-linear-gradient(
    right,
    #fff,
    rgba(255, 255, 255, 0) 220px,
    rgba(255, 255, 255, 0) calc(100% - 220px),
    #fff
  );
  background: -o-linear-gradient(
    right,
    #fff,
    rgba(255, 255, 255, 0) 220px,
    rgba(255, 255, 255, 0) calc(100% - 220px),
    #fff
  );
  background: linear-gradient(
    to left,
    #fff,
    rgba(255, 255, 255, 0) 220px,
    rgba(255, 255, 255, 0) calc(100% - 220px),
    #fff
  );
}

.main-marq.md-text .box .item h4 {
  font-size: 40px;
}

.main-marq.lrg .box .item h4 {
  font-size: 5vw;
}

.main-marq.xlrg .box .item {
  padding: 0 30px;
}

.main-marq.xlrg .box .item h4 {
  font-size: 8vw;
}

.main-marq .slide-har {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.main-marq .strok .item h4 {
  color: transparent !important;
  -webkit-text-stroke: 0.5px #1a1a1a;
}

.main-marq .strok .item h4 a {
  color: transparent !important;
  -webkit-text-stroke: 0.5px #1a1a1a;
}

.main-marq .non-strok .item h4 {
  color: #1a1a1a !important;
  -webkit-text-stroke: 0 !important;
}

.main-marq .non-strok .item h4 a {
  color: #1a1a1a !important;
  -webkit-text-stroke: 0 !important;
}

.main-marq .box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.main-marq .box .item {
  padding: 0 30px;
}

.main-marq .box .item h4 {
  white-space: nowrap;
  margin: 0;
}

.main-marq .box .item h4 .icon {
  margin-bottom: -15px;
}

.main-marq .box .item h2 {
  margin: 0;
}

.main-marq .box .item:nth-of-type(even) h4 {
  color: transparent;
  -webkit-text-stroke: 0.5px #1a1a1a;
}

.main-marq .box .item:nth-of-type(even) h4 a {
  color: transparent;
  -webkit-text-stroke: 0.5px #1a1a1a;
}

.main-marq .box:last-of-type .item:nth-of-type(even) h4 {
  color: #1a1a1a;
  -webkit-text-stroke: 0;
}

.main-marq .box:last-of-type .item:nth-of-type(even) h4 a {
  color: #1a1a1a;
  -webkit-text-stroke: 0;
}

.main-marq .box:last-of-type .item:nth-of-type(odd) h4 {
  color: transparent;
  -webkit-text-stroke: 1px #1a1a1a;
}

.main-marq .box:last-of-type .item:nth-of-type(odd) h4 a {
  color: transparent;
  -webkit-text-stroke: 1px #1a1a1a;
}

.dark-text {
  color: #000 !important;
}

.dark-text .box .item h4,
.dark-text .box:last-of-type .item:nth-of-type(even) h4 {
  color: #000 !important;
}

.dark-text .strok .box .item h4,
.dark-text .box .item:nth-of-type(even) h4,
.dark-text .box:last-of-type .item:nth-of-type(odd) h4 {
  -webkit-text-stroke: 1px #000;
}

.light-text {
  color: #fff;
}

.light-text .box .item h4,
.light-text .box:last-of-type .item:nth-of-type(even) h4 {
  color: #fff;
}

.light-text .box .item:nth-of-type(even) h4,
.light-text .box:last-of-type .item:nth-of-type(odd) h4 {
  -webkit-text-stroke: 1px #fff;
}

.slide-har {
  position: relative;
}

.slide-har.st1 .box {
  position: relative;
  -webkit-animation: slide-har 80s linear infinite;
  animation: slide-har 80s linear infinite;
}

.slide-har.st2 .box {
  position: relative;
  -webkit-animation: slide-har-revers 80s linear infinite;
  animation: slide-har-revers 80s linear infinite;
}

@-webkit-keyframes slide-har {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }

  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@keyframes slide-har {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }

  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@-webkit-keyframes slide-har-revers {
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }

  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@keyframes slide-har-revers {
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }

  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.main-marqv {
  position: relative;
  padding: 0;
}

.main-marqv:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: -webkit-linear-gradient(
    bottom,
    #f5f7f9,
    rgba(255, 255, 255, 0) 80px,
    rgba(255, 255, 255, 0) calc(100% - 80px),
    #f5f7f9
  );
  background: -o-linear-gradient(
    bottom,
    #f5f7f9,
    rgba(255, 255, 255, 0) 80px,
    rgba(255, 255, 255, 0) calc(100% - 80px),
    #f5f7f9
  );
  background: linear-gradient(
    to top,
    #f5f7f9,
    rgba(255, 255, 255, 0) 80px,
    rgba(255, 255, 255, 0) calc(100% - 80px),
    #f5f7f9
  );
  pointer-events: none;
}

.main-marqv .slide-vertical {
  overflow: hidden;
  height: 500px;
  margin: 0 auto;
  position: relative;
}

.main-marqv .slide-vertical:hover .box {
  -webkit-animation-play-state: paused !important;
  animation-play-state: paused !important;
}

.main-marqv .slide-vertical .box {
  position: relative;
  -webkit-animation: slide-vertical 50s linear infinite;
  animation: slide-vertical 50s linear infinite;
}

@-webkit-keyframes slide-vertical {
  0% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }

  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@keyframes slide-vertical {
  0% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }

  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

.imago {
  -webkit-clip-path: polygon(0 0, 0% 0, 0% 100%, 0 100%);
  clip-path: polygon(0 0, 0% 0, 0% 100%, 0 100%);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-filter: blur(5px);
  filter: blur(5px);
  -webkit-transition: -webkit-clip-path 0.8s ease-in-out 0.3s,
    -webkit-transform 0.8s ease-in-out 1.1s,
    -webkit-filter 0.5s ease-in-out 1.1s;
  transition: -webkit-clip-path 0.8s ease-in-out 0.3s,
    -webkit-transform 0.8s ease-in-out 1.1s,
    -webkit-filter 0.5s ease-in-out 1.1s;
  -o-transition: clip-path 0.8s ease-in-out 0.3s,
    transform 0.8s ease-in-out 1.1s, filter 0.5s ease-in-out 1.1s;
  transition: clip-path 0.8s ease-in-out 0.3s, transform 0.8s ease-in-out 1.1s,
    filter 0.5s ease-in-out 1.1s;
  transition: clip-path 0.8s ease-in-out 0.3s, transform 0.8s ease-in-out 1.1s,
    filter 0.5s ease-in-out 1.1s, -webkit-clip-path 0.8s ease-in-out 0.3s,
    -webkit-transform 0.8s ease-in-out 1.1s,
    -webkit-filter 0.5s ease-in-out 1.1s;
  position: relative;
}

.imago.animated {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-filter: blur(0px);
  filter: blur(0px);
}

.imago.simpl {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-filter: blur(0px);
  filter: blur(0px);
}

/* ====================== [ Start Helper Classes  ] ====================== */
.o-hidden {
  overflow: hidden;
}

.position-re {
  position: relative;
}

.position-abs {
  position: absolute;
}

.cursor-pointer {
  cursor: pointer;
}

.white {
  color: #ffffff !important;
}

.social-icons {
  padding-left: 10px !important;
}

.revers-x {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}

.revers-y {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.full-over {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ontop {
  position: relative;
  z-index: 7;
}

.rest {
  padding: 0 !important;
  margin: 0 !important;
}

img.out-box {
  width: 140%;
}

img.left {
  margin-left: -40%;
}

.no-bord {
  border: 0 !important;
}

.bord-thin-top {
  border-top: 1px solid rgba(0, 0, 0, 0.14);
  /* padding-bottom: 50px; */
}

.bord-thin-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.14);
}

.bord-thin-right {
  border-right: 1px solid rgba(0, 0, 0, 0.14);
}

.bord-thin-left {
  border-left: 1px solid rgba(0, 0, 0, 0.14);
}

.order-md-1,
.order-md-2,
.order-md-3,
.order-md-4 {
  -webkit-box-ordinal-group: inherit !important;
  -ms-flex-order: inherit !important;
  order: inherit !important;
}

.full-width {
  width: 100% !important;
}

.bg-img {
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-top {
  background-position: center top;
}

.bg-bottom {
  background-position: center bottom;
}

.bg-center {
  background-position: center center;
}

.bg-fixed {
  background-attachment: fixed;
}

.bg-repeat {
  background-size: auto;
  background-repeat: repeat;
}

.bg-auto {
  background-size: auto;
  background-repeat: no-repeat;
}

.section-ptb {
  padding: 80px 0;
}

.cmd-padding {
  padding: 0 10px;
}

.csm-padding {
  padding: 0 5px;
}

.head-pt {
  padding: 80px 0 0;
}

.head-pb {
  padding: 0 0 80px;
}

.no-shad {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.fit-img {
  overflow: hidden;
}

.fit-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

.circle {
  border-radius: 50%;
}

.circle-img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

.circle-30 {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  overflow: hidden;
}

.circle-40 {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  overflow: hidden;
}

.circle-50 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  line-height: 50px;
  overflow: hidden;
}

.circle-60 {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  line-height: 60px;
  overflow: hidden;
}

.circle-70 {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  text-align: center;
  line-height: 70px;
  overflow: hidden;
}

.circle-80 {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  text-align: center;
  line-height: 80px;
  overflow: hidden;
}

.circle-90 {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  text-align: center;
  line-height: 90px;
  overflow: hidden;
}

.circle-100 {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  text-align: center;
  line-height: 100px;
  overflow: hidden;
}

.circle-120 {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  text-align: center;
  line-height: 120px;
  overflow: hidden;
}

.nowrap {
  white-space: nowrap;
}

.unlist {
  list-style: none;
}

.underline a {
  text-decoration: underline !important;
}

.underline span {
  text-decoration: underline;
}

.undecro:hover {
  text-decoration: none;
}

.inline {
  display: inline-block;
}

.valign {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.horizontal-link li {
  display: inline-block;
}

.rest {
  margin: 0;
  padding: 0;
}

.plink {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.social.circle-bord a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border: 1px solid #eee;
  border-radius: 50%;
}

.primery-shadow {
  -webkit-box-shadow: 0px 8px 30px -4px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 8px 30px -4px rgba(0, 0, 0, 0.1);
}

/*-------------------------------------------------------
           icon Size
-------------------------------------------------------*/
.icon-img-20 {
  width: 20px;
}

.icon-img-30 {
  width: 30px;
}

.icon-img-40 {
  width: 40px;
}

.icon-img-50 {
  width: 50px;
}

.icon-img-60 {
  width: 60px;
}

.icon-img-70 {
  width: 70px;
}

.icon-img-80 {
  width: 80px;
}

.icon-img-90 {
  width: 90px;
}

.icon-img-100 {
  width: 100px;
}

.icon-img-120 {
  width: 120px;
}

/*-------------------------------------------------------
           Z-index
-------------------------------------------------------*/
.zindex-1 {
  z-index: 1;
}

.zindex-2 {
  z-index: 2;
}

.zindex-3 {
  z-index: 3;
}

.zindex-4 {
  z-index: 4;
}

.zindex-5 {
  z-index: 5;
}

.zindex-10 {
  z-index: 10;
}

.zindex-99 {
  z-index: 99;
}

/*-------------------------------------------------------
           opacity
-------------------------------------------------------*/
.opacity-05 {
  opacity: 0.05 !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-1 {
  opacity: 0.1 !important;
}

.opacity-2 {
  opacity: 0.2 !important;
}

.opacity-3 {
  opacity: 0.3 !important;
}

.opacity-4 {
  opacity: 0.4 !important;
}

.opacity-5 {
  opacity: 0.5 !important;
}

.opacity-6 {
  opacity: 0.6 !important;
}

.opacity-7 {
  opacity: 0.7 !important;
}

.opacity-8 {
  opacity: 0.8 !important;
}

.opacity-9 {
  opacity: 0.9 !important;
}

/*-------------------------------------------------------
           text color
-------------------------------------------------------*/
.text-white {
  color: #fff;
}

.text-black {
  color: #000;
}

.text-dark {
  color: #444;
}

.text-extra-light-gray {
  color: #b7b7b7;
}

.text-hover-inherit:hover {
  color: inherit !important;
}

.text-hover-light:hover {
  color: #fff !important;
}

/*-------------------------------------------------------
          font-weight
-------------------------------------------------------*/
.fw-100 {
  font-weight: 100 !important;
}

.fw-200 {
  font-weight: 200 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

/*-------------------------------------------------------
           font-size
-------------------------------------------------------*/
.fz-10 {
  font-size: 10px !important;
}

.fz-12 {
  font-size: 12px !important;
}

.fz-13 {
  font-size: 13px !important;
}

.fz-14 {
  font-size: 14px !important;
}

.fz-15 {
  font-size: 15px !important;
}

.fz-16 {
  font-size: 16px !important;
}

.fz-18 {
  font-size: 18px !important;
}

.fz-20 {
  font-size: 20px !important;
}

.fz-22 {
  font-size: 22px !important;
}

.fz-24 {
  font-size: 24px !important;
}

.fz-25 {
  font-size: 25px !important;
}

.fz-26 {
  font-size: 26px !important;
}

.fz-28 {
  font-size: 28px !important;
}

.fz-30 {
  font-size: 30px !important;
}

.fz-35 {
  font-size: 35px !important;
}

.fz-40 {
  font-size: 40px !important;
}

.fz-42 {
  font-size: 42px !important;
}

.fz-44 {
  font-size: 44px !important;
}

.fz-45 {
  font-size: 45px !important;
}

.fz-46 {
  font-size: 46px !important;
}

.fz-48 {
  font-size: 48px !important;
}

.fz-50 {
  font-size: 50px !important;
}

.fz-55 {
  font-size: 55px !important;
}

.fz-60 {
  font-size: 60px !important;
}

.fz-65 {
  font-size: 65px !important;
}

.fz-70 {
  font-size: 70px !important;
}

.fz-80 {
  font-size: 80px !important;
}

.fz-100 {
  font-size: 100px !important;
  line-height: 1.1;
}

.fz-120 {
  font-size: 120px !important;
  line-height: 1.1;
}

/*-------------------------------------------------------
          line-height
-------------------------------------------------------*/
.line-height-1 {
  line-height: 1;
}

.line-height-10 {
  line-height: 10px;
}

.line-height-13 {
  line-height: 13px;
}

.line-height-18 {
  line-height: 18px;
}

.line-height-20 {
  line-height: 20px;
}

.line-height-22 {
  line-height: 22px;
}

.line-height-24 {
  line-height: 24px;
}

.line-height-26 {
  line-height: 26px;
}

.line-height-28 {
  line-height: 28px;
}

.line-height-30 {
  line-height: 30px;
}

.line-height-35 {
  line-height: 35px;
}

.line-height-40 {
  line-height: 40px;
}

.line-height-45 {
  line-height: 45px;
}

.line-height-50 {
  line-height: 50px;
}

.line-height-55 {
  line-height: 55px;
}

.line-height-60 {
  line-height: 60px;
}

.line-height-65 {
  line-height: 65px;
}

.line-height-70 {
  line-height: 70px;
}

.line-height-75 {
  line-height: 75px;
}

.line-height-80 {
  line-height: 80px;
}

.line-height-85 {
  line-height: 85px;
}

.line-height-90 {
  line-height: 90px;
}

.line-height-95 {
  line-height: 95px;
}

.line-height-100 {
  line-height: 100px;
}

.line-height-110 {
  line-height: 110px;
}

.line-height-120 {
  line-height: 120px;
}

/*-------------------------------------------------------
          text-transform
-------------------------------------------------------*/
.text-u {
  text-transform: uppercase !important;
}

.text-l {
  text-transform: lowercase !important;
}

.text-c {
  text-transform: capitalize !important;
}

.text-non {
  text-transform: none !important;
}

.text-i {
  font-style: italic;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

/*-------------------------------------------------------
           letter-spacing
-------------------------------------------------------*/
.ls0 {
  letter-spacing: 0px !important;
}

.ls1 {
  letter-spacing: 1px !important;
}

.ls2 {
  letter-spacing: 2px !important;
}

.ls3 {
  letter-spacing: 3px !important;
}

.ls4 {
  letter-spacing: 4px !important;
}

.ls5 {
  letter-spacing: 5px !important;
}

.ls6 {
  letter-spacing: 6px !important;
}

.ls7 {
  letter-spacing: 7px !important;
}

.ls8 {
  letter-spacing: 8px !important;
}

.ls9 {
  letter-spacing: 9px !important;
}

.ls10 {
  letter-spacing: 10px !important;
}

.ls15 {
  letter-spacing: 15px !important;
}

.ls20 {
  letter-spacing: 20px !important;
}

.ls25 {
  letter-spacing: 25px !important;
}

.ls30 {
  letter-spacing: 30px !important;
}

/*-------------------------------------------------------
       margin-top  (0/100) +5
-------------------------------------------------------*/
.mt-0 {
  margin-top: 0 !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-55 {
  margin-top: 55px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-62 {
  margin-top: 62px !important;
}

.mt-65 {
  margin-top: 65px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mt-75 {
  margin-top: 75px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mt-85 {
  margin-top: 85px !important;
}

.mt-90 {
  margin-top: 90px !important;
}

.mt-95 {
  margin-top: 95px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.mt-120 {
  margin-top: 120px !important;
}

.mt-140 {
  margin-top: 140px !important;
}

/*-------------------------------------------------------
       margin-bottom  (0/100) +5
-------------------------------------------------------*/
.mb-0 {
  margin-bottom: 0 !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-45 {
  margin-bottom: 45px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-55 {
  margin-bottom: 55px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-62 {
  margin-bottom: 62px !important;
}

.mb-65 {
  margin-bottom: 65px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-75 {
  margin-bottom: 75px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-85 {
  margin-bottom: 85px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mb-95 {
  margin-bottom: 95px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.mb-120 {
  margin-bottom: 120px !important;
}

.mb-140 {
  margin-bottom: 140px !important;
}

/*-------------------------------------------------------
       margin-right  (0/100) +5
-------------------------------------------------------*/
.mr-0 {
  margin-right: 0 !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-25 {
  margin-right: 25px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mr-35 {
  margin-right: 35px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.mr-45 {
  margin-right: 45px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.mr-55 {
  margin-right: 55px !important;
}

.mr-60 {
  margin-right: 60px !important;
}

.mr-62 {
  margin-right: 62px !important;
}

.mr-65 {
  margin-right: 65px !important;
}

.mr-70 {
  margin-right: 70px !important;
}

.mr-75 {
  margin-right: 75px !important;
}

.mr-80 {
  margin-right: 80px !important;
}

.mr-85 {
  margin-right: 85px !important;
}

.mr-90 {
  margin-right: 90px !important;
}

.mr-95 {
  margin-right: 95px !important;
}

.mr-100 {
  margin-right: 100px !important;
}

/*-------------------------------------------------------
       margin-left  (0/100) +5
-------------------------------------------------------*/
.ml-auto {
  margin-left: auto;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-25 {
  margin-left: 25px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.ml-35 {
  margin-left: 35px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.ml-45 {
  margin-left: 45px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.ml-55 {
  margin-left: 55px !important;
}

.ml-60 {
  margin-left: 60px !important;
}

.ml-62 {
  margin-left: 62px !important;
}

.ml-65 {
  margin-left: 65px !important;
}

.ml-70 {
  margin-left: 70px !important;
}

.ml-75 {
  margin-left: 75px !important;
}

.ml-80 {
  margin-left: 80px !important;
}

.ml-85 {
  margin-left: 85px !important;
}

.ml-90 {
  margin-left: 90px !important;
}

.ml-95 {
  margin-left: 95px !important;
}

.ml-100 {
  margin-left: 100px !important;
}

.m-auto {
  margin-left: auto;
  margin-right: auto;
}

.min-30 {
  margin-left: -30px;
  margin-right: -30px;
}

/*-------------------------------------------------------
      margin left right  (0/50) +5
-------------------------------------------------------*/
.margin-rl-5 {
  margin-right: 5px;
  margin-left: 5px;
}

.margin-rl-10 {
  margin-right: 10px;
  margin-left: 10px;
}

.margin-rl-15 {
  margin-right: 15px;
  margin-left: 15px;
}

.margin-rl-20 {
  margin-right: 20px;
  margin-left: 20px;
}

.margin-rl-25 {
  margin-right: 25px;
  margin-left: 25px;
}

.margin-rl-30 {
  margin-right: 30px;
  margin-left: 30px;
}

.margin-rl-35 {
  margin-right: 35px;
  margin-left: 35px;
}

.margin-rl-40 {
  margin-right: 40px;
  margin-left: 40px;
}

.margin-rl-45 {
  margin-right: 45px;
  margin-left: 45px;
}

.margin-rl-50 {
  margin-right: 50px;
  margin-left: 50px;
}

/*-------------------------------------------------------
      padding-top  (0/100) +5
-------------------------------------------------------*/
.pt-0 {
  padding-top: 0 !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-35 {
  padding-top: 35px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-45 {
  padding-top: 45px !important;
}

/* .pt-50 {
  padding-top: 50px !important;
} */

.pt-55 {
  padding-top: 55px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pt-65 {
  padding-top: 65px !important;
}

.pt-70 {
  padding-top: 70px !important;
}

.pt-75 {
  padding-top: 75px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.pt-85 {
  padding-top: 85px !important;
}

.pt-90 {
  padding-top: 90px !important;
}

.pt-95 {
  padding-top: 95px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.pt-120 {
  padding-top: 120px !important;
}

.pt-140 {
  padding-top: 140px !important;
}

/*-------------------------------------------------------
     padding-bottom  (0/100) +5
-------------------------------------------------------*/
.pb-0 {
  padding-bottom: 0 !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pb-45 {
  padding-bottom: 45px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pb-55 {
  padding-bottom: 55px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.pb-65 {
  padding-bottom: 65px !important;
}

.pb-70 {
  padding-bottom: 70px !important;
}

.pb-75 {
  padding-bottom: 75px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.pb-85 {
  padding-bottom: 85px !important;
}

.pb-90 {
  padding-bottom: 90px !important;
}

.pb-95 {
  padding-bottom: 95px !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.pb-120 {
  padding-bottom: 120px !important;
}

.pb-140 {
  padding-bottom: 140px !important;
}

.no-padding {
  padding: 0 !important;
}

.pr-30 {
  padding-right: 30px;
}

.pr-50 {
  padding-right: 50px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-50 {
  padding-left: 50px;
}

/*-------------------------------------------------------
      padding left right  (0/50) +5
-------------------------------------------------------*/
.padding-rl-5 {
  padding-right: 5px;
  padding-left: 5px;
}

.padding-rl-10 {
  padding-right: 10px;
  padding-left: 10px;
}

.padding-rl-15 {
  padding-right: 15px;
  padding-left: 15px;
}

.padding-rl-20 {
  padding-right: 20px;
  padding-left: 20px;
}

.padding-rl-25 {
  padding-right: 25px;
  padding-left: 25px;
}

.padding-rl-30 {
  padding-right: 30px;
  padding-left: 30px;
}

.padding-rl-35 {
  padding-right: 35px;
  padding-left: 35px;
}

.padding-rl-40 {
  padding-right: 40px;
  padding-left: 40px;
}

.padding-rl-45 {
  padding-right: 45px;
  padding-left: 45px;
}

.padding-rl-50 {
  padding-right: 50px;
  padding-left: 50px;
}

/* ====================== [ End Helper Classes  ] ====================== */
/* Preloader
-----------------------------------------------------------------*/
body.loaded {
  overflow: hidden !important;
  height: 100% !important;
}

.loader-wrap {
  position: fixed;
  /* Ensure the loader stays fixed on the screen */
  z-index: 10;
  /*To keep the page opening animation */
  height: 100vh;
  width: 100%;
  left: 0;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: transparent;
  pointer-events: none;
  /* Allow clicks to pass through */
}

.loader-wrap svg {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /* Match the loader's height */
  fill: #e8e8e8;
  pointer-events: none;
  /* Allow clicks to pass through */
}

.loader-wrap .loader-wrap-heading .load-text {
  font-size: 18px;
  font-weight: 200;
  letter-spacing: 15px;
  text-transform: uppercase;
  z-index: 20;
}

.load-text span {
  -webkit-animation: loading 1s infinite alternate;
  animation: loading 1s infinite alternate;
}

.load-text span:nth-child(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.load-text span:nth-child(2) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.load-text span:nth-child(3) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.load-text span:nth-child(4) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.load-text span:nth-child(5) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.load-text span:nth-child(6) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.load-text span:nth-child(7) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

@-webkit-keyframes loading {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes loading {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* ====================== [ Start Overlay ] ====================== */
[data-overlay-dark],
[data-overlay-light] {
  position: relative;
}

[data-overlay-dark] .container,
[data-overlay-dark] .container-xxl,
[data-overlay-light] .container-xxl,
[data-overlay-light] .container {
  position: relative;
  z-index: 7;
}

[data-overlay-dark] .container p,
[data-overlay-dark] .container-xxl p,
[data-overlay-light] .container-xxl p,
[data-overlay-light] .container p {
  color: #ddd;
}

[data-overlay-dark]:before,
[data-overlay-light]:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  pointer-events: none;
}

[data-overlay-dark]:before {
  background: #1a1a1a;
}

[data-overlay-light]:before {
  background: #fff;
}

[data-overlay-dark],
[data-overlay-dark] h1,
[data-overlay-dark] h2,
[data-overlay-dark] h3,
[data-overlay-dark] h4,
[data-overlay-dark] h5,
[data-overlay-dark] h6,
[data-overlay-dark] span,
.bg-dark h1,
.bg-dark h2,
.bg-dark h3,
.bg-dark h4,
.bg-dark h5,
.bg-dark h6,
.bg-dark span,
.bg-color h1,
.bg-color h2,
.bg-color h3,
.bg-color h4,
.bg-color h5,
.bg-color h6,
.bg-color span {
  color: #fff;
}

[data-overlay-dark="0"]:before,
[data-overlay-light="0"]:before {
  opacity: 0;
}

[data-overlay-dark="1"]:before,
[data-overlay-light="1"]:before {
  opacity: 0.1;
}

[data-overlay-dark="2"]:before,
[data-overlay-light="2"]:before {
  opacity: 0.2;
}

[data-overlay-dark="3"]:before,
[data-overlay-light="3"]:before {
  opacity: 0.3;
}

[data-overlay-dark="4"]:before,
[data-overlay-light="4"]:before {
  opacity: 0.4;
}

[data-overlay-dark="5"]:before,
[data-overlay-light="5"]:before {
  opacity: 0.5;
}

[data-overlay-dark="6"]:before,
[data-overlay-light="6"]:before {
  opacity: 0.6;
}

[data-overlay-dark="7"]:before,
[data-overlay-light="7"]:before {
  opacity: 0.7;
}

[data-overlay-dark="8"]:before,
[data-overlay-light="8"]:before {
  opacity: 0.8;
}

[data-overlay-dark="9"]:before,
[data-overlay-light="9"]:before,
[data-overlay-color="9"]:before {
  opacity: 0.9;
}

[data-overlay-dark="10"]:before,
[data-overlay-light="10"]:before {
  opacity: 1;
}

/* ====================== [ End Overlay ] ====================== */
/* Menu
-----------------------------------------------------------------*/
.navbar {
  position: absolute !important;
  left: 0;
  top: 0;
  padding: 0 !important;
  margin: 0;
  width: 100%;
  background: transparent;
  border-bottom: 2px solid transparent;
  z-index: 999;
}

.navbar.nav-crev {
  width: calc(100% - 80px);
  left: 40px;
  top: 30px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  /*  border-radius: 40px;*/
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  min-height: auto;
  -webkit-transform: translate(0) !important;
  -ms-transform: translate(0) !important;
  transform: translate(0) !important;
}

.navbar.nav-crev.nav-scroll {
  background: #fff;
  border-color: rgba(255, 255, 255, 0.05);
  padding: 0;
  position: fixed;
  top: -80px;
  -webkit-transform: translateY(80px) !important;
  -ms-transform: translateY(80px) !important;
  transform: translateY(80px) !important;
  left: 0;
  width: 100%;
  border-radius: 0;
}

.navbar.nav-scroll .logo {
  width: 13rem;
}

.navbar.static {
  position: relative;
}

.marq {
  float: right;
  width: 60%;
}

.navbar.main-bg {
  background: #fff;
  border-color: rgba(255, 255, 255, 0.05);
}

.navbar.sub-bg {
  background: #f5f7f9;
  border-color: rgba(255, 255, 255, 0.05);
}

.navbar.blur {
  border-color: rgba(255, 255, 255, 0.05);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.navbar.bord {
  border-color: rgba(0, 0, 0, 0.05);
}

.navbar.change .navbar-nav .nav-link,
.navbar.change .menu-icon {
  color: #fff;
}

.navbar.change .search-form .search-icon {
  color: #fff;
}

.navbar.change .search-form .search-icon:after {
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar.change.nav-scroll .navbar-nav .nav-link,
.navbar.change.nav-scroll .menu-icon {
  color: #1a1a1a;
}

.navbar.change.nav-scroll .search-form .search-icon {
  color: #1a1a1a;
}

.navbar.change.nav-scroll .search-form .search-icon:after {
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar.nav-scroll {
  background: #fff;
  border-color: rgba(255, 255, 255, 0.05);
  -webkit-box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
  padding: 0 !important;
  position: fixed !important;
  top: -80px;
  -webkit-transition: -webkit-transform 0.8s;
  transition: -webkit-transform 0.8s;
  -o-transition: transform 0.8s;
  transition: transform 0.8s;
  transition: transform 0.8s, -webkit-transform 0.8s;
  -webkit-transform: translateY(80px);
  -ms-transform: translateY(80px);
  transform: translateY(80px);
}

.navbar .navbar-nav .nav-link {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 13px;
  font-weight: 500;
  color: #1a1a1a;
  padding: 20px 10px 15px 10px;
  margin: 0 15px;
}

.navbar .navbar-nav .nav-link .rolling-text {
  height: 30px;
  line-height: 30px;
}

.navbar .navbar-nav .dropdown-menu {
  padding: 20px 10px;
  border: 1px solid #ddd;
}

.navbar .dropdown-menu {
  display: block;
  position: absolute;
  top: 75px;
  opacity: 0;
  visibility: hidden;
  border-radius: 0;
  color: #1a1a1a;
  background: #fff;
  border: 1px solid #ddd;
  /*  border-radius: 0;*/
  min-width: 255px;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.navbar .dropdown-menu.show {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.navbar .dropdown-menu.show-left .dropdown-item .dropdown-side {
  left: auto;
  right: 248px;
}

.navbar .dropdown-menu .dropdown-item {
  font-size: 14px;
  color: #1a1a1a;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  padding: 10px 25px;
  position: relative;
}

.navbar .dropdown-menu .dropdown-item .dropdown-side {
  position: absolute;
  left: 248px;
  top: -15px;
  display: block;
  opacity: 0;
  visibility: hidden;
  border: 1px solid #ddd;
  /*  border-radius: 0;*/
  background: #fff;
  width: 240px;
  padding: 20px 0px;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.navbar .dropdown-menu .dropdown-item .dropdown-side.show {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.navbar .dropdown-menu .dropdown-item .icon-arrow {
  position: absolute;
  right: 15px;
}

.navbar .dropdown-menu .dropdown-item:after {
  content: "";
  width: 0px;
  height: 1px;
  background: #1a1a1a;
  position: absolute;
  left: 10px;
  top: 18px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.navbar .dropdown-menu .dropdown-item:hover {
  padding-left: 30px;
  background: transparent;
}

.navbar .dropdown-menu .dropdown-item:hover:after {
  width: 10px;
}

.navbar .no-relative {
  position: static;
}

.navbar .dropdown {
  position: static;
}

.navbar .mega-menu {
  width: 96vw;
  position: absolute;
  left: 2vw;
  top: 75px;
  padding: 40px 0 30px !important;
  /*  border-radius: 0;*/
  overflow: hidden;
}

.navbar .mega-menu .title {
  margin-bottom: 20px;
}

.navbar .mega-menu .title .sub-title {
  font-weight: 500;
}

.navbar .mega-menu .dropdown-item {
  padding: 10px 0;
}

.navbar .mega-menu .dropdown-item:after {
  left: 0;
}

.navbar .mega-menu .dropdown-item:hover {
  padding-left: 20px;
}

.navbar .search-form {
  position: relative;
  padding-top: 10px;
}

.navbar .search-form.open .form-group {
  opacity: 1;
  visibility: visible;
  bottom: -1px;
}

.navbar .search-form.open .search-icon .open-search {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  transform: translateY(-10px);
}

.navbar .search-form .form-group {
  position: absolute;
  right: 0;
  bottom: -35px;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  padding: 15px;
  background: rgba(255, 255, 255, 0.05);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.navbar .search-form .form-group input {
  padding: 10px 55px 10px 15px;
  background: #fff;
  border: 0;
  /*  border-radius: 0;*/
}

.navbar .search-form .form-group button {
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 7.5px 13px;
  background: #ccc;
  border: 0;
  border-radius: 0;
}

.navbar .search-form .search-icon {
  position: relative;
  padding: 20px 8px;
  cursor: pointer;
  color: #fff;
  font-size: 22px;
}

.navbar.nav-scroll .search-form .search-icon {
  color: #000;
}

.navbar .search-form .search-icon .close-search {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 25px 0;
  font-size: 35px;
  display: none;
}

.navbar .search-form .search-icon .open-search {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.navbar .menu-icon {
  padding: 20px 20px;
  color: #fff;
  font-size: 21px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.navbar.nav-scroll .menu-icon {
  color: #000;
}

.navbar .dropdown-toggle:after {
  margin-left: 5px;
  vertical-align: 12px;
}

.navbar .navbar-toggler {
  display: none;
}

.hamenu {
  /* position: fixed; */
  top: 0;
  left: -100%;
  width: 100%;
  height: 100vh;
  background: #fff;
  overflow: hidden;
  z-index: 9999;
  -webkit-transition: all 0.5s cubic-bezier(1, 0, 0.55, 1);
  -o-transition: all 0.5s cubic-bezier(1, 0, 0.55, 1);
  transition: all 0.5s cubic-bezier(1, 0, 0.55, 1);
}

.hamenu .logo {
  position: absolute;
  top: 40px;
  left: 40px;
}

.hamenu .close-menu {
  position: absolute;
  top: 30px;
  right: 40px;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  text-align: center;
  background: #fff;
  cursor: pointer;
  z-index: 4;
}

.hamenu .logo,
.hamenu .close-menu {
  -webkit-transform: translateY(-100px);
  -ms-transform: translateY(-100px);
  transform: translateY(-100px);
  opacity: 0;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.hamenu.open .logo,
.hamenu.open .close-menu {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}

.hamenu.open .menu-links .main-menu > li .link {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}

.hamenu.open .cont-info .item {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  -webkit-transition-delay: 1.4s;
  -o-transition-delay: 1.4s;
  transition-delay: 1.4s;
}

.hamenu.open .menu-text .text {
  opacity: 1;
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}

.hamenu.open .menu-text:after {
  height: 200vh;
  -webkit-transition-delay: 0.7s;
  -o-transition-delay: 0.7s;
  transition-delay: 0.7s;
}

.hamenu .menu-links {
  color: #1a1a1a;
  height: 100vh;
  overflow: auto;
}

.hamenu .menu-links::-webkit-scrollbar {
  width: 5px;
}

.hamenu .menu-links::-webkit-scrollbar-track {
  background: #eee;
}

.hamenu .menu-links::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 0;
}

.hamenu .menu-links .main-menu {
  position: relative;
  z-index: 2;
  padding-top: 90px !important;
  padding-bottom: 100px !important;
}

.main-meanu-react {
  position: relative;
  z-index: 2;
  padding-top: 90px !important;
}

.hamenu .menu-links .main-menu ul {
  margin: 0;
  padding: 0;
}

.hamenu .menu-links .main-menu > li {
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.hamenu .menu-links .main-menu > li .link {
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
  /* letter-spacing: 1px; */
  line-height: 1.2;
  padding: 20px 30px;
  border-bottom: 1px solid rgb(0 0 0 / 5%);
  width: 100%;
  position: relative;
  display: block;
  -webkit-transform: translateY(100px);
  -ms-transform: translateY(100px);
  transform: translateY(100px);
  opacity: 0;
  -webkit-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}

.hamenu .menu-links .main-menu > li .link i {
  position: absolute;
  width: 30px;
  top: 0;
  right: 0;
  bottom: 0;
  /*  background: #f5f7f9;*/
}

.hamenu .menu-links .main-menu > li .link i:before {
  content: "";
  width: 1px;
  height: 16px;
  position: absolute;
  top: 41%;
  left: 50%;
  background: #1a1a1a;
}

.hamenu .menu-links .main-menu > li .link i:after {
  content: "";
  width: 16px;
  height: 1px;
  position: absolute;
  top: calc(40% + 8px);
  left: calc(50% - 8px);
  background: #1a1a1a;
}

.hamenu .menu-links .main-menu > li .link.dopen i:before {
  opacity: 0;
}

.hamenu .menu-links .main-menu > li .link .fill-text {
  position: relative;
  /* color: #999999; */
  font-size: 18px !important;
}

.hamenu .menu-links .main-menu > li .link .fill-text:after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  /* color: #3a4197; */
  width: 100%;
  /* overflow: hidden; */
  white-space: nowrap;
  -webkit-transition: width 0.75s cubic-bezier(0.63, 0.03, 0.21, 1);
  -o-transition: width 0.75s cubic-bezier(0.63, 0.03, 0.21, 1);
  transition: width 0.75s cubic-bezier(0.63, 0.03, 0.21, 1);
  font-size: 18px !important;
}

.hamenu .menu-links .main-menu > li:last-of-type .link {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.hamenu .menu-links .main-menu > li.hoverd .link .fill-text:after {
  width: 0;
}

.hamenu .menu-links .main-menu > li .sub-dmenu {
  font-size: 30px;
  font-weight: 500;
  padding-left: 50px;
  text-transform: capitalize;
  letter-spacing: normal;
  border-color: rgba(0, 0, 0, 0.05);
  background: rgba(0, 0, 0, 0.015);
}

.hamenu .menu-links .main-menu .sub-menu {
  /* display: none; */
  padding: 20px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  width: 100%;
}

.hamenu .menu-links .main-menu .sub-menu.no-bord {
  border-top: 0;
  padding: 0;
}

.hamenu .menu-links .main-menu .sub-menu li {
  padding: 0;
  margin: 0;
  line-height: 1;
  font-size: 18px;
  font-weight: 400;
}

.ham-menu {
  margin-bottom: 80px !important;
}

.hamenu .menu-links .main-menu .sub-menu li a {
  padding: 6px 12px 12px 40px;
  opacity: 0.6;
  color: #666666;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.hamenu .menu-links .main-menu .sub-menu li a:hover {
  /*  opacity: 1;*/
  color: #a41e35;
}

.hamenu .menu-links .main-menu .sub-menu2 {
  display: none;
  padding: 10px 0 20px;
}

.hamenu .menu-links .main-menu .sub-menu2 li {
  padding: 0;
  margin: 0;
  line-height: 1;
  font-size: 22px;
  font-weight: 400;
}

.hamenu .menu-links .main-menu .sub-menu2 li a {
  padding: 12px 12px 12px 70px;
  opacity: 0.6;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.hamenu .menu-links .main-menu .sub-menu2 li a:hover {
  opacity: 1;
}

.hamenu .menu-text {
  position: relative;
  height: 100%;
}

.hamenu .menu-text .text {
  position: absolute;
  right: 25%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 2;
  opacity: 0;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.hamenu .menu-text .text h2 {
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  font-size: 10vw;
  font-weight: 800;
  line-height: 1;
  text-transform: uppercase;
  opacity: 0.05;
}

.hamenu .menu-text:after {
  content: "";
  width: 1px;
  height: 0;
  background: rgba(0, 0, 0, 0.05);
  position: absolute;
  right: -30px;
  bottom: -75vh;
  -webkit-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.hamenu .cont-info {
  padding-top: 140px;
  padding-left: 15px;
  position: relative;
  z-index: 3;
}

.hamenu .cont-info:after {
  content: "";
  width: 300%;
  height: 200vh;
  position: absolute;
  left: -30px;
  top: -75vh;
  background: #f5f7f9;
  border-left: 1px solid #ddd;
  z-index: -1;
}

.hamenu .cont-info .item {
  -webkit-transform: translateY(50px);
  -ms-transform: translateY(50px);
  transform: translateY(50px);
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  opacity: 0;
}

.hamenu .cont-info .item h5 {
  font-size: 18px;
}

/* Titles
-----------------------------------------------------------------*/
.sec-head .stroke {
  color: transparent;
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0.4);
  font-weight: 800;
  font-size: 55px;
  text-transform: uppercase;
  line-height: 1;
}

.sec-head .bord {
  position: relative;
  /*  padding-right: 30px;*/
}

.sec-head .bord:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 40px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.sub-title {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
}

.numb-font {
  font-family: "Allerta Stencil", sans-serif;
}

.main-font {
  font-family: "Space Grotesk", sans-serif;
}

.sec-bottom {
  position: relative;
}

.sec-bottom:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.sec-bottom .d-flex {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin: auto;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 30px;
  border-radius: 30px;
  position: relative;
  z-index: 3;
}

/* About Intro
-----------------------------------------------------------------*/
.intro .marq-brand .item {
  width: 200px;
}

.half-block {
  padding: 15px;
}

.half-block .colmn {
  padding: 40px;
}

.about-crev .left-block {
  position: relative;
  z-index: 3;
}

.about-crev .left-block .info {
  padding: 0 60px;
  margin-top: -30px;
}

.about-crev .left-block .mz-shap {
  position: absolute;
  top: -100px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.about .img-vid {
  position: relative;
  /*  border-radius: 30px 30px 30px 0;*/
  overflow: hidden;
}

.about .img-vid .curv-butn {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 60%;
  right: 0;
  margin: 0 auto;
  width: 65px;
  height: 65px;
  line-height: 40px;
  border-radius: 5px;
  text-align: center;
  /* border-radius: 0 30px 0 0;
/*  border-radius: 0 30px 0 0;*/
}

.about .img-vid .curv-butn:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #f7f7f7;
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
}

.about .img-vid .curv-butn:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 25%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 60px;
  height: 60px;
  background: #ffffff6e;
  border-radius: 50%;
  transition: all 200ms;
}

.about .img-vid .curv-butn .vid {
  position: absolute;
  top: 13px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  /* border-radius: 50%; */
  /*  background: rgba(255, 255, 255, 0.04);*/
  z-index: 99;
}

.about .img-vid .curv-butn .shap-left-top {
  position: absolute;
  top: -2.05rem;
  left: -1px;
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
  line-height: 1;
}

.about .img-vid .curv-butn .shap-left-top svg {
  width: 2.1rem;
  height: 2.1rem;
}

.about .img-vid .curv-butn .shap-right-bottom {
  position: absolute;
  bottom: -1px;
  right: -2.1rem;
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
  line-height: 1;
}

.about .img-vid .curv-butn .shap-right-bottom svg {
  width: 2.1rem;
  height: 2.1rem;
}

.intro-card h2 {
  font-size: 100px;
  font-weight: 700;
  line-height: 0.8;
  position: relative;
  z-index: 4;
}

.intro-card h2 .botm {
  font-size: 70px;
  font-weight: 400;
  padding-left: 30px;
  opacity: 0.4;
  position: relative;
  z-index: -1;
}

.vcard-bg {
  background-position: center;
  background-color: #fff;
}

.resume .resume-clumn {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.resume .items {
  padding-left: 50px;
  position: relative;
}

.resume .items:before {
  content: "";
  position: absolute;
  left: -5px;
  top: 10px;
  width: 10px;
  height: 10px;
  /*  border-radius: 50%;*/
  background: #ccc;
  z-index: 2;
}

.resume .items h6 {
  display: inline-block;
  color: #777;
  padding: 7px 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  /*  border-radius: 30px;*/
  margin-bottom: 15px;
}

.resume .items h5 {
  font-size: 22px;
}

.resume .items span {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
}

.page-intro .img {
  height: 520px;
}

.page-intro .img.to-up {
  margin-top: -100px;
}

.page-intro-cerv .cont {
  padding: 60px;
  height: 100%;
}

.page-intro-cerv .img {
  padding: 15px;
  height: 100%;
}

.page-intro-cerv .bg-dots {
  position: absolute;
  bottom: -250px;
  left: 60px;
  width: 140px;
  height: 250px;
  background-repeat: repeat;
  background-size: 6px;
  opacity: 0.4;
}

.page-intro-modern {
  position: relative;
}

.page-intro-modern:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60%;
  background: #f7f7f7;
  z-index: -1;
}

.page-intro-modern .img1 {
  height: 380px;
  width: 100%;
}

.page-intro-modern .img2 {
  height: 650px;
  width: 100%;
}

.page-intro-modern .img3 {
  height: 220px;
  width: 80%;
  margin-left: 20%;
}

.page-intro-modern .img4 {
  height: 280px;
}

/* Awards
-----------------------------------------------------------------*/
.awards .item-line {
  padding: 25px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  position: relative;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  z-index: 3;
}

.awards .item-line > div {
  padding: 0;
}

.awards .item-line > div:last-of-type {
  text-align: right;
}

.awards .item-line:hover {
  color: #0c0c0c;
}

.awards .item-line:hover:after {
  height: 100%;
}

.awards .item-line:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background: #fff;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  z-index: -1;
}

.awards .item-line .project-date span {
  font-size: 14px;
  opacity: 0.7;
  margin-left: 15px;
}

.awards .item-title {
  padding: 0 15px 25px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}

.awards .item-title > div {
  padding: 0;
}

/* Blog
-----------------------------------------------------------------*/
.blog .img {
  /*  border-radius: 15px;*/
  overflow: hidden;
}

.blog .cont {
  width: 100%;
}

.blog .cont .info .date {
  padding-right: 30px;
  margin-right: 20px;
  position: relative;
}

.blog .cont .info .date:after {
  content: "";
  position: absolute;
  right: 0;
  top: 40%;
  width: 7px;
  height: 7px;
  background: #fff;
}

.blog-modern .item .img {
  position: relative;
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.08);
}

.blog-modern .item .img .tag {
  position: absolute;
  top: 40px;
  right: 10px;
  padding: 8px 20px;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.blog-modern .item .img .tag .shap-right-top {
  position: absolute;
  top: -1.05rem;
  right: -1px;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.blog-modern .item .img .tag .shap-right-top svg {
  width: 1.1rem;
  height: 1.1rem;
}

.blog-modern .item .img .tag .shap-right-bottom {
  position: absolute;
  bottom: -1.05rem;
  right: -1px;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.blog-modern .item .img .tag .shap-right-bottom svg {
  width: 1.1rem;
  height: 1.1rem;
}

.blog-modern .item .cont {
  padding: 0 10px;
}

.blog-crev .item .img .tag {
  position: absolute;
  bottom: -2px;
  left: 50%;
  padding: 8px 30px;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.blog-crev .item .img .tag .shap-right-bottom {
  position: absolute;
  bottom: -1px;
  right: -1.05rem;
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.blog-crev .item .img .tag .shap-right-bottom svg {
  width: 1.1rem;
  height: 1.1rem;
}

.blog-crev .item .img .tag .shap-left-bottom {
  position: absolute;
  bottom: -1px;
  left: -1.05rem;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.blog-crev .item .img .tag .shap-left-bottom svg {
  width: 1.1rem;
  height: 1.1rem;
}

.blog-crev .item .cont {
  padding: 40px 30px;
}

.blog-list-half .item {
  /*  border-radius: 10px;*/
  overflow: hidden;
}

.blog-list-half .item .img {
  min-height: 300px;
  padding-right: 0;
  position: relative;
}

.blog-list-half .item .img:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*  border-radius: 10px;*/
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#1a1a1a),
    to(transparent)
  );
  background: -webkit-linear-gradient(top, #1a1a1a, transparent);
  background: -o-linear-gradient(top, #1a1a1a, transparent);
  background: linear-gradient(180deg, #1a1a1a, transparent);
  opacity: 0;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.blog-list-half .item .img .img-post {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  /*  border-radius: 10px;*/
}

.blog-list-half .item .img .author {
  color: #fff;
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 3;
  opacity: 0;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.blog-list-half .item .cont {
  padding: 40px 15px 40px 30px;
}

.blog-list-half .item .cont .tags a {
  font-size: 12px;
  padding: 8px 20px;
  background: rgba(0, 0, 0, 0.05);
  /*  border-radius: 30px;*/
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.blog-list-half .item .cont .tags a:hover {
  background: #3a4197;
}

.blog-list-half .item .cont .tags.colorbg a:hover {
  background: #3a4197;
  color: #fff;
}

.blog-list-half .item:hover .img:after {
  opacity: 0.3;
}

.blog-list-half .item:hover .img .author {
  opacity: 1;
}

.blog-list-half.crev .item {
  overflow: visible;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.blog-list-half.crev .item .img {
  padding: 0 0 0 15px;
}

.blog-list-half.crev .item .img img {
  border-radius: 0;
  position: relative;
  bottom: -30px;
}

.blog-list-half.crev .item .cont {
  padding: 30px 0;
}

.blog-list-crev .item {
  padding: 45px;
  position: relative;
}

.blog-list-crev .item:hover .background {
  opacity: 1;
  background-position: center bottom;
}

.blog-list-crev .item .info .author {
  margin-right: 20px;
}

.blog-list-crev .item .info .author .img {
  width: 60px;
  height: 60px;
  overflow: hidden;
  /*  border-radius: 50%;*/
}

.blog-list-crev .item .info .author .img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

.blog-list-crev .item .tags a {
  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 8px 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  /*  border-radius: 30px;*/
  margin: 5px 5px 5px 0;
}

.blog-list-crev .item .background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center top;
  opacity: 0;
  -webkit-transition: opacity 0.4s, background-position 10s linear;
  -o-transition: opacity 0.4s, background-position 10s linear;
  transition: opacity 0.4s, background-position 10s linear;
}

.blog-list-crev .item .background .more a {
  font-size: 14px;
  padding: 14px 35px;
  background: #3a4197;
  /*  border-radius: 30px;*/
}

.blog-list-crev .item .background .more a span,
.blog-list-crev .item .background .more a i {
  color: #ffffff;
}


/* 5th check */
/* .sidebar .search-box {
  position: relative;
} */

/* .sidebar .search-box input {
  color: #1a1a1a;
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  width: 100%;
  background: transparent;
} */

/* .sidebar .search-box input:focus {
  border-color: #1a1a1a;
} */

.sidebar .search-box .icon {
  position: absolute;
  top: 50%;
  right: 15px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.sidebar .widget {
  margin-top: 50px;
}

.sidebar .widget .title-widget {
  margin-bottom: 40px;
  padding-top: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
}

/* .sidebar .widget .title-widget:after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 30px;
  border-right: 1px solid rgba(0, 0, 0, 0.08);
} */

/* .sidebar .widget.catogry li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 15px;
  margin-bottom: 7px;
  padding: 10px 20px;
  background: rgba(0, 0, 0, 0.02);
} */

/* .sidebar .widget.catogry li:last-of-type {
  margin-bottom: 0;
} */

/* .sidebar .widget.last-post-thum .item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px;
} */

/* .sidebar .widget.last-post-thum .item:last-of-type {
  margin-bottom: 0;
} */

/* .sidebar .widget.last-post-thum .item:hover .img a .date {
  opacity: 1;
} */

/* .sidebar .widget.last-post-thum .item .img {
  width: 90px;
  height: 100px;
  border-radius: 0;
  overflow: hidden;
} */

/* .sidebar .widget.last-post-thum .item .img a {
  width: 100%;
  height: 100%;
  position: relative;
} */


/* .sidebar .widget.last-post-thum .item .img a .date {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 14px;
  width: 50px;
  height: 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  z-index: 3;
  opacity: 0;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
} */

/* .sidebar .widget.last-post-thum .item .img a .date span {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  line-height: 1;
} */

/* .sidebar .widget.last-post-thum .item .img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
} */

/* .sidebar .widget.last-post-thum .item .cont {
  padding-left: 25px;
} */

/* .sidebar .widget.last-post-thum .item .cont h6 {
  font-size: 17px;
} */

/* .sidebar .widget.last-post-thum .item .cont .tag {
  font-size: 12px;
  padding: 5px 15px;
  background: rgba(0, 0, 0, 0.03);
  margin-bottom: 10px;
} */

/* .sidebar .widget.tags a {
  padding: 8px 15px;

  background: rgba(0, 0, 0, 0.02);
  margin: 2px 0;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
} */

/* .sidebar .widget.tags a:hover {
  background: #3a4197;
} */

.blog-main .item .content {
  padding: 30px;
  background: #fff;
  width: 95%;
  margin: auto;
  margin-top: -80px;
  position: relative;
  z-index: 3;
}

.blog-main .item .content .post-date {
  padding: 7px 15px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  /*  border-radius: 30px;*/
  font-size: 12px;
  color: #1d1d1d;
  text-transform: uppercase;
  margin-right: 15px;
}

.blog-header .background {
  height: 75vh;
  min-height: 480px;
}

/* .main-post .spec-letter {
  font-size: 60px;
  line-height: 50px;
  padding-right: 10px;
  float: left;
} */

/* .main-post .post-qoute {
  position: relative;
  padding: 40px;
  padding-left: 120px;
  border: 1px solid rgba(0, 0, 0, 0.15);
} */

/* .main-post .post-qoute h6 {
  position: relative;
} */

/* .main-post .post-qoute h6:before {
  content: "“";
  position: absolute;
  top: 80px;
  left: -100px;
  font-size: 180px;
  font-family: Poppins;
  line-height: 0;
} */

/* .main-post .iner-img {
  height: 400px;
} */

/* .main-post .iner-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
} */

/* .main-post .unorder-list li,
.main-post .order-list li {
  margin-top: 10px;
  opacity: 0.7;
  padding-left: 15px;
  position: relative;
} */

/* .main-post .unorder-list li:before,
.main-post .order-list li:before {
  content: "";
  width: 5px;
  height: 5px;
  background: #777;
  position: absolute;
  left: 0;
  top: 10px;
} */

/* .main-post .order-list li {
  padding-left: 0;
} */

/* .main-post .order-list li:before {
  display: none;
} */

/* .main-post .order-list li span {
  font-size: 13px;
} */

/* .main-post .info-area .tags span,
.main-post .info-area .share-icon span {
  font-size: 14px;
  margin-right: 5px;
  opacity: 0.7;
} */

/* .main-post .info-area .tags a,
.main-post .info-area .share-icon a {
  padding: 7px 20px;
  font-size: 13px;
  background: rgba(0, 0, 0, 0.02);
  margin-right: 5px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
} */

/* .main-post .info-area .tags a:hover,
.main-post .info-area .share-icon a:hover {
  background: #3a4197;
  color: #1d1d1d;
} */

/* .main-post .info-area .share-icon a {
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 11px;
  text-align: center;
  padding: 0;
} */

/* .main-post .author-area {
  padding: 40px;
  background: rgba(0, 0, 0, 0.02);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
} */

/* .main-post .author-area .author-img .img {
  width: 100px;
  height: 100px;
  overflow: hidden;
} */

/* .main-post .next-prv-post .thumb-post {
  width: 100%;
  padding: 40px;
  position: relative;
  overflow: hidden;
  z-index: 2;
} */

/* .main-post .next-prv-post .thumb-post:after {
  content: "";
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  z-index: -1;
  opacity: 0.7;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
} */

/* .main-post .next-prv-post .thumb-post:first-of-type {
  margin-right: 15px;
} */

/* .main-post .next-prv-post .thumb-post:last-of-type {
  margin-left: 15px;
} */

.comments-post .item-box {
  position: relative;
}

.comments-post .item-box p {
  font-size: 14px;
  width: 80%;
}

.comments-post .item-box.replayed {
  margin-left: 30px;
}

.comments-post .item-box .replay-butn {
  position: absolute;
  top: 15px;
  right: 15px;
}

.comments-post .item-box .replay-butn a {
  font-size: 13px;
  padding: 8px 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  /*  border-radius: 30px;*/
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.comments-post .item-box .replay-butn a:hover {
  background: #3a4197;
  color: #1d1d1d;
}

/* .comments-from input,
.comments-from textarea {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: transparent;
  color: #1a1a1a;
  padding: 15px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
} */

/* .comments-from input:focus,
.comments-from textarea:focus {
  border-color: #1a1a1a;
} */

/* .comments-from textarea {
  min-height: 180px;
} */

/* .comments-from button {
  padding: 15px 35px;
  width: 100%;
  background: rgba(0, 0, 0, 0.02);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  color: #1a1a1a;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 1px;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.2);
  overflow: hidden;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
} */

/* .comments-from button .text {
  position: relative;
  z-index: 4;
} */

/* .comments-from button:hover {
  color: #fff;
  background: #1d1d1d;
} */

/* Brands & Clients
-----------------------------------------------------------------*/
.brands .brand-swiper .swiper-slide {
  height: auto;
}

.brands .brand-swiper .item {
  padding: 60px 30px;
  border-right: 1px solid rgba(0, 0, 0, 0.08);
  height: 100%;
}

.brands .brand-swiper .item a {
  width: 100px;
}

.brands-crev .sec-head {
  padding: 80px 20px 80px 80px;
  position: relative;
}

.brands-crev .sec-head:after {
  content: "";
  position: absolute;
  top: -30px;
  left: 0px;
  right: -100vw;
  bottom: 0;
  background: #f5f7f9;
  /*  border-radius: 0 0 0 30px;*/
}

.brands-crev .sec-head .shap-left-top {
  position: absolute;
  top: -1px;
  left: -2.05rem;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.brands-crev .sec-head .shap-left-top svg {
  width: 2.1rem;
  height: 2.1rem;
}



.brands-crev .item {
  padding: 130px 20px;
  text-align: center;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.08);
}

.brands-crev .item:after {
  content: "";
  position: absolute;
  top: 35px;
  left: 35px;
  right: 35px;
  bottom: 35px;
  background: -webkit-linear-gradient(320deg, transparent, #3a4197);
  background: -o-linear-gradient(320deg, transparent, #3a4197);
  background: linear-gradient(130deg, transparent, #3a4197);
  opacity: 0;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.brands-crev .item:hover:after {
  opacity: 1;
}

.brands-crev .item.empty {
  border: 0;
}

.brands-crev .item.empty .text {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.brands-crev .item.empty:after {
  display: none;
}

.brands-crev .item .top-left {
  top: -0.8rem;
  left: -0.8rem;
}

.brands-crev .item .top-right {
  top: -0.8rem;
  right: -0.8rem;
}

.brands-crev .item .bottom-left {
  bottom: -0.8rem;
  left: -0.8rem;
}

.brands-crev .item .bottom-right {
  bottom: -0.8rem;
  right: -0.8rem;
}

.brands-crev .item .top-left,
.brands-crev .item .top-right,
.brands-crev .item .bottom-left,
.brands-crev .item .bottom-right {
  position: absolute;
}

.brands-crev .item .top-left:after,
.brands-crev .item .top-right:after,
.brands-crev .item .bottom-left:after,
.brands-crev .item .bottom-right:after {
  content: "";
  position: absolute;
  top: -20px;
  left: -20px;
  right: -20px;
  bottom: -20px;
  background: #fff;
}

.brands-crev .item svg {
  width: 1.6rem;
  position: relative;
  z-index: 3;
  opacity: 0.4;
}

.brands-crev .item img {
  width: 100px;
}

.brands-crev .item .img,
.brands-crev .item .text {
  position: relative;
  z-index: 2;
}

.brands-crev.simple .sec-head:after {
  display: none;
}

.brands-crev.revers-bg .item .top-left,
.brands-crev.revers-bg .item .top-right,
.brands-crev.revers-bg .item .bottom-left,
.brands-crev.revers-bg .item .bottom-right {
  position: absolute;
}

.brands-crev.revers-bg .item .top-left:after,
.brands-crev.revers-bg .item .top-right:after,
.brands-crev.revers-bg .item .bottom-left:after,
.brands-crev.revers-bg .item .bottom-right:after {
  content: "";
  position: absolute;
  top: -20px;
  left: -20px;
  right: -20px;
  bottom: -20px;
  background: #f5f7f9;
}

.clients .brand:hover .img {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  opacity: 0;
}

.clients .brand:hover .link {
  opacity: 1 !important;
  -webkit-transition: all 0;
  -o-transition: all 0;
  transition: all 0;
}

.clients .brand:hover .char {
  -webkit-animation: fadeInUp 0.3s cubic-bezier(0.3, 0, 0.7, 1) both;
  animation: fadeInUp 0.3s cubic-bezier(0.3, 0, 0.7, 1) both;
  -webkit-animation-delay: calc(20ms * var(--char-index));
  animation-delay: calc(20ms * var(--char-index));
}

.clients .brand .item {
  position: relative;
  text-align: center;
  padding: 80px 20px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  /*  border-radius: 10px;*/
}

.clients .brand .item .link {
  font-size: 16px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  opacity: 0;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.clients .brand .img {
  opacity: 0.8;
  max-width: 100px;
  margin: auto;
  position: relative;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

/* .clients-carso .img {
  margin: auto;
} */

/* .clients-carso2 {
  padding: 60px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
} */

/* .clients-carso2 .img {
  margin: auto;
  opacity: 0.8;
} */

/* .clients-carso2 .img:hover {
  opacity: 1;
} */

/* .brand-box .box-bord {
  border: 1px solid rgba(0, 0, 0, 0.08);
  padding: 140px 30px 60px;
} */

.img-scale {
  position: relative;
  height: 500px;
}

.img-scale .top-curve {
  position: absolute;
  top: -2px;
  right: 0;
  background: #fff;
  padding: 40px;
  /*  border-radius: 0 0 0 30px;*/
  z-index: 3;
}

.img-scale .top-curve .shap-left-top {
  position: absolute;
  top: 0;
  left: -2.05rem;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.img-scale .top-curve .shap-left-top svg {
  width: 2.1rem;
  height: 2.1rem;
}

.img-scale .top-curve .shap-right-bottom {
  position: absolute;
  bottom: -2.12rem;
  right: 0;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.img-scale .top-curve .shap-right-bottom svg {
  width: 2.1rem;
  height: 2.1rem;
}

.img-scale .bottom-curve {
  position: absolute;
  left: -1px;
  bottom: -1px;
  padding: 30px 140px;
  background: #fff;
  /*  border-radius: 0 30px 0 0;*/
  z-index: 3;
}

.img-scale .bottom-curve .shap-left-top {
  position: absolute;
  top: -2.05rem;
  left: 1px;
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.img-scale .bottom-curve .shap-left-top svg {
  width: 2.1rem;
  height: 2.1rem;
}

.img-scale .bottom-curve .shap-right-bottom {
  position: absolute;
  bottom: 0;
  right: -2.1rem;
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.img-scale .bottom-curve .shap-right-bottom svg {
  width: 2.1rem;
  height: 2.1rem;
}

.img-scale .image {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.img-scale .image > .img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.img-scale .image img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center center;
  object-position: center center;
}

.img-scale .vid-circle {
  width: 200px;
  height: 200px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  z-index: 5;
}

/* Contact
-----------------------------------------------------------------*/
.contact input,
.contact textarea {
  width: 100%;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  background: transparent;
  padding: 15px 0;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.contact input:focus,
.contact textarea:focus {
  border-color: #1a1a1a;
}

.contact textarea {
  min-height: 180px;
}

.contact .info-box {
  padding: 40px 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

.contact .info-box:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../imgs/map.png);
  background-size: cover;
  opacity: 0.3;
  z-index: -1;
}

.contact .form2 input,
.contact .form2 textarea {
  padding: 15px 30px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 30px;
}

/* .contact-modern .social-box {
  height: 100%;
  overflow: hidden;
} */

/* .contact-modern .social-box .item {
  padding: 30px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  width: calc(50% - 20px);
  height: calc(50% - 10px);
  display: inline-block;
  margin-bottom: 20px;
  margin-right: 20px;
  border-radius: 15px;
  position: relative;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s; 
}*/

/* .contact-modern .social-box .item:hover .icon {
  background: rgba(0, 0, 0, 0.08);
} */

/* .contact-modern .social-box .item:nth-of-type(3),
.contact-modern .social-box .item:nth-of-type(4) {
  margin-bottom: 0;
} */

/* .contact-modern .social-box .item .icon {
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  font-size: 18px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
} */

/* .contact-modern .social-box .item h6 {
  position: absolute;
  bottom: 30px;
  left: 30px;
} */

/* .contact-modern .social-box .item h6 span {
  width: 20px;
  margin-right: 10px;
} */

/* .contact-modern .contact-form input,
.contact-modern .contact-form textarea {
  width: 100%;
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0;
  background: transparent;
} */

/* .contact-modern.box-form {
  padding: 40px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 15px;
} */

.contact-crev {
  position: relative;
}

/* .contact-crev:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  background-image: url(../imgs/patterns/lines1.png);
  background-size: cover;
  background-position: center top;
  opacity: 0.12;
  z-index: -1;
} */

/* .contact-crev input,
.contact-crev textarea {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: transparent;
  padding: 15px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
} */

.contact-crev input:focus,
.contact-crev textarea:focus {
  border-color: #1a1a1a;
}

.contact-crev textarea {
  min-height: 180px;
}

.social-icon-circle a {
  width: 55px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  /* border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.02); */
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.social-icon-circle a:hover {
  color: #3a4197;
}

.google-map {
  height: 450px;
  width: 100%;
  position: relative;

}

.google-map iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* .call-box-action .thbox {
  border: 2px solid rgba(0, 0, 0, 0.08);
  border-radius: 30px;
  position: relative;
  overflow: hidden;
} */

/* Features
-----------------------------------------------------------------*/
.accordion .title {
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  cursor: pointer;
  position: relative;
  margin-bottom: 10px;
  margin-left: 40px;
  margin-top: 15px;
}

.accordion .title .ico {
  position: absolute;
  bottom: 20px;
  right: 15px;
}

.accordion .accordion-info {
  display: none;
  padding: 30px;
  background: rgba(255, 255, 255, 0.02);
  border-radius: 15px;
}

.accordion .active .title .ico {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.accordion .active .accordion-info {
  display: block;
}

.list-arrow li .icon {
  width: 15px;
  margin-right: 18px;
  margin-top: -3px;
}

.list-arrow li {
  display: flex;
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 8px;
}

/* .dot-list li {
  position: relative;
  padding-left: 15px;
} */

/* .blc-clr {
  color: #000;
} */

/* .dot-list li:before {
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 0;
  background: #1a1a1a;
} */

.text-dark .dot-list li:before {
  background: #1a1a1a;
}

/* Footer
-----------------------------------------------------------------*/
footer .subscribe-minimal .form-group {
  position: relative;
}

footer .subscribe-minimal input {
  color: #fff;
  background: transparent;
  border-radius: 30px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
  padding: 15px 30px;
}

footer .subscribe-minimal input:focus {
  border-color: rgba(255, 255, 255, 0.5);
}

footer .subscribe-minimal button {
  color: #fff;
  padding: 15px 0;
  position: absolute;
  top: 5px;
  right: 30px;
  background: transparent;
  border: 0;
}

footer .logo {
  width: 100% !important;
}

footer.clean-footer.crev {
  position: relative;
}

footer.clean-footer.crev .bord-thin-top,
footer.clean-footer.crev .bord-thin-left,
footer.clean-footer.crev .bord-thin-right,
footer.clean-footer.crev .bord-thin-bottom {
  border-color: rgba(255, 255, 255, 0.1);
}

footer.clean-footer.crev:after {
  content: "";
  position: absolute;
  top: 0;
  left: 40px;
  right: 0;
  bottom: 40px;
  background: #1a1a1a;
  z-index: 2;
}

.modern-footer {
  background: #1a1a1a;
  color: #fff;
}

.modern-footer .sub-bg {
  background: #1d1d1d !important;
}

.modern-footer.sub-bg {
  background: #1d1d1d !important;
  color: #fff;
}

.modern-footer.sub-bg .main-bg {
  background: #1a1a1a !important;
}

.modern-footer .bord-thin-top,
.modern-footer .bord-thin-left,
.modern-footer .bord-thin-right,
.modern-footer .bord-thin-bottom {
  border-color: rgba(255, 255, 255, 0.1);
}

/* .modern-footer .butn.butn-bord-thin {
  border-color: rgba(255, 255, 255, 0.1);
  color: #fff;
} */

/* .modern-footer .bord-left {
  position: relative;
} */

/* .modern-footer .bord-left:after {
  content: "";
  position: absolute;
  top: -80px;
  bottom: -80px;
  left: -80px;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
} */

/* Headers
-----------------------------------------------------------------*/
.main-header {
  padding: 120px 0 0;
  background-size: cover;
}

.banner-content {
  position: absolute;
  bottom: 5rem;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.main-header .container-fluid {
  width: calc(100% - 100px);
  margin: auto;
}

.main-header .lg-text {
  margin-bottom: -50px;
}

.main-header .lg-text h1 {
  text-transform: uppercase;
  font-size: 50px;
  line-height: 1;
  font-weight: 500;
  color: #fff;
}

/* .digital-header {
  min-height: 85vh;
  width: calc(100% - 60px);
  margin: auto;
  position: relative;
  background-position: top;
} */

.digital-header .caption {
  position: relative;
  background: #f5f7f9;
  padding: 20px 20px 20px 40px;
  margin-top: -1px;
  z-index: 3;
}

.digital-header .caption h2 {
  color: #1a1a1a;
}

.digital-header .caption .shap-left-top {
  position: absolute;
  display: none;
  top: 0;
  left: -2.05rem;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.digital-header .caption .shap-left-top svg {
  width: 2.1rem;
  height: 2.1rem;
}

.digital-header .caption .shap-right-bottom {
  position: absolute;
  bottom: -2.1rem;
  right: 10px;
  display: none;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.digital-header .caption .shap-right-bottom svg {
  width: 2.1rem;
  height: 2.1rem;
}

/* .digital-header .text-info {
  position: absolute;
  left: -1px;
  bottom: -1px;
  padding: 20px;
  background: #f5f7f9;
  z-index: 3;
} */

/* .digital-header .text-info .shap-left-top {
  position: absolute;
  top: -2.05rem;
  left: 1px;
  display: none;
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
} */

/* .digital-header .text-info .shap-left-top svg {
  width: 2.1rem;
  height: 2.1rem;
} */

/* .digital-header .text-info .shap-right-bottom {
  position: absolute;
  bottom: 0;
  display: none;
  right: -2.1rem;
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
} */

/* .digital-header .text-info .shap-right-bottom svg {
  width: 2.1rem;
  height: 2.1rem;
} */

/* .crev-agency-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
} */

.crev-agency-header .caption {
  padding-left: 15px;
  width: calc(100% + 30px);
  position: relative;
  z-index: 3;
}

.crev-agency-header .caption h2 {
  font-size: 40px;
  line-height: 1.1;
  color: #3a4197;
  font-weight: 500;
}

.crev-agency-header .left-clumn,
.crev-agency-header .right-clumn {
  padding: 40px 30px;
}

.crev-agency-header .left-clumn .img,
.crev-agency-header .right-clumn .img {
  border-radius: 0;
  overflow: hidden;
}

.crev-agency-header .left-clumn {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.crev-agency-header .left-clumn .img {
  margin-bottom: 140px;
}



.crev-agency-header .right-clumn .img {
  margin-top: 70px;
}

/* .crev-portfolio-header {
  position: relative;
} */
/* 4th check  */

.crev-portfolio-header .caption h1 {
  font-size: 74px;
}



.view-tog-wrap .img-vid {
  position: relative;
}

.view-tog-wrap .img-vid .curv-butn {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 40%;
  right: 0;
  margin: 0 auto;
  width: 65px;
  height: 65px;
  line-height: 45px;
  border-radius: 100%;
  text-align: center;
  box-sizing: content-box;
}

.view-tog-wrap .img-vid .curv-butn:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 39%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #f7f7f7;
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
}

.view-tog-wrap .img-vid .curv-butn:after {
  cursor: pointer;
  content: "";
  position: absolute;
  z-index: 1;
  left: 47%;
  top: 36%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 60px;
  height: 60px;
  background: #ffffff6e;
  border-radius: 50%;
  transition: all 200ms;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

.view-tog-wrap .img-vid .curv-butn .vid {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  z-index: 99;
  bottom: 10px;
  background: rgba(255, 255, 255, 0.04);
}

.startup-header .bg-img {
  min-height: 100vh;
  padding: 120px 0;
}

.startup-header .caption {
  position: relative;
}

.startup-header .caption .lg-text {
  font-size: 120px;
  text-transform: uppercase;
}

.startup-header .caption .lg-text .text {
  display: block;
}

.startup-header .caption .lg-text .text:nth-of-type(2) {
  padding-left: 30vw;
}



.freelancer .caption {
  text-align: center;
}

.freelancer .caption h1 {
  font-size: 13vw;
  font-weight: 800;
  line-height: 1.2;
  text-transform: uppercase;
  position: absolute;
  bottom: 50px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: -1;
}

.freelancer .caption h1 span {
  display: block;
  white-space: nowrap;
}

.freelancer .caption h1.stroke {
  color: transparent;
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0.8);
  z-index: 3;
}

.page-header-cerv {
  position: relative;
}

.page-header-cerv.section-padding,
.page-header.section-padding {
  padding: 70px 0;
}



.header-project1 .bg-img {
  height: 90vh;
  min-height: 500px;
}


.header-project6 .info .category {
  position: relative;
  padding-right: 10px;
}

.header-project6 .info .category:after {
  content: ",";
  position: absolute;
  top: 50%;
  right: 5px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.header-project6 .info .category:last-of-type:after {
  display: none;
}

.header-project6 .info .date {
  position: relative;
  padding-left: 10px;
}

.header-project6 .info .date:after {
  content: "";
  position: absolute;
  bottom: 5px;
  left: -2px;
  width: 3px;
  height: 3px;
  background: #3a4197;
}



@-webkit-keyframes move {
  0% {
    background-position: 0 0;
  }

  25% {
    background-position: 50% 0;
  }

  50% {
    background-position: 90% 0;
  }

  60% {
    background-position: 60%;
  }

  75% {
    background-position: 40%;
  }

  100% {
    background-position: 0 0;
  }
}

@keyframes move {
  0% {
    background-position: 0 0;
  }

  25% {
    background-position: 50% 0;
  }

  50% {
    background-position: 90% 0;
  }

  60% {
    background-position: 60%;
  }

  75% {
    background-position: 40%;
  }

  100% {
    background-position: 0 0;
  }
}




.interactive-inline .item .hover-reveal {
  position: absolute !important;
  top: -10px !important;
  left: 50% !important;
  -webkit-transform: translateX(-50%) translateY(-100%);
  -ms-transform: translateX(-50%) translateY(-100%);
  transform: translateX(-50%) translateY(-100%);
  width: 380px;
  height: 280px;
}




/* 3rd check */


.portfolio-tab .cluom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
}

.portfolio-tab .cluom:last-of-type {
  border-bottom: 0;
}

.portfolio-tab .cluom.current {
  border-left-color: transparent;
}

.portfolio-tab .cluom .more {
  margin-left: auto;
}

.portfolio-tab .cluom .img {
  position: absolute;
  top: -25px;
  right: 200px;
  width: 200px;
  height: 300px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.portfolio-tab .glry-img {
  width: 100%;
  height: 100%;
  position: relative;
}

.portfolio-tab .glry-img .bg-img {
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  -webkit-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
  opacity: 0;
  -webkit-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
  background-size: cover;
  background-position: center center;
}

.portfolio-tab .glry-img .bg-img.current {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.portfolio-tab.crev {
  border: 0;
}

.portfolio-tab.crev .cluom {
  padding: 0 0 15px;
  border-left: 0;
}

.portfolio-tab.crev .glry-img {
  width: 450px;
  height: 500px;
  position: relative;
}

.portfolio-tab.crev .glry-img .bg-img {
  height: 455px;
  position: absolute;
  top: 3px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  opacity: 0;
  -webkit-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
  background-size: cover;
  background-position: center center;
}

.portfolio-tab.crev .glry-img .bg-img:before {
  top: -50px;
  left: 50px;
  -webkit-transform: translateY(-100px);
  -ms-transform: translateY(-100px);
  transform: translateY(-100px);
}

.portfolio-tab.crev .glry-img .bg-img:after {
  bottom: -50px;
  right: 50px;
  -webkit-transform: translateY(100px);
  -ms-transform: translateY(100px);
  transform: translateY(100px);
}

.portfolio-tab.crev .glry-img .bg-img:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 100px;
  background: #ddd;
  opacity: 0;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  display: none;
  transition: all 0.4s;
  -webkit-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;
  transition-delay: 0.4s;
  width: 160px;
  height: 80px;
  border-bottom-left-radius: 160px;
  border-bottom-right-radius: 160px;
}

.portfolio-tab.crev .glry-img .bg-img:before {
  content: "";
  position: absolute;
  width: 20px;
  height: 100px;
  background: #3a4197;
  opacity: 0;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;
  transition-delay: 0.4s;
  display: none;
  left: 20px;
  border-top-left-radius: 160px;
  border-top-right-radius: 160px;
  left: 20px;
  width: 160px;
  height: 80px;
  border-top-left-radius: 160px;
  border-top-right-radius: 160px;
}

.portfolio-tab.crev .glry-img .bg-img.current {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.portfolio-tab.crev .glry-img .bg-img.current:after,
.portfolio-tab.crev .glry-img .bg-img.current:before {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.work-minimal .item:hover .img .cont {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.work-minimal .item .img {
  position: relative;
}

.work-minimal .item .img .cont {
  position: absolute;
  bottom: 15px;
  left: 15px;
  right: 15px;
  padding: 15px 30px;
  background: #fff;
  /*  border-radius: 0;*/
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.work-minimal .item .img .cont h5 {
  font-size: 18px;
}

.work-minimal .item .img .cont p {
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.work-minimal .item .img .cont img {
  width: 20px;
}

.work-minimal .item .img .cont.inline {
  padding: 25px 30px;
}



/* 2nd check */
.work-sticky .items {
  margin-top: 80px;
}

.work-sticky .items .cont {
  padding: 0 40px;
  background: #eee;
  height: 100%;
}

.work-sticky .items .cont .sticky-item {
  padding: 40px 0;
}

.work-sticky .items .img {
  overflow: hidden;
}



.works .panel {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 30px;
}

.works .panel .item {
  width: calc(50vw - 80px);
}

.works .panel .item .img {
  height: 480px;
  min-height: 50vh;
}

.works .panel .item .img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

.works .panel .item .cont {
  padding: 25px 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.works .panel .item .cont h5 {
  margin-bottom: 5px;
}

.works .panel .item .cont span {
  font-size: 16px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 5px;
}

.filtering span {
  margin: 0 20px;
  cursor: pointer;
  position: relative;
}

.filtering span:after {
  content: "";
  position: absolute;
  left: -2px;
  right: 110%;
  bottom: -2px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.filtering span.text {
  cursor: default;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 13px;
}

.filtering span.text:after {
  display: none;
}

.filtering span.active:after {
  right: -2px;
}


/* checked */

.serv-img .img .circle-button .shap-right-top svg {
  width: 2.1rem;
  height: 2.1rem;
}

.services .item-box.about {
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 15px;
}

.services .item-box .image {
  height: 350px;
  overflow: hidden;
}

.services .item-box .icon-box {
  display: inline-block;
  -webkit-box-shadow: inset -2px -2px 2px rgba(65, 65, 65, 0.14),
    inset 2px 2px 5px rgba(7, 7, 7, 0.38);
  box-shadow: inset -2px -2px 2px rgba(65, 65, 65, 0.14),
    inset 2px 2px 5px rgba(7, 7, 7, 0.38);
  width: 80px;
  height: 80px;
  padding: 15px;
}

.services .item-box .icon-box img {
  opacity: 0.7;
}

.services .item-box.bg {
  background: #f5f7f9;
}

.services .item-box2 {
  padding: 40px;
  background: #f5f7f9;
  position: relative;
  overflow: hidden;
}

.services .item-box2:hover .rmore {
  opacity: 1;
  top: 0px;
  right: 0px;
}

.services .item-box2:hover .rmore .arrow img {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  opacity: 1;
}

.services .item-box2 .icon {
  width: 70px;
  opacity: 0.5;
}

.services .item-box2 .rmore {
  position: absolute;
  top: -20px;
  right: -20px;
  background: #fff;
  padding: 10px;
  /*  border-radius: 0 0 0 30px;*/
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  opacity: 0;
}

.services .item-box2 .rmore:hover .arrow {
  background: #3a4197;
}

.services .item-box2 .rmore .arrow {
  width: 70px;
  height: 70px;
  line-height: 70px;
  border: 1px solid rgba(0, 0, 0, 0.08);

  text-align: center;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.services .item-box2 .rmore .arrow img {
  opacity: 0;
  -webkit-transform: translateX(-20px) translateY(20px);
  -ms-transform: translateX(-20px) translateY(20px);
  transform: translateX(-20px) translateY(20px);
  -webkit-transition-delay: 0.8s;
  -o-transition-delay: 0.8s;
  transition-delay: 0.8s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.services .item-box2 .rmore .shap-left-top {
  position: absolute;
  top: 0;
  left: -2.1rem;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  line-height: 1;
}

.services .item-box2 .rmore .shap-left-top svg {
  width: 2.1rem;
  height: 2.1rem;
}

.services .item-box2 .rmore .shap-right-bottom {
  position: absolute;
  bottom: -2.1rem;
  right: 0;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  line-height: 1;
}

.services .item-box2 .rmore .shap-right-bottom svg {
  width: 2.1rem;
  height: 2.1rem;
}

.services .serv-swiper {
  -webkit-clip-path: inset(-100vw 0);
  clip-path: inset(-100vw 0);
}

.services .swiper-container {
  overflow: visible;
}

.services-tab .serv-tab-cont .tab-content {
  display: none;
}

.services-tab .serv-tab-cont .tab-content.current {
  display: block;
}

.services-tab .serv-tab-cont .item {
  position: relative;
}

.services-tab .serv-tab-cont .item .img {
  width: 70%;
  height: 540px;
}

.services-tab .serv-tab-cont .item .cont {
  position: absolute;
  bottom: -80px;
  left: 20%;
  right: 0;
  padding: 80px 40px;
}

.services-tab .serv-tab-link .item-link {
  cursor: pointer;
}

.services-tab .serv-tab-link .item-link span {
  margin-right: 30px;
}

.services-tab .half-img-left {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: left top;
  background-size: 50%;
  pointer-events: none;
}

.services-tab.revers .serv-tab-cont .item {
  position: relative;
}

.services-tab.revers .serv-tab-cont .item .img {
  width: 70%;
  margin-left: 30%;
  height: 540px;
}

.services-tab.revers .serv-tab-cont .item .img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center center;
  object-position: center center;
}

.services-tab.revers .serv-tab-cont .item .cont {
  position: absolute;
  bottom: -2px;
  right: 30%;
  left: auto;
  padding: 60px 40px;
}

.services-tab.revers .serv-tab-cont .item .cont .bg-pattern {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: bottom;
  pointer-events: none;
}

.services-boxs .items {
  position: relative;
  /*  bottom: -30px;*/
}

.services-boxs .item-box {
  padding: 20px 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.services-boxs .item-box.bg {
  background: #ffffff;
  height: 400px;
  overflow: hidden;
  width: 255px;
}

.services-boxs .item-box.bg .icon img {
  /* height: 150px; */
  object-fit: contain;
  transition: all 1s ease;
  width: 210px;
}

.services-boxs .item-box.bg:hover .icon img {
  -webkit-transform: scale(1.25);
  -moz-transform: scale(1.25);
  -ms-transform: scale(1.25);
  -o-transform: scale(1.25);
  transform: scale(1.25);
}

.services-boxs .item-box.bg .icon {
  /* height: 150px; */
  overflow: hidden;
}

.services-boxs .item-box.bg .icon img {
  /* height: 150px; */
  object-fit: cover;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.services-boxs .item-box h5,
.services-boxs .item-box p {
  -webkit-transform: translateY(50px);
  -ms-transform: translateY(50px);
  transform: translateY(50px);
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.services-boxs .item-box p {
  opacity: 0;
}

.services-boxs .item-box:hover h5,
.services-boxs .item-box:hover p {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  opacity: 1;
}

.services-crev .item-box {
  padding: 40px 60px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: rgba(0, 0, 0, 0.01);
  position: relative;
}

.services-crev .item-box .num {
  position: absolute;
  top: 40px;
  left: 40px;
  opacity: 0.7;
}

.services-crev .item-box .icon {
  width: 80px;
}

.services-details .serv-imgs .img {
  height: 400px;
}

.full-showcase .parallax-slider .swiper-slide .bg-img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: center;
}

.full-showcase .parallax-slider .swiper-slide .video-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.full-showcase .parallax-slider .swiper-slide .video-container video {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center center;
  object-position: center center;
}

.full-showcase .parallax-slider .swiper-slide-active .caption .thin,
.full-showcase .parallax-slider .swiper-slide-active .caption .btn-curve,
.full-showcase .parallax-slider .swiper-slide-active .caption p {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.full-showcase .parallax-slider .caption h1 {
  font-size: 100px;
}

.full-showcase .parallax-slider .caption .cal-act {
  position: relative;
}

.full-showcase .parallax-slider .caption p {
  color: #eee;
  margin-top: 15px;
  opacity: 0;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}

.full-showcase .swiper-controls .swiper-button-prev,
.full-showcase .swiper-controls .swiper-button-next {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  top: auto;
  color: #fff;
  z-index: 9;
}

.full-showcase .swiper-controls .swiper-button-prev:after,
.full-showcase .swiper-controls .swiper-button-next:after {
  display: none;
}

.full-showcase .swiper-controls .swiper-button-prev span,
.full-showcase .swiper-controls .swiper-button-next span {
  white-space: nowrap;
}

.full-showcase .swiper-controls .swiper-button-prev {
  left: 120px;
}

.full-showcase .swiper-controls .swiper-button-prev i {
  margin-right: 25px;
}

.full-showcase .swiper-controls .swiper-button-next {
  right: 120px;
}

.full-showcase .swiper-controls .swiper-button-next i {
  margin-left: 25px;
}

.carousel-slider .gallery-img .bg-img {
  height: 60vh;
  overflow: hidden;
  position: relative;
  background-position: center;
}

.carousel-slider .gallery-img .bg-img a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.carousel-slider .gallery-text {
  position: absolute;
  width: auto;
  top: 70%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  text-align: center;
  margin: auto;
  z-index: 9;
}

.carousel-slider .gallery-text .swiper-container,
.carousel-slider .gallery-text .swiper-slide {
  height: 100px !important;
}

.carousel-slider .gallery-text .swiper-slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.carousel-slider .gallery-text .text {
  width: 100%;
  text-align: center;
  color: #fff;
}

.carousel-slider .gallery-text .text h4 {
  font-size: 50px;
  font-weight: 500;
  line-height: 1;
}

.carousel-slider .gallery-text .text h6 {
  margin-top: 15px;
  overflow: hidden;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 3px;
}

.carousel-slider .gallery-text .text h6 span {
  -webkit-transform: translateY(50px);
  -ms-transform: translateY(50px);
  transform: translateY(50px);
  -webkit-transition: all 0.8s;
  -o-transition: all 0.8s;
  transition: all 0.8s;
}

.carousel-slider .gallery-text .swiper-slide-active .text h6 span {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.carousel-slider .swiper-controls .swiper-button-prev,
.carousel-slider .swiper-controls .swiper-button-next {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  top: auto;
  color: #1a1a1a;
  z-index: 9;
}

.carousel-slider .swiper-controls .swiper-button-prev:after,
.carousel-slider .swiper-controls .swiper-button-next:after {
  display: none;
}

.carousel-slider .swiper-controls .swiper-button-prev span,
.carousel-slider .swiper-controls .swiper-button-next span {
  white-space: nowrap;
}

.carousel-slider .swiper-controls .swiper-button-prev {
  left: 120px;
}

.carousel-slider .swiper-controls .swiper-button-prev i {
  margin-right: 25px;
}

.carousel-slider .swiper-controls .swiper-button-next {
  right: 120px;
}

.carousel-slider .swiper-controls .swiper-button-next i {
  margin-left: 25px;
}

.half-slider .gallery-img .swiper-slide-active .bg-img {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.half-slider .gallery-img .bg-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-transition: all 0.6s linear;
  -o-transition: all 0.6s linear;
  transition: all 0.6s linear;
  background-position: center;
}

.half-slider .gallery-img .bg-img a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.half-slider .gallery-text {
  position: absolute;
  width: auto;
  top: 50%;
  left: -15vw;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 9;
}

.half-slider .gallery-text .text {
  color: #fff;
}

.half-slider .gallery-text .text h4 {
  font-size: 70px;
  font-weight: 500;
  color: transparent;
  -webkit-text-stroke: 0.5px rgba(255, 255, 255, 0.5);
  -webkit-transition: all 0.6s;
  -o-transition: all 0.6s;
  transition: all 0.6s;
}

.half-slider .gallery-text .text h6 {
  margin-bottom: 15px;
  overflow: hidden;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 3px;
}

.half-slider .gallery-text .text h6 span {
  -webkit-transform: translateY(50px);
  -ms-transform: translateY(50px);
  transform: translateY(50px);
  -webkit-transition: all 0.8s;
  -o-transition: all 0.8s;
  transition: all 0.8s;
}

.half-slider .gallery-text .swiper-slide-active .text h4 {
  color: #fff;
  -webkit-text-stroke: 0.5px rgba(255, 255, 255, 0);
}

.half-slider .gallery-text .swiper-slide-active .text h6 span {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.half-slider .swiper-controls {
  position: absolute;
  bottom: 50px;
  left: 0;
  width: 100%;
  z-index: 9;
}

.half-slider .swiper-controls .swiper-button-prev,
.half-slider .swiper-controls .swiper-button-next {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  top: auto;
  color: #fff;
  z-index: 9;
}

.half-slider .swiper-controls .swiper-button-prev:after,
.half-slider .swiper-controls .swiper-button-next:after {
  display: none;
}

.half-slider .swiper-controls .swiper-button-prev span,
.half-slider .swiper-controls .swiper-button-next span {
  white-space: nowrap;
}

.half-slider .swiper-controls .swiper-button-prev {
  left: 120px;
}

.half-slider .swiper-controls .swiper-button-prev i {
  margin-right: 25px;
}

.half-slider .swiper-controls .swiper-button-next {
  right: 120px;
}

.half-slider .swiper-controls .swiper-button-next i {
  margin-left: 25px;
}

.half-slider .path {
  display: none;
}

.slider .parallax-slider .caption h2 {
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 7px;
}

.slider .parallax-slider .caption h1 {
  font-size: 110px;
}

.swiper-arrow-control {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 25px;
}

.swiper-arrow-control .swiper-button-next,
.swiper-arrow-control .swiper-button-prev {
  position: static;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0.01);
  border: 1px solid rgba(0, 0, 0, 0.05);
  /*  border-radius: 50%;*/
  color: inherit;
  line-height: 18px;
  font-size: 18px;
  text-align: center;
}

.swiper-arrow-control .swiper-button-next:after,
.swiper-arrow-control .swiper-button-prev:after {
  display: none;
}

.swiper-arrow-control .swiper-button-next {
  margin-left: 15px;
}

/* Team
-----------------------------------------------------------------*/
.team .item {
  position: relative;
  padding-right: 60px;
}

.team .item:hover .img .info {
  bottom: 15px;
  opacity: 1;
}

.team .item:hover .social {
  right: 5px;
  -webkit-transform: rotate(8deg);
  -ms-transform: rotate(8deg);
  transform: rotate(8deg);
}

.team .item .img {
  overflow: hidden;
  position: relative;
  z-index: 2;
}

.team .item .img .info {
  position: absolute;
  left: 15px;
  right: 15px;
  bottom: -15px;
  padding: 15px;
  text-align: center;
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  /*  border-radius: 10px;*/
  opacity: 0;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.team .item .social {
  position: absolute;
  top: 50px;
  left: 50px;
  right: 60px;
  bottom: 40px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: #f5f7f9;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.team .item .social .links {
  position: absolute;
  top: 15px;
  right: 15px;
}

.team .item .social .links a {
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 5px;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.team .item .social .links a:hover {
  background: #3a4197;
  border-color: #3a4197;
  color: #111;
}

.team-tab .cluom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.team-tab .cluom:last-of-type {
  border-bottom: 0;
}

.team-tab .cluom.current {
  border-left-color: transparent;
}

.team-tab .cluom .more {
  margin-left: auto;
}

.team-tab .glry-img {
  width: 100%;
  height: 100%;
  position: relative;
}

.team-tab .glry-img .bg-img {
  position: absolute;
  top: 80px;
  left: 80px;
  right: 80px;
  bottom: 80px;
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
  opacity: 0;
  -webkit-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
  background-size: cover;
  background-position: center center;
}

.team-tab .glry-img .bg-img.current {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.team-tab.crev {
  border: 0;
}

.team-tab.crev .cluom {
  padding: 0 0 15px;
  border-left: 0;
}

.team-tab.crev .glry-img {
  width: 450px;
  height: 450px;
  position: relative;
  -webkit-transform: rotate(-10deg);
  -ms-transform: rotate(-10deg);
  transform: rotate(-10deg);
}

.team-tab.crev .glry-img .bg-img {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  opacity: 0;
  -webkit-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
  background-size: cover;
  background-position: center center;
}

.team-tab.crev .glry-img .bg-img:before {
  top: -50px;
  left: 50px;
  -webkit-transform: translateY(-100px);
  -ms-transform: translateY(-100px);
  transform: translateY(-100px);
}

.team-tab.crev .glry-img .bg-img:after {
  bottom: -50px;
  right: 50px;
  -webkit-transform: translateY(100px);
  -ms-transform: translateY(100px);
  transform: translateY(100px);
}

.team-tab.crev .glry-img .bg-img:after,
.team-tab.crev .glry-img .bg-img:before {
  content: "";
  position: absolute;
  width: 20px;
  height: 100px;
  background: #3a4197;
  opacity: 0;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.team-tab.crev .glry-img .bg-img.current {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.team-tab.crev .glry-img .bg-img.current:after,
.team-tab.crev .glry-img .bg-img.current:before {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.testimonials {
  position: relative;
}

.testimonials .small-line {
  position: absolute;
  bottom: -100px;
  left: 0;
  width: 400px;
  height: 400px;
  z-index: 1;
  pointer-events: none;
}

.testimonials .small-line .line-overlay svg path {
  stroke: #aaa;
}

.testimonials .item .text {
  position: relative;
}

.testimonials .item .text .qout-svg {
  position: absolute;
  left: -40px;
  top: -70px;
  width: 160px;
}

.testimonials .item .circle {
  width: 80px;
  height: 80px;
}

.testimonials .control-abslout {
  position: absolute;
  bottom: -40px;
  left: 15px;
}

.testim-crev .bord-box .info .qout-svg {
  width: 80px !important;
  height: 80px !important;
  margin-bottom: 0 !important;
}

.testim-crev .testim-swiper2 .item p {
  font-size: 17px;
}

.testimonials-crev .control-abslout {
  position: absolute;
  top: 50%;
  right: -15px;
  display: block;
  color: #fff;
}

.testimonials-crev .control-abslout .swiper-button-next {
  margin: 15px 0 0;
}

.testimonials-crev .control-abslout .swiper-button-prev,
.testimonials-crev .control-abslout .swiper-button-next {
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
}

.circle-button .arrow svg {
  width: 40px;
  height: 40px;
}

.circle-button .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

/* Shop
-----------------------------------------------------------------*/
/* ====================== [ Start Responsive ] ====================== */
@media screen and (max-width: 992px) {
  .md-mb10 {
    margin-bottom: 10px;
  }

  .md-mb30 {
    margin-bottom: 30px;
  }

  .md-mb50 {
    margin-bottom: 50px !important;
  }

  .md-mb80 {
    margin-bottom: 80px;
  }

  .justify-end {
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .justify-end .vew-all {
    margin-left: 25px;
    margin-top: 25px;
  }

  .justify-end .swiper-controls {
    margin-top: 30px;
  }

  .justify-end .ml-auto {
    margin-left: 0;
    margin-top: 30px;
  }

  h1 {
    font-size: 50px;
  }

  h2 {
    font-size: 40px;
  }

  h3 {
    font-size: 30px;
  }

  h4 {
    font-size: 28px;
  }

  h5 {
    font-size: 23px;
  }

  h6 {
    font-size: 18px;
  }

  .hamenu .menu-links {
    height: calc(100vh - 40px);
  }

  .sec-head h2 {
    font-size: 35px;
  }

  .main-header .lg-text h1 {
    font-size: 4vw;
  }

  .main-header .container-fluid {
    width: 100%;
  }

  .crev-agency-header {
    padding: 100px 0;
  }

  .crev-agency-header .left-clumn {
    display: none;
  }

  .services-boxs .items:nth-of-type(even) {
    top: auto;
  }

  .services-boxs .items {
    bottom: auto;
  }

  .services-crev .item-box {
    padding: 40px;
  }

  .services-crev .item-box .icon {
    margin-right: 50px !important;
  }

  .services-tab.mb-80 {
    margin-bottom: 0 !important;
  }

  .team-tab .glry-img,
  .portfolio-tab .glry-img {
    display: none;
  }

  .portfolio-tab .cluom:hover .img {
    opacity: 1;
    visibility: visible;
  }

  .testimonials .small-line {
    display: none;
  }

  .testimonials-crev .item .content {
    margin-left: 0 !important;
  }

  .testimonials-crev .item .circle {
    display: none;
  }

  .img-scale .image img {
    -webkit-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
    height: 100%;
  }

  .clean-footer .fz-120 {
    font-size: 90px !important;
  }

  .clean-footer .logo {
    margin-bottom: 50px;
  }

  .clean-footer .column {
    margin-bottom: 50px;
  }

  .clean-footer .mt-80 {
    margin-top: 30px !important;
  }

  .page-header .fz-100,
  .page-header-cerv .fz-100 {
    font-size: 80px !important;
  }

  .portfolio-fixed .left {
    display: none;
  }

  .portfolio-fixed .right {
    padding: 0 15px 120px;
  }

  .portfolio-fixed .cont {
    opacity: 1 !important;
    margin-bottom: 80px;
    padding: 0 15px;
  }

  .portfolio-fixed .cont .img-hiden {
    display: block;
    margin-bottom: 30px;
  }

  .works.thecontainer {
    width: 100%;
    min-height: auto;
    height: auto;
    display: block;
    padding: 70px 0 120px;
  }

  .works .panel {
    display: block;
    padding: 0 15px !important;
  }

  .works .panel .item {
    width: 100%;
    margin-top: 50px;
  }

  .img-column3 .img,
  .img-column .img {
    height: auto;
    min-height: auto;
  }

  .next-project .box .cont .fz-40 {
    font-size: 30px !important;
  }

  .full-showcase .parallax-slider .caption h1 {
    font-size: 80px;
  }

  .carousel-slider .gallery-text .text h4 {
    font-size: 34px;
  }

  .half-slider .gallery-img {
    width: 100%;
    margin-left: 0;
  }

  .half-slider .gallery-text {
    left: 0;
  }

  .half-slider .gallery-text .text {
    padding-left: 15px;
  }

  .half-slider .gallery-text {
    width: 100%;
  }

  .portfolio-tab .cluom .img {
    position: absolute;
    top: -25px;
    right: 200px;
    width: 100px;
  }
}
.go-more-css::after{
  right: -12px !important;
}

.archive-container {
  background-color: #f8f9fa; 
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); 
  margin-bottom: 20px;
  transition: all 0.3s ease-in-out;
}

.circular-header {
  font-size: 16px !important;
  font-weight: bold;
  color: #3a4197;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease-in-out, transform 0.2s ease;
}

.circular-header:hover {
  background: #e8e8ff; 
  transform: translateY(-2px); 
}

.toggle-icon {
  font-size: 18px;
  transition: transform 0.3s ease-in-out;
}

.circular-header:hover .toggle-icon {
  color: #292d75; 
}

.year-filter {
  margin-top: 30px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  margin-bottom: 20px;
}

.year-filter label {
  font-weight: 500;
  color: #3a4197;
  font-size: 18px;
  margin-right: 10px; 
}

.filter-dropdown {
  padding: 10px 15px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #ccc;
  background-color: #fff;
  color: #333;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.filter-dropdown:focus {
  border-color: #3a4197;
  outline: none;
  box-shadow: 0 0 8px rgba(58, 65, 151, 0.3); 
}

.filter-dropdown option {
  padding: 10px;
}

.filter-dropdown:hover {
  border-color: #3a4197;
  cursor: pointer;
}

.filter-button {
  padding: 8px 15px;
  font-size: 16px;
  color: #fff;
  background-color: #3a4197;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.filter-button:hover {
  background-color: #2e357e;
}


.timetable-res {
  width: 100%;
  border-collapse: collapse;
  margin-top: 30px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.timetable-res thead {
  background-color: #3a4197;
  color: #fff;
}

.timetable-res th, .timetable-res td {
  padding: 12px 15px;
  text-align: center;
  font-size: 16px;
  border-right: 1px solid #ddd;
  transition: all 0.3s ease;
}

.timetable-res th {
  font-weight: bold;
  text-transform: uppercase;
}

.timetable-res tr:nth-child(even) {
  background-color: #f9f9f9;
}

.timetable-res tr:hover {
  background-color: #f1f1f1;
}

.timetable-res a {
  color: #3a4197;
  text-decoration: none;
  transition: color 0.3s ease;
}

.timetable-res a:hover {
  color: #2e357e;
}

.timetable-res td:last-child {
  border-right: none;
}

.border-right {
  border-right: 1px solid #ddd;
}

/* Responsive Design */
@media (max-width: 768px) {
  .timetable-res th, .timetable-res td {
    font-size: 14px;
    padding: 10px;
  }

  .timetable-res {
    font-size: 14px;
  }

  /* .rwd-table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  } */

  .timetable-res td, .timetable-res th {
    display: inline-block;
    text-align: left;
    width: auto;
  }

  .timetable-res th {
    text-align: left;
  }
}

@media (min-width: 992px) {
  .small-screen-titles {
    display: none; 
  }

  .large-screen-titles {
    display: block; 
  }
}
@media (max-width: 991px) {
  .small-screen-titles {
    display: block;
  }

  .large-screen-titles {
    display: none; 
  }
}


.test-li ul li{
  position: relative;
}
.test-li ul li:before{
  content: "";
  width: 8px;
  height: 7px;
  background: #656560;
  position: absolute;
  top: 8px;
  left: -15px;
  border-radius: 50%;
}
.test-li li {
  line-height: 1.5;
  color: #424242;
  font-family: Poppins, sans-serif;
  font-size: 13.3333px;
  text-align: justify;
}



.remove-top-margin {
  margin-top: 0;
}
.logo {
  border-radius: 50%;
  max-width: 150px;
  max-height: 180px;
}

/* .bg-img-wrk-with-us {

  background:linear-gradient(20deg, rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0.64)),url("https://images.unsplash.com/39/lIZrwvbeRuuzqOoWJUEn_Photoaday_CSD%20(1%20of%201)-5.jpg?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=92b4ef70a4e06a7e7bf54e1bde61b624&auto=format&fit=crop&w=1950&q=80") no-repeat center center fixed;
} */

/* .content {
  padding: 0 20px 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}



.counter {
  width: 500px;
}

@media (min-width: 481px) and (max-width: 767px) {

  .counter {
      width: 450px;
  }

}



@media (min-width: 320px) and (max-width: 480px) {

  .counter {
      width: 350px;
  }

} */