@media screen and (min-width: 320px) and (max-width: 370px) {
  .testimonial-image {
    height: 270px !important;
    width: 270px !important;
  }

  .services-boxs .item-box.bg .icon img {
    /* height: 150px; */
    object-fit: contain !important;
    transition: all 1s ease;
    width: 14rem;
  }

  .res-text {
    width: 280px !important;
  }
}

@media screen and (min-width: 371px) and (max-width: 400px) {
  .testimonial-image {
    height: 310px !important;
    width: 310px !important;
  }

  .services-boxs .item-box.bg .icon img {
    /* height: 150px; */
    object-fit: contain;
    transition: all 1s ease;
    width: 19rem;
  }

  .res-text {
    width: 325px !important;
  }
}

@media screen and (min-width: 401px) and (max-width: 425px) {
  .testimonial-image {
    height: 340px !important;
    width: 340px !important;
  }

  .services-boxs .item-box.bg .icon img {
    /* height: 150px; */
    object-fit: cover;
    transition: all 1s ease;
    width: 20rem;
  }

  .res-text {
    width: 360px !important;
  }
}

@media screen and (min-width: 426px) and (max-width: 475px) {
  .testimonial-image {
    height: 380px !important;
    width: 380px !important;
  }
}

@media screen and (min-width: 476px) and (max-width: 550px) {
  .testimonial-image {
    height: 425px !important;
    width: 425px !important;
  }
}

@media screen and (min-width: 551px) and (max-width: 600px) {
  .testimonial-image {
    height: 460px !important;
    width: 460px !important;
  }
}

@media screen and (min-width: 601px) and (max-width: 768px) {
  .testimonial-image {
    height: 490px !important;
    width: 490px !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 991px) {
  .testimonial-image {
    height: 475px !important;
    width: 500px !important;
  }
}

@media screen and (min-width: 992px) and (max-width: 1024px) {
  .testimonial-image {
    height: 450px !important;
    width: 475px !important;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1250px) {
  .testimonial-image {
    height: 450px !important;
    width: 475px !important;
  }
}

@media screen and (min-width: 1251px) and (max-width: 1440px) {
  .testimonial-image {
    height: 540px !important;
    width: 540px !important;
  }
}

@media screen and (min-width: 426px) and (max-width: 1023px) {
  .video-container-testimonial iframe {
    width: 100%;
    height: 100%;
  }
}

.secrat {
  text-align: justify;
}

@media screen and (max-width: 768px) {
  .para-psg {
    margin-top: -20px !important;
  }

  .about-us-texts {
    margin-top: -100px !important;
  }

  .testimonial-btn {
    margin-top: -9px !important;
  }

  .sm-mb10 {
    margin-bottom: 10px;
  }

  .sm-mb20 {
    margin-bottom: 20px;
  }

  .sm-mb30 {
    margin-bottom: 30px;
  }

  .sm-mb40 {
    margin-bottom: 40px;
  }

  .sm-mb50 {
    margin-bottom: 50px;
  }

  .sm-mb80 {
    margin-bottom: 80px;
  }

  .sm-pb10 {
    padding-bottom: 10px;
  }

  .sm-pb20 {
    padding-bottom: 20px;
  }

  .sm-pb30 {
    padding-bottom: 30px;
  }

  .sm-pb40 {
    padding-bottom: 40px;
  }

  .sm-pb50 {
    padding-bottom: 50px;
  }

  .sm-pb60 {
    padding-bottom: 60px;
  }

  .sm-pb70 {
    padding-bottom: 70px;
  }

  .sm-pb80 {
    padding-bottom: 80px;
  }

  .sm-hide {
    display: none;
  }

  .fz-70 {
    font-size: 40px !important;
  }

  h1 {
    font-size: 50px;
  }

  h2 {
    font-size: 34px;
  }

  h3 {
    font-size: 30px;
  }

  h4 {
    font-size: 28px;
  }

  h5 {
    font-size: 23px;
  }

  h6 {
    font-size: 18px;
  }

  .progress-wrap {
    bottom: 15px;
    right: 15px;
  }

  .in-box {
    width: 100%;
  }

  .in-box.mb-15 {
    margin-bottom: 0 !important;
  }

  .hamenu .logo {
    left: 25px;
  }

  .hamenu .close-menu {
    top: 25px;
    right: 25px;
  }

  .hamenu .menu-links .main-menu > li .link {
    font-size: 30px;
  }

  .hamenu .menu-links .main-menu > li .sub-dmenu {
    font-size: 24px;
  }

  .hamenu .menu-links .main-menu .sub-menu li,
  .hamenu .menu-links .main-menu .sub-menu2 li {
    font-size: 18px;
  }

  .sec-head .bord {
    display: block !important;
  }

  .sec-head .bord .ml-auto {
    margin-top: 15px !important;
  }

  .sec-head .bord .d-flex {
    display: block !important;
  }

  .sec-head h2 {
    font-size: 28px;
  }

  .sec-head .go-more {
    margin-top: 10px;
  }

  .main-header .lg-text {
    margin-bottom: -20px;
    text-align: center;
  }

  .main-header .lg-text h1 {
    text-align: left;
  }

  .crev-agency-header .caption h2 {
    font-size: 40px;
  }

  .digital-header {
    width: 100%;
  }

  .digital-header .caption {
    padding: 30px;
  }

  .digital-header .caption h1 {
    font-size: 30px;
  }

  body.crev-portfolio main {
    padding: 0;
  }

  body.crev-portfolio section .sec-head .fixed-head h5 {
    font-size: 25px;
  }

  .crev-portfolio-header .caption h1 {
    font-size: 35px;
  }

  .startup-header .caption .lg-text {
    font-size: 35px;
  }

  .startup-header {
    padding: 0;
  }

  .slider .parallax-slider .caption h1 {
    font-size: 50px;
  }

  .slider .parallax-slider .caption h2 {
    letter-spacing: 1px;
  }

  .vcard-bg {
    background-position: 40%;
  }

  .work-asymetic .caption .lg-text {
    font-size: 55px;
  }

  .intro-card h2 {
    font-size: 60px;
  }

  .intro-card h2 .botm {
    font-size: 40px;
  }

  .about-crev .left-block .info {
    padding: 0 20px;
  }

  .about-crev .left-block .mz-shap {
    top: -65px;
  }

  .about .cont .d-flex.mt-100 {
    display: block !important;
    margin-top: 80px !important;
  }

  .about .cont .d-flex .ml-80 {
    margin: 30px 0 0 !important;
  }

  .skills-img-crev .img {
    width: 100%;
  }

  .skills-img-crev .img img {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    margin: 0;
  }

  .skills-img-crev .img .rotate-butn-vid {
    bottom: 90px;
    left: -15px;
  }

  .about-crev .left-block {
    margin-top: 65px !important;
  }

  .services-crev .item-box .d-flex {
    display: block !important;
  }

  .services-crev .item-box .icon {
    margin: 0 0 30px !important;
  }

  .services-crev .item-box .num {
    left: auto;
    right: 40px;
  }

  .services-tab .serv-tab-cont .item .img {
    width: 90%;
  }

  .services-tab .serv-tab-cont .item .cont {
    left: 15px;
  }

  .services-tab.revers .serv-tab-cont .item .img {
    width: 100%;
    margin-left: 0;
  }

  .services-tab.revers .serv-tab-cont .item .cont {
    right: 5%;
    padding: 40px 30px;
  }

  .inter-fixed-text .links-img .img {
    height: 400px;
  }

  .team-tab .cluom,
  .portfolio-tab .cluom {
    padding: 40px 30px;
    overflow: hidden;
  }

  .team-tab .cluom .info h4,
  .portfolio-tab .cluom .info h4 {
    font-size: 18px;
  }

  .team-tab .cluom .img,
  .portfolio-tab .cluom .img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .team-tab .cluom .img img,
  .portfolio-tab .cluom .img img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center center;
    object-position: center center;
  }

  .team-tab .cluom .img:after,
  .portfolio-tab .cluom .img:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #1a1a1a;
    opacity: 0.15;
  }

  .portfolio-tab.crev .cluom {
    padding: 30px 10px !important;
    margin: 0 !important;
  }

  .portfolio-tab.crev .cluom .sub-title {
    letter-spacing: 1px;
    font-size: 13px;
  }

  .portfolio-tab.crev .cluom i {
    display: none;
  }

  .sec-bottom {
    font-size: 12px;
  }

  .sec-bottom .d-flex {
    padding: 5px 10px;
  }

  .sec-bottom p {
    font-size: 12px;
  }

  .sec-bottom .ml-40 {
    margin-left: 10px !important;
  }

  .numbers .item {
    -webkit-box-pack: left !important;
    -ms-flex-pack: left !important;
    justify-content: left !important;
  }

  .half-block .colmn {
    padding: 40px 0;
  }

  .awards .item-line > div:last-of-type {
    text-align: left;
  }

  .testim-crev .item {
    padding: 50px 0 0;
  }

  .testim-crev .item .text {
    padding: 0 15px;
  }

  .testim-crev .item .text p {
    font-size: 18px !important;
  }

  .testim-crev .item .qout-svg {
    margin-right: 15px;
  }

  .testim-crev .item .info {
    margin-left: 0;
  }

  .testimonials .item .text .fz-30 {
    font-size: 16px !important;
  }

  .testimonials .item .text .qout-svg {
    position: absolute;
    left: -30px;
    top: -90px;
    width: 100px;
  }

  .testimonials .item .circle {
    width: 60px;
    height: 60px;
  }

  .testimonials .swiper-slide {
    padding-top: 60px;
    padding-left: 30px;
  }

  .testimonials .control-abslout {
    position: static;
    /* padding-left: 30px; */
    margin-top: 15px;
  }

  .testim-crev .testim-swiper2 .item .qout-svg {
    margin-left: 15px;
  }

  .testimonials-crev .item h4 {
    font-size: 22px;
  }

  .testimonials-crev .item .text .qout-svg {
    top: -100px;
    width: 100px;
  }

  .testimonials-crev .control-abslout {
    right: 0;
  }

  .testim-crev .testim2 .item {
    padding: 40px 15px;
  }

  .testim-crev .testim2 .item .info .ml-auto {
    position: absolute;
    right: 5px;
    bottom: 5px;
  }

  .testim-crev .testim2 .item .info .ml-auto .qout-svg {
    width: 60px !important;
  }

  .brands-crev {
    padding-top: 100px !important;
  }

  .brands-crev .item {
    padding: 70px 10px;
  }

  .brands-crev .item img {
    width: 80px;
  }

  .brands-crev .item svg {
    -webkit-transform: scale(0.5);
    -ms-transform: scale(0.5);
    transform: scale(0.5);
  }

  .brands-crev .item .top-left:after,
  .brands-crev .item .top-right:after,
  .brands-crev .item .bottom-left:after,
  .brands-crev .item .bottom-right:after {
    top: -10px;
    left: -10px;
    bottom: -10px;
    right: -10px;
  }

  .price-hr .item {
    display: block !important;
  }

  .price-hr .item .cont {
    margin: 15px 0 !important;
  }

  .price-hr .item .ml-auto {
    margin: 0 !important;
    text-align: left !important;
  }

  .price-hr .item .numb-font {
    font-size: 45px;
    margin-bottom: 10px;
  }

  .blog-list-half .item .cont .date {
    margin-top: 40px !important;
  }

  footer.clean-footer.crev:after {
    left: 0;
    bottom: 0;
  }

  footer.clean-footer.crev .cal-action .ml-30 {
    margin: 15px 0 0 !important;
    display: block;
  }

  .clean-footer .mt-80 {
    display: block !important;
  }

  .clean-footer .mt-80 .ml-auto {
    margin: 15px 0 0 !important;
  }

  .clean-footer .fz-120 {
    font-size: 70px !important;
  }

  .modern-footer .logo {
    width: 100px !important;
  }

  .modern-footer .social-media .ml-50 {
    margin-left: 15px !important;
  }

  .modern-footer .social-media a i {
    display: none;
  }

  .modern-footer .fz-60 {
    font-size: 30px !important;
  }

  .modern-footer .info {
    display: block !important;
  }

  .modern-footer .info .ml-30 {
    margin: 15px 0 0 !important;
  }

  footer .info-comp {
    display: block !important;
  }

  footer .info-comp .mr-30 {
    margin: 0 0 15px !important;
  }

  footer .sub-footer .links {
    -webkit-box-pack: inherit !important;
    -ms-flex-pack: inherit !important;
    justify-content: inherit !important;
  }

  footer .sub-footer .text-center {
    text-align: left !important;
  }

  .min-footer .logo {
    margin-bottom: 30px;
  }

  .min-footer .text-center {
    text-align: left !important;
  }

  .min-footer2 .logo {
    margin-top: 15px;
    margin-bottom: 20px;
  }

  .min-footer2 .links {
    -webkit-box-pack: inherit !important;
    -ms-flex-pack: inherit !important;
    justify-content: inherit !important;
  }

  .min-footer2 .order-md-3 .text-center {
    text-align: left !important;
  }

  .min-footer2 .fz-100 {
    font-size: 55px !important;
  }

  .page-header .fz-100,
  .page-header-cerv .fz-100 {
    font-size: 45px !important;
  }

  .team-single .cont {
    padding: 60px 5px 0;
  }

  .skills .resume-exp .box-items .item:nth-of-type(1) {
    border-right: 0;
  }

  .skills .resume-exp .box-items .item:nth-of-type(3) {
    border-right: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }

  .portfolio-fixed {
    padding-top: 100px;
  }

  .full-showcase .swiper-controls .swiper-button-next,
  .carousel-slider .swiper-controls .swiper-button-next,
  .half-slider .swiper-controls .swiper-button-next {
    right: 50px;
  }

  .full-showcase .swiper-controls .swiper-button-prev,
  .carousel-slider .swiper-controls .swiper-button-prev,
  .half-slider .swiper-controls .swiper-button-prev {
    left: 50px;
  }

  .full-showcase .swiper-pagination,
  .carousel-slider .swiper-pagination,
  .half-slider .swiper-pagination {
    display: none;
  }

  .full-showcase .parallax-slider .caption h1 {
    font-size: 40px;
  }

  .carousel-slider .gallery-text .text h4 {
    font-size: 24px;
  }

  .carousel-slider .gallery-text .text h6 {
    font-size: 14px;
    letter-spacing: 1px;
  }

  .half-slider .gallery-text .text h4 {
    font-size: 35px;
  }

  .inter-links-center .links-text li h2 {
    font-size: 28px !important;
  }

  .inter-links-center.vertical .links-text li,
  .inter-links-center .links-text li {
    padding: 20px 0;
  }

  .interactive-center .item h4 {
    font-size: 30px;
  }

  .interactive-center .item a {
    padding: 25px 0;
  }

  .interactive-center .item .hover-reveal {
    width: 90vw !important;
    height: 90vh !important;
    left: 5vw !important;
    top: 5vh !important;
  }
}

.banner-wrap {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.bg-video-wrap {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.bg-video-wrap:before {
  content: "";
  /* background: #3a41974d; */
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

video {
  min-width: 100%;
  min-height: 100vh;
  z-index: 1;
  object-fit: contain !important;
}

.logo {
  width: 14rem;
  padding: 8px 0;
  padding-left: 0;
}

.footer-logo-wrap {
  width: fit-content;
}

.top-footer {
  display: flex;
  align-items: center;
}

.news-bot-wrap a {
  font-size: 16px;
  font-weight: 400;
  cursor: pointer !important;
  text-align: justify;
}

.res-text {
  cursor: pointer !important;
}

.news-bot-wrap {
  padding-top: 20px;
  line-height: 1.5;
}

.top-men {
  top: 0;
  left: auto;
  right: 0;
  margin: 0 auto;
  text-align: center;
  background: #ddd;
  padding-top: 5px;
  width: 100%;
}

.top-men a {
  text-decoration: underline !important;
  color: #3a4197 !important;
}

.menu-logo {
  width: 15rem;
  margin: 50px 25px;
  margin-bottom: 5px;
  position: absolute;
  top: 0;
}

.bg-light {
  background: #f7f7f7;
}

.full-scale {
  height: 80vh;
}

.icon.vid-wrap {
  position: absolute;
  top: 25%;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
}

.icon.vid-wrap:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #f7f7f7;
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
}

.icon.vid-wrap:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 60px;
  height: 60px;
  background: #ffffff6e;
  border-radius: 50%;
  transition: all 200ms;
}

.icon.vid-wrap i {
  padding: 15px;
  border-radius: 5px;
  position: relative;
  z-index: 99;
}

.portfolio-carsouel .swiper-slide:hover .img img {
  -webkit-filter: blur(4px);
  filter: blur(4px);
}

.flr {
  float: right;
}

canvas {
  display: block;
  /* vertical-align: bottom; */
}

#particles-js {
  position: absolute;
  width: 100%;
  height: auto;
}

#particles1-js {
  position: fixed;
  width: 100%;
  height: 100%;
}

.image-hover-img {
  position: absolute;
  top: -100px;
  right: 0;
  width: 60%;
  height: 100vh;
  opacity: 2;
  z-index: -1;
}

.menuImages {
  position: absolute;
  /* top: -100px; */
  right: 0;
  /* width: 70% !important;
    height: 50vh !important; */
  /* opacity: 2; */
  /* z-index: -1;  */
}

.menuImages img {
  width: 100% !important;
  height: 100vh !important;
}

@media screen and (max-width: 525px) {
  .menuImages img {
    display: none !important;
  }
}

@media (min-width: 526px) and (max-width: 768px) {
  .menuImages img {
    padding-top: 50px;
    width: 100% !important;
    height: 90vh !important;
  }
}

@media screen and (max-width: 576px) {
  .image-hover-img {
    position: absolute;
    top: -100px;
    right: 0;
    width: 100%;
    height: 100vh;
    opacity: 2;
    z-index: -1;
  }
}

.image-hover-img img {
  width: 100%;
  height: 120vh;
  object-fit: cover;
  position: fixed;
}

.menu-image-1 {
  opacity: 2;
  visibility: visible;
}

.menu-image-2,
.menu-image-3,
.menu-image-4,
.menu-image-5,
.menu-image-6,
.menu-image-7,
.menu-image-8 {
  opacity: 0;
  visibility: hidden;
}

.menu:hover .menu-image- {
  opacity: 2;
  visibility: visible;
}

.menu2:hover .menu-image-2 {
  opacity: 2;
  visibility: visible;
}

.menu3:hover .menu-image-3 {
  opacity: 2;
  visibility: visible;
}

.menu4:hover .menu-image-4 {
  opacity: 2;
  visibility: visible;
}

.menu5:hover .menu-image-5 {
  opacity: 2;
  visibility: visible;
}

.menu6:hover .menu-image-6 {
  opacity: 2;
  visibility: visible;
}

.menu7:hover .menu-image-7 {
  opacity: 2;
  visibility: visible;
}

.menu8:hover .menu-image-8 {
  opacity: 2;
  visibility: visible;
}

.services .item-box .vid .img {
  height: 230px;
  overflow: hidden;
}

.services .item-box .vid .img img {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.services .item-box:hover .vid .img img {
  -webkit-transform: scale(1.25);
  -moz-transform: scale(1.25);
  -ms-transform: scale(1.25);
  -o-transform: scale(1.25);
  transform: scale(1.25);
}

.bg-white {
  background: #ffffff;
}

.manage-wrap {
  display: flex;
  align-items: center;
}

.code-wrap p {
  font-size: 16px !important;
  color: #000;
}

.code-wrap p span {
  color: #a41e35;
}

footer a {
  font-size: 14px;
}

.navbar .stic-view {
  display: none;
}

.navbar .nor-view {
  display: flex;
}

.navbar.nav-scroll .stic-view {
  display: flex;
}

.navbar.nav-scroll .nor-view {
  display: none;
}

/* ====================== [ End Responsive ] ====================== */
.item-box.radius-15 {
  padding: 10px;
  cursor: pointer;
}

#particles canvas {
  z-index: -1;
  height: 100%;
  width: 100%;
  position: absolute;
}

.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
  overflow-x: hidden;
  transition: width 0.5s;
}
body, html {
  overflow-x: hidden;
}
.overlay.open {
  width: 100%;
}

.overlay-content {
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.overlay a:hover,
.overlay a:focus {
  color: #f1f1f1;
}

.overlay .closebtn {
  position: absorelute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px;
  }

  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  background-color: #ffffff;
  overflow-x: hidden;
  -webkit-transition: all 0.5s cubic-bezier(1, 0, 0.55, 1);
  -o-transition: all 0.5s cubic-bezier(1, 0, 0.55, 1);
  transition: all 0.5s cubic-bezier(1, 0, 0.55, 1);
}

.sidenav a {
  text-decoration: none;
  font-size: 18px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  right: 25px;
  margin-left: 50px;
  z-index: 999999999999;
  font-size: 35px;
  font-weight: 200 !important;
  top: -50px;
  transition: top 0.9s ease-in-out;
}

.sidenav.open .closebtn {
  top: 5px;
}

.sidenav .closebtn.close-menu {
  transition: top 0.9s ease-in-out;
}

.sidenav .closebtn:hover {
  color: #f1f1f1;
}

.sidenav.open {
  width: 100%;
}

.tes-big-text {
  font-weight: 400;
  font-size: 38px;
  -webkit-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
  transition: all 0.8s;
  line-height: 1.3;
}

.particles-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

span.fill-text {
  font-size: 20px;
  color: #3a4197;
  font-weight: 500;
}

.menu-react-heading {
  border-bottom: 1px solid black;
}

.react-dmenu {
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
  letter-spacing: 1px;
  line-height: 1.2;
  padding: 20px 30px;
  border-bottom: 1px solid rgb(0 0 0 / 5%);
  width: 40%;
  position: relative;
  display: block;
}

.fill-text:after {
  position: absolute;
  top: 0;
  left: 0;
  /* color: #3a4197; */
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  -webkit-transition: width 0.75s cubic-bezier(0.63, 0.03, 0.21, 1);
  -o-transition: width 0.75s cubic-bezier(0.63, 0.03, 0.21, 1);
  transition: width 0.75s cubic-bezier(0.63, 0.03, 0.21, 1);
}

.hamenu .menu-links .main-menu > li .link .fill-text {
  position: relative;
  /* color: #999999; */
}

.menu-i-left:before {
  /* opacity: 0; */
  content: "+";
  width: 1px;
  height: 16px;
  position: absolute;
  top: 46%;
  left: 91%;
  background: #1a1a1a;
}

.menu-i-left:after {
  content: "-";
  width: 16px;
  height: 1px;
  position: absolute;
  top: calc(46% + 8px);
  left: calc(90% - 2px);
  background: #1a1a1a;
}

.hamenu .menu-links .main-menu > li .link .fill-text:after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  /* color: #3a4197; */
  width: 100%;
  /* overflow: hidden; */
  white-space: nowrap;
  /* -webkit-transition: width 0.75s cubic-bezier(0.63, 0.03, 0.21, 1); */
  -o-transition: width 0.75s cubic-bezier(0.63, 0.03, 0.21, 1);
  transition: width;
}

.scrolling-text-container {
  display: flex;
  align-items: center;
}

.new-scrolling-text {
  margin-left: 1px;
}

.marquee-hsas-shortcode-92 {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.js-marquee-wrapper {
  cursor: pointer;
  position: absolute;
  white-space: nowrap;
  right: 0;
  animation: marqueeAnimation 32s linear infinite;
}

.js-marquee-wrapper:hover {
  animation-play-state: paused;
}

@keyframes marqueeAnimation {
  from {
    left: 100%;
  }

  to {
    left: -100%;
  }
}

.js-marquee {
  display: inline-block;
  margin-right: 20px;
}

@media only screen and (max-width: 465px) {
  .logo {
    width: 50% !important;
  }
}

@media only screen and (max-width: 768px) {
  .js-marquee {
    padding-top: 5px;
    display: inline-block;
    /* Change display to inline-block */
  }

  .text-right {
    text-align: center;
    font-size: 14px;
  }

  .js-marquee-wrapper {
    position: relative;
    animation: marqueeAnimation 15s linear infinite;
    white-space: nowrap;
  }

  .logo {
    width: 30%;
  }

  .navbar.nav-scroll .logo {
    width: 8rem;
  }

  .banner-wrap {
    height: 70vh;
  }

  .banner-content {
    bottom: 4rem;
  }

  .portfolio-tab .cluom .more {
    margin-left: unset;
  }

  .portfolio-tab .cluom {
    display: flex;
    padding: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;
    flex-direction: row;
    gap: 5px;
    align-items: flex-start;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 1200px) {
  .top-men {
    padding: 5px;
  }

  .icon.vid-wrap {
    top: 16%;
  }
}

.sidenav-link {
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 1.2;
  padding: 20px 30px;
  border-bottom: 1px solid rgb(0 0 0 / 5%);
  width: 100%;
  position: relative;
  display: block;
  -webkit-transform: translateY(100px);
  -ms-transform: translateY(100px);
  transform: translateY(100px);
  -webkit-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}

.sidenav-i:before {
  content: "";
  width: 1px;
  height: 16px;
  position: absolute;
  top: 41%;
  left: 50%;
  background: #1a1a1a;
}

.sidenav-i:after {
  content: "";
  width: 16px;
  height: 1px;
  position: absolute;
  top: calc(40% + 8px);
  left: calc(50% - 8px);
  background: #1a1a1a;
}

.closebtn {
  top: 30px;
  right: 40px;
  width: 60px;
  height: 60px;
  line-height: 60px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  text-align: center;
  background: #fff;
  cursor: pointer;
  z-index: 4;
}

#tsparticles1 {
  position: absolute;
  width: 100%;
  height: 100%;
}

.video-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  background-color: white;
  padding: 20px;
}

.video-container video {
  width: 100%;
  height: 100%;
}

.scroll-text2 {
  margin-left: 20rem;
}

.scroll-text3 {
  margin-left: 20rem;
}

.react-vdo-play {
  width: 800px !important;
  height: 700px !important;
}

.menu-and-submenus {
  padding-top: 90px !important;
  position: absolute;
}

.menu-leftside {
  height: calc(100vh - 90px);
  height: calc(100vh - 90px);
  overflow-y: auto;
}

.menu-hovered-image {
  height: 120vh;
  object-fit: cover;

  right: 0;
  width: 60%;
  height: 100vh;
  opacity: 2;
  z-index: 999999999;
  position: fixed;
}

.leftside-menus {
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
  /* letter-spacing: 1px; */
  line-height: 1.2;
  padding: 20px 0px;
  border-bottom: 1px solid rgb(0 0 0 / 5%);
  /* width: 40%; */
  -webkit-transform: translateY(100px);
  -ms-transform: translateY(100px);
  transform: translateY(100px);
  /* opacity: 0; */
  -webkit-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}

.parallax-container {
  position: relative;
  height: 500px;
}

.parallax-image {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-attachment: fixed;
  z-index: -1;
}

.item-box {
  margin-right: 15px;
  margin-bottom: 15px;
}

.vid-con {
  position: relative;
  /* padding-bottom: 56.25%; */
  overflow: hidden;
  /* height: 300px; */
}

.vid-con iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer !important;
}

.abt-uss {
  margin-top: 10px;
}

@media screen and (min-width: 1440px) {
  .psg-margin {
    margin-left: 45px !important;
    width: 100%;
  }
}

@media screen and (max-width: 1440px) {
  .psg-margin {
    margin-left: 45px !important;
    width: 100%;
  }

  .submenCon {
    width: 100%;
    margin-top: -280px;
  }

  .submenCon1 {
    width: 100%;
    margin-top: -340px;
  }

  .submenCon2 {
    width: 100%;
    margin-top: -340px;
  }

  .item-box {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

.blog-text {
  font-size: 30px;
}

.portfolio-text {
  font-size: 38px;
}

.vidsection-text {
  font-size: 38px;
}

@media screen and (min-width: 1024px) {
  .academic-calendar-responsive {
    font-weight: 300;
  }

  .video-popup {
    margin-top: 30px;
  }
}

@media screen and (max-width: 1024px) {
  .timetable-year {
    background-color: #484e92;
    color: #fff;
  }

  .psg-margin {
    margin-left: 45px;
    width: 100%;
  }

  .side-textss {
    margin-top: -120px !important;
  }

  .gallery-text {
    margin-left: 100px !important;
    margin-right: 96px !important;
    margin-bottom: 23px !important;
    font-size: 18px;
  }

  .item-box {
    margin-right: 5px;
    margin-bottom: 5px;
  }

  .principal-text {
    margin-top: 1px !important;
  }

  .news-texts {
    margin-top: 1px !important;
  }

  .side-txt {
    margin-top: -160px !important;
  }

  .side-mission {
    margin-top: -150px;
  }

  .side-goal {
    margin-top: -140px;
  }
}

.side-txt {
  margin-top: -50px;
}

.pointtxt {
  margin-top: 25px !important;
}

@media screen and (max-width: 768px) {
  .pointxt {
    width: 100% !important;
  }

  .submenCon {
    margin-left: 0 !important;
    margin-top: -76px !important;
    width: 100% !important;
  }

  .item-box {
    margin-right: 3px;
    margin-bottom: 3px;
  }

  .principal-text {
    margin-top: 1px !important;
  }

  .portfolio-text {
    margin-top: 1px !important;
  }
}

@media screen and (min-width: 425px) {
  .sec-info {
    width: 100% !important;
  }

  .go-more-administration {
    position: relative;
    padding: 0 20px;
    margin-top: -100px;
    margin-left: 50px;
  }

  .go-more-administration:after {
    content: "";
    position: absolute;
    right: 5px;
    top: -10px !important;
    width: 43px;
    height: 43px;
    background: rgba(0, 0, 0, 0.05);
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    margin-top: 117px;
  }
}

@media screen and (min-width: 425px) {
  .sec-msg {
    font-size: 12px;
  }

  .go-more-trust {
    position: relative;
    padding: 0 20px;
    margin-top: -90px;
    margin-left: 90px;
  }
}

@media screen and (min-width: 376px) and (max-width: 446px) {
  .site-visits-texts {
    font-size: 12px;
  }
}

@media screen and (max-width: 425px) {
  .expert-txtss {
    font-size: 14px;
  }

  .life-at-campus-contents {
    margin-left: 64px !important;
  }

  .life-at-campus-image {
    height: 280px !important;
    width: 300px !important;
  }

  .life-at-campus-filtering {
    font-size: 18px;
  }

  .connect-with-us a {
    font-size: 18px !important;
  }

  .principal-text {
    font-size: 25px !important;
  }

  .abt-uss {
    margin-top: -120px !important;
  }

  .submenCon {
    width: 100% !important;
  }

  .trustee-msg {
    font-size: 32px !important;
  }

  .trustee-texts {
    font-size: 15px !important;
  }

  .go-more-trust:after {
    content: "";
    position: absolute;
    right: 5px;
    top: -10px;
    width: 43px;
    height: 43px;
    /*  border-radius: 40px;*/
    background: rgba(0, 0, 0, 0.05);
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    margin-top: 115px;
  }

  .footer-subtitle {
    margin-top: -50px !important;
  }

  .news-texts {
    margin-top: 1px !important;
  }

  .portfolio-text {
    margin-top: 1px !important;
  }

  .marquee-scroll-item {
    display: inline-block;
  }

  .marquee-scroll-item {
    margin-right: 180px;
  }
}

.video-content {
  min-width: 0 !important;
  min-height: 0 !important;
}

.close-btn-testimonial {
  position: fixed;
  top: 20px;
  right: 20px;
  font-size: 20px;
  cursor: pointer;
  z-index: 9999;
  color: white;
  font-weight: 400 !important;
}

.video-container-testimonial {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.video-container-testimonial video {
  width: 100%;
  height: 100%;
}

.video-container-testimonial div {
  width: 100%;
  height: 100%;
}

.video-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.video-content-videosection {
  min-width: 0 !important;
  min-height: 0 !important;
}

.close-btn-testimonial {
  position: fixed;
  top: 20px;
  right: 20px;
  font-size: 20px;
  cursor: pointer;
  z-index: 9999;
  color: white;
  font-weight: 400 !important;
}

.video-container-videosection {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  height: 70%;
  width: 50%;
}

.video-container-videosection div {
  width: 100%;
  height: 100%;
}

.video-container-videosection div {
  width: 100%;
  height: 100%;
}

.video-container-videosection iframe {
  width: 100%;
  height: 100%;
}

.video-container-videosection iframe {
  width: 100%;
  height: 100%;
}

.video-popup-videosection {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.close-btn-videosection {
  position: fixed;
  top: 20px;
  right: 20px;
  font-size: 20px;
  cursor: pointer;
  z-index: 9999;
  color: white;
  font-weight: 400 !important;
}

@media screen and (max-width: 425px) {
  .administration-text {
    font-size: 26px !important;
    font-weight: 400 !important;
  }

  .vidsection-text {
    font-size: 18px !important;
  }

  .video-container-videosection {
    width: 100%;
    height: auto;
  }

  .video-container-videosection div {
    width: 100%;
    height: auto;
  }

  .video-container-videosection iframe {
    max-width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 768px) {
  .abt-uss {
    margin-top: -140px !important;
  }

  .go-more-trust:after {
    content: "";
    position: absolute;
    right: 5px;
    top: -10px;
    width: 43px;
    height: 43px;
    background: rgba(0, 0, 0, 0.05);
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    margin-top: 115px;
  }

  .video-container-videosection {
    max-width: 100%;
    width: 100%;
    height: auto;
  }

  .video-container-videosection div {
    max-width: 100%;
    width: auto;
    height: auto;
  }

  .video-container-videosection iframe {
    max-width: 100%;
    height: 20rem;
    width: 80%;
  }

  .video-container-videosection {
    left: 60%;
  }

  .sec-head {
    margin-top: 40px !important;
  }
}

.marquee-container {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.marquee-item {
  display: inline-block;
  padding-right: 70px;
}

.marquee-space {
  display: inline-block;
  width: 50px;
}

.text-videosection {
  margin-top: 20px !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 1.3 !important;
  cursor: pointer !important;
}

.iframe-content {
  width: 300 !important;
  height: 515 !important;
}

.vid-con:hover iframe {
  transform: scale(1.1);
  transition: transform 0.3s ease;
}

.hover-this .hover-anim {
  pointer-events: none;
  -webkit-transition: -webkit-transform 0.2s linear;
  transition: -webkit-transform 0.2s linear;
  -o-transition: transform 0.2s linear;
  transition: transform 0.2s linear;
  transition: transform 0.2s linear, -webkit-transform 0.2s linear;
}

.content .cluom .bg-img {
  position: absolute;
  top: 0;
  left: 0;
  max-width: none;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0;

  transition: 0.6s;
}

.content .cluom:hover .bg-img.current {
  opacity: 1;
}

.text-wrapper-marque {
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  margin-right: 20px;
  position: relative;
}

.marque-scroll-text {
  -webkit-text-stroke-width: 0.1px;
  color: black;
  white-space: nowrap;
  padding: 0 6rem;
  letter-spacing: 1px;
  animation: move-rtl 19000ms linear infinite;
  z-index: 1;
  margin-right: 40px !important;
  cursor: pointer;
}

@keyframes move-rtl {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-300%);
  }
}

.text-wrapper-marque:hover .marque-scroll-text {
  animation-play-state: paused;
}

.main-grid {
  /* padding-top: 160px; */
  padding-bottom: 50px;
}

.container {
  width: 100%;
}

.main-grid .container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  justify-content: center;
  align-items: center;
}

.main-grid .card {
  color: #252a32;
  border-radius: 2px;
  background: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.24);
}

.main-grid .card-image {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 70%;
  background: #ffffff;
}

.main-grid .card-image img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text-center {
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .main-grid .container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }
}

.spinner {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 20px;
  border-radius: 8px;
}

.spinner-content {
  display: flex;
  align-items: center;
}

.spinner-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #646464;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin-right: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.pro-img img {
  width: 300px;
  height: 300px;
}

@media screen and (max-width: 768px) {
  .column {
    padding-top: 40px;
  }

  .hamenu .menu-links .main-menu > li .link {
    font-size: 20px;
  }
}

@media only screen and (max-width: 425px) {
  .video-container-testimonial {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    height: 230px;
    width: 300px;
  }

  .hamenu .menu-links .main-menu > li .link i:before {
    content: "";
    width: 1px;
    height: 16px;
    position: absolute;
    top: 48%;
    left: 208%;
    background: #1a1a1a;
  }

  .hamenu .menu-links .main-menu > li .link i:after {
    content: "";
    width: 16px;
    height: 1px;
    position: absolute;
    top: calc(46% + 8px);
    left: calc(208% - 8px);
    background: #1a1a1a;
  }
}

.about .cont {
  transition: opacity 0.8s ease, transform 0.8s ease;
  opacity: 0;
  transform: translateY(50px);
}

.about .cont.animate {
  opacity: 1;
  transform: translateY(0);
}
@media screen and (min-width: 700px) {
  .about .cont.animate {
    margin-left: 40px;
  }
}

/* .testimonials .left-clumn {
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.3s ease, transform 0.3s ease;
  } */

.testimonials .left-clumn.animated {
  opacity: 1;
  transform: translateY(0);
}

@keyframes slideFromBottom {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.heading-animation {
  animation: slideFromBottom 2s ease forwards;
  opacity: 0;
  transform: translateY(100%);
}

.sub-link:hover {
  color: #a41e35 !important;
}

.link {
  position: relative;
}

.fill-text {
  position: relative;
}

@keyframes slideInFromBottom {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.container {
  animation: slideInFromBottom 1s ease-in-out;
}

.container.slide-in {
  animation: slideInFromBottom 1s ease-in-out;
}

.hovering-effect {
  transition: transform 2s;
}

.hovering-effect:hover {
  cursor: pointer;
  transform: scale (1.2);
}

.gallery-text {
  font-size: 18px;
}

.gallery-navbar {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 425px) {
  .about .cont.animate {
    padding: 5px;
  }

  .image-newsandevents {
    height: 300px !important;
    width: 360px !important;
  }

  .gallery-heading {
    margin-top: 30px !important;
    font-size: 18px !important;
  }

  .hamenu .menu-links .main-menu > li .link .fill-text:after {
    color: #3a4197 !important;
  }

  .text-left {
    text-align: left;
  }

  .dmenu {
    width: 100% !important;
  }

  .sub-bg {
    padding: 0 !important;
  }

  .img-shadow::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 150%;
    background-color: rgba(161, 161, 153, 0.4) !important;
  }

  .text-wrapper-marque {
    margin-right: 0px !important;
  }

  .gallery-text {
    margin-left: 10px !important;
    margin-right: 10px !important;
    margin-bottom: 23px !important;
    font-size: 13px !important;
  }

  .blog-text {
    font-size: 21px !important;
  }

  .portfolio-text {
    font-size: 25px;
    font-size: 25px;
  }

  .vidsection-text {
    font-size: 25px;
    font-size: 25px;
  }
}

@media screen and (max-width: 425px) {
  .go-more-administration {
    margin-top: -450px !important;
    margin-left: 40px !important;
  }

  .go-more-administration::after {
    margin-top: 230px !important;
  }
}
@media screen and (max-width: 520px) {
  .testimonial-text-heading {
    font-size: 17px !important;
  }
}

@media screen and (min-width: 992px) and (max-width: 1300px) {
  .testimonial-text-heading {
    font-size: 20px !important;
  }
}

@media screen and (min-width: 521px) and (max-width: 767px) {
  .testimonial-text-heading {
    font-size: 20px !important;
  }
}

@media screen and (min-width: 320px) {
  .life-at-campus-image {
    height: 240px !important;
    width: 270px !important;
  }

  .life-at-campus-contents {
    margin-left: 19px !important;
    margin-bottom: 34px;
  }
}

@media screen and (max-width: 320px) {
  .des-chair {
    margin-top: -29px;
  }

  .res-italic-txts {
    margin-top: -100px;
  }

  .res-header {
    font-size: 10px;
  }

  .committee-center-para1 {
    margin-top: -54px;
  }

  .res-project-list {
    width: 256px !important;
    height: 240px !important;
  }

  .res-work {
    margin-left: -37px;
  }

  .res-work-des {
    margin-left: -37px !important;
  }

  .syllabuss {
    font-size: 10px !important;
  }

  .read-moree {
    margin-top: -110px !important;
  }

  .close-button {
    margin-left: 258px !important;
    margin-top: 6px;
  }

  .principal-name {
    font-size: 20px;
  }

  .blog-text {
    font-size: 15px !important;
  }

  .portfolio-text {
    font-size: 18px !important;
  }

  .fill-text {
    font-size: 15px !important;
  }

  .menu-wrapper {
    position: relative;
  }

  .menu-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .gallery-text {
    font-size: 11px !important;
  }

  .o-hidden {
    position: relative;
  }

  .menu-image-default {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .img-shadow::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 150%;
    background-color: rgba(161, 161, 153, 0.4) !important;
  }
}

@media screen and (min-width: 577px) and (max-width: 652px) {
  .go-more-administration {
    margin-top: 450px;
    margin-left: 40px;
  }

  .go-more-administration::after {
    margin-top: 230px;
  }
}

@media screen and (max-width: 768px) {
  .go-more-administration {
    margin-top: -450px;
    margin-left: 280px;
  }

  .go-more-administration::after {
    margin-top: 230px;
  }

  .blog-text {
    font-size: 24px;
    font-size: 24px;
  }

  .portfolio-text {
    font-size: 24px;
    font-size: 24px;
  }

  .vidsection-text {
    font-size: 24px;
    font-size: 24px;
  }

  .gallery-text {
    margin-left: 40px !important;
    margin-right: 40px !important;
    margin-bottom: 23px !important;
    font-size: 18px;
  }
}

@media screen and (max-width: 1024px) {
  .abt-uss {
    margin-top: 100px;
  }

  .go-more-trust:after {
    content: "";
    position: absolute;
    right: 5px;
    top: -10px;
    width: 43px;
    height: 43px;
    /*  border-radius: 40px;*/
    background: rgba(0, 0, 0, 0.05);
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    margin-top: 115px;
  }

  .gallery-text {
    margin-left: 50px !important;
    margin-right: 50px !important;
    margin-bottom: 23px !important;
    font-size: 18px;
  }
}

.marquee-scroll-item {
  display: inline-block;
}

.marquee-scroll-item {
  margin-right: 300px;
}

.marquee-scroll:hover {
  animation-play-state: paused !important;
}

.testimonial-text {
  text-align: justify !important;
}
@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .testimonial-text {
    font-size: 15px;
    margin-bottom: 17px;
    text-align: justify !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-text {
    font-size: 14px;
    text-align: justify !important;
  }
}
@media screen and (min-width: 1400px) {
  .testimonial-text {
    font-size: 1.1rem;
    margin-bottom: 17px;
    text-align: justify !important;
  }
}
@media screen and (min-width: 768px) {
  /* 
  .heading-animation {
    font-size: 17px !important;
  } */

  .video-texts {
    font-size: 14px !important;
  }
}

@media screen and(min-width:375px) {
  .go-more-administration {
    position: relative;
    padding: 0 20px;
    margin-top: -200px !important;
    margin-left: 50px;
  }

  .go-more-trust {
    position: relative;
    padding: 0 20px;
    margin-top: -90px !important;
    margin-left: 90px;
  }
}

@media screen and (max-width: 375px) {
  .expert-txtss {
    font-size: 11px;
  }

  .res-italic-txts {
    margin-top: -100px;
  }

  .res-work-des {
    margin-left: -40px !important;
  }

  .life-at-campus-contents {
    margin-left: 29px;
  }

  .trustee-msg {
    font-size: 25px !important;
  }

  .trustee-texts {
    font-size: 12px !important;
  }

  .close-button {
    margin-left: 300px !important;
    margin-top: 6px;
  }

  .image-newsandevents {
    height: 300px !important;
    width: 300px !important;
  }

  .portfolio-text {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 320px) {
  .expert-txtss {
    font-size: 11px;
  }

  .res-prj-list {
    margin-left: 20px !important;
  }

  .trustee-msg {
    font-size: 21px !important;
  }

  .trustee-texts {
    font-size: 10px !important;
  }

  .close-button {
    margin-left: 258px !important;
    margin-top: 6px;
  }

  .image-newsandevents {
    height: 260px !important;
    width: 263px !important;
  }

  .test-button {
    margin-top: 1px;
  }

  /* .dmenu {
      width: 63% !important;
    } */

  .footer-subtitle {
    margin-top: -50px;
  }

  .secretary-image {
    /* height: 0px !important;  */
    width: 251px !important;
    margin-top: 0px;
  }

  /* .num-footer {
      display: block !important;
    } */
}

@media screen and (min-width: 426px) and (max-width: 612px) {
  .dmenu {
    width: 90% !important;
  }
}

@media screen and (min-width: 425px) and (max-width: 768px) {
  .testimonial-image {
    margin-bottom: -10px !important;
  }

  .res-logo {
    width: 155px !important;
  }

  .res-menu {
    font-size: 13px !important;
  }

  .res-menu {
    font-size: 14px !important;
  }

  .logo-socialicon {
    display: flex;
    flex-wrap: nowrap !important;
    align-items: center;
    justify-content: space-between;
  }
}

@media screen and (min-width: 320px) and (max-width: 424px) {
  .services-boxs .item-box h5,
  .services-boxs .item-box p {
    /* Remove the transform property only for this screen size */
    transform: none !important;
  }

  .news-bot-wrap a {
    font-size: 13px;
    margin-left: 5px;
  }

  .res-menu {
    font-size: 13px !important;
  }

  .res-menu1 {
    font-size: 15px !important;
  }

  .banner-text {
    font-size: 25px !important;
  }

  .logo-socialicon {
    display: flex;
    flex-wrap: nowrap !important;
    align-items: center;
    justify-content: space-between;
  }

  .services-boxs .item-box.bg .icon img {
    /* height: 150px; */
    width: 100% !important;
  }
}

img {
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.principal-btn {
  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important;
  top: 40% !important;
  right: 0 !important;
  margin: 0 auto !important;
  width: 65px !important;
  height: 65px !important;
  line-height: 45px !important;
  border-radius: 100% !important;
  text-align: center !important;
  box-sizing: content-box !important;
}

@media screen and (max-width: 991px) {
  .footer-subtitle {
    margin-top: 50px;
  }

  .footer-subtitle1 {
    margin-top: 50px;
  }
}

.text-wrapper-marque .marquee-scroll-item {
  font-weight: 400;
}

.bg-gap {
  margin-bottom: 10px !important;
}

.bg-img.tab-img {
  width: 100%;
  height: 100%;
  background-size: cover;
  /* background-position: center; */
}

.bg-img.tab-img.current {
  display: block;
}

.image-container {
  position: relative;
  display: inline-block;
}

.image-overlay {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 5px;
  margin: 0;
  transition: opacity 0.3s ease;
  opacity: 0;
}

.image-container:hover .image-overlay {
  opacity: 1;
}

.animate-charcter {
  text-transform: uppercase;
  background-image: linear-gradient(225deg, #000000 5%, #1e1e1f 50%);
  background: #0e0f0f;
  background: linear-gradient(270deg, #0e0f0f, #787374);
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 3s linear;
  display: inline-block;
  font-size: 190px;
}

.fill-text:hover {
  background-image: linear-gradient(225deg, #646263 50% #3a4197 50%);
  background: #0e0f0f;
  background: linear-gradient(270deg, #646263, #3a4197);

  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 1s linear;
  display: inline-block;
  font-weight: 550 !important;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.close-button {
  margin-left: 461px;
  margin-top: 5px;
}

@media screen and (max-width: 425px) {
  .res-header {
    font-size: 12px;
  }

  .res-italic-txts {
    margin-top: -100px;
  }

  .res-work-des {
    margin-left: -44px !important;
  }

  .para-psg {
    margin-top: -20px !important;
  }

  .about-us-texts {
    margin-top: 210px !important;
  }

  .close-button {
    margin-left: 363px;
    margin-top: 6px;
  }
}

/* shine effect on hover */
.pro-img {
  width: 100%;

  -webkit-mask-image: linear-gradient(
    45deg,
    #000 25%,
    rgba(0, 0, 0, 0.2) 50%,
    #000 75%
  );
  mask-image: linear-gradient(
    45deg,
    #000 25%,
    rgba(0, 0, 0, 0.2) 50%,
    #000 75%
  );
  -webkit-mask-size: 800%;
  mask-size: 800%;
  -webkit-mask-position: 0;
  mask-position: 0;
}

.pro-img:hover {
  transition: mask-position 2s ease, -webkit-mask-position 2s ease;
  -webkit-mask-position: 120%;
  mask-position: 120%;
  opacity: 1;
}

@media screen and (max-width: 1024px) {
  .code-wrap {
    display: block;
    margin-left: auto !important;
  }

  .static-text {
    white-space: nowrap;
    margin-top: -20px !important;
  }
}

@media screen and (min-width: 992px) and (max-width: 2560px) {
  .para-itech {
    margin-left: -28px !important;
  }
}

@media (max-width: 1440px) {
  .timetable-year {
    background-color: #484e92;
    color: #fff;
  }

  .timetable-years {
    background-color: #484e92;
    color: #fff;
  }

  .code-wrap {
    display: block;
    margin-left: auto !important;
  }

  .static-text {
    white-space: nowrap;
    margin-top: -26px !important;
  }

  .small-text1 {
    margin-left: 130px;
  }
}

.border-r {
  border-right: 1px solid #9d3244;
  height: 30px;
}

@media only screen and (max-width: 1200px) {
  .code-wrap p {
    font-size: 14px !important;
  }
}

@media (max-width: 768px) {
  .para-psg {
    margin-top: -20px !important;
  }

  .about-us-texts {
    margin-top: 110px !important;
  }

  .static-text {
    white-space: nowrap;
    margin-top: -20px !important;
  }

  .code-wrap {
    width: 100%;
    text-align: center;
    margin-left: auto !important;
  }
}

@media screen and (max-width: 767px) {
  .code-wrap {
    margin-top: 15px !important;
  }
}

p.white {
  color: #ffffff !important;
  font-size: 18px;
  line-height: 1.4;
}

@media screen and (max-width: 768px) {
  .main-header .lg-text {
    text-align: left;
    margin: 0;
    margin-bottom: 20px;
  }

  p.white {
    color: #ffffff !important;
    font-size: 12px;
    line-height: 1.4;
  }
  .hamenu .menu-links .main-menu > li .link i {
    right: 20px;
  }
}

@media screen and (max-width: 768px) {
}

@media screen and (min-width: 991px) {
  .footer-text {
    margin-right: 0 !important;
  }
}

@media screen and (max-width: 320px) {
  .lecture-description {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }

  .tab-content {
    margin-top: 40px;
  }

  .services-boxs .item-box.bg {
    height: auto !important;
  }
}

@media screen and (min-width: 426px) and (max-width: 612px) {
  .dmenu {
    width: 100% !important;
  }

  .sidenav .closebtn {
    position: absolute;
    top: 50px !important;
    /* right: 0; */
    margin-left: 0;
    z-index: 999999999999;
    font-size: 60px;
    font-weight: 200 !important;
  }
}

@media screen and (max-width: 768px) {
  .blog-text {
    font-size: 24px;
  }

  .vidsection-text {
    font-size: 24px !important;
  }

  .princ-text {
    margin-top: 0px !important;
  }

  .testimonial-text {
    margin-bottom: 20px !important;
    text-align: justify;
  }
}

@media screen and (max-width: 768px) {
  .team-tab .cluom .img,
  .portfolio-tab .cluom .img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .princ-text {
    margin-top: 0px !important;
  }
}

.portfolio-tab .cluom .img {
  position: absolute;
  top: -25px;
  right: 200px;
  width: 200px;
  height: 300px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

@media screen and (max-width: 768px) {
  .team-tab .cluom .img:after,
  .portfolio-tab .cluom .img:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #1a1a1a;
    opacity: 0.15;
  }

  .tab-content {
    margin-top: 40px;
  }

  li.item-link.mb-15 {
    padding: 8px;
    margin-top: 10px;
  }

  .video-container-testimonial.mt-40 {
    width: 85%;
    height: 17rem;
  }
}

.video-container-testimonial.mt-40 {
  width: auto;
  height: auto;
  margin: 0 auto;
}

.sub-title {
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
  /* color: #3a4197; */
}

.image-container {
  /* height: 400px; */
  overflow: hidden;
}

.cropped-image {
  margin-bottom: -70% !important;
  margin-top: -30% !important;
  width: 100%;
}

.sub-bg {
  background-color: white !important;
  padding: 60px 30px;
}

@media (max-width: 576px) {
  .sub-bg {
    padding: 0;
  }

  li.item-link.mb-15 {
    padding: 8px;
    margin-top: 10px;
  }
}

.rest {
  padding: 0 !important;
  margin: 0 !important;
}

.submenCon {
  margin-left: 280px;
  margin-top: -250px;
}

.pointxt {
  margin-top: 10px;
  margin-left: 10px;
}

.totaltxt {
  margin-top: 10px;
}

.submenCon1 {
  margin-left: 250px;
  margin-top: -298px;
}

.submenCon2 {
  margin-left: 250px;
  margin-top: -299px;
}

.marbg {
  background-color: grey !important;
}

.trusthome {
  margin-top: -120px;
  margin-left: 90px;
  color: white;
  position: relative;
  z-index: 1;
}

.sub-menu {
  cursor: pointer;
}

.admintext {
  margin-top: -150px;
  margin-left: 90px;
  color: white;
  position: relative;
  z-index: 1;
}

.filtertext {
  margin-top: 50px;
}

.card-2 {
  --bg: #f1f1f1;
  --contrast: #f9f9f9;
  --grey: #d9d9d9;
  position: relative;
  padding: 12px;
  background-color: #484e92;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  margin: 0 auto;
}

.card-inner-2 {
  display: flex;
  overflow: hidden;
  width: 100%;
  height: auto;
  background-color: #f9f9f9fa;
  font-size: 20px;
  font-weight: 700;
  color: #555;
  text-align: left;
  justify-content: center;
  padding: 13px;
  flex-direction: column;
  min-height: 195px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.row.md-marg {
  margin-left: -25px;
  margin-right: -25px;
}

.manage-wrap {
  display: flex;
  align-items: center;
}

.bg-clr {
  background-color: #ffffff;
}

button.neumorphic img {
  width: 60px;
  text-align: center;
  margin: 0 auto;
}

button.neumorphic {
  container-type: inline-size;
  aspect-ratio: 1 / 1;
  border: 0.5rem solid transparent;
  border-radius: 1rem;
  color: hsl(0 0% 10%);
  /* background: none; */
  display: grid;
  place-content: center;
  gap: 1rem;
  --shadow: -0.5rem -0.5rem 1rem hsl(0 0% 100% / 0.75),
    0.5rem 0.5rem 1rem hsl(0 0% 50% / 0.5);
  box-shadow: var(--shadow);
  outline: none;
  padding: 0 15px;
  transition: all 0.1s;
}

.buttons {
  display: grid;
  width: 100%;
  margin-inline: auto;
  grid-template-columns: repeat(auto-fit, minmax(min(13rem, 100%), 1fr));
  gap: 2rem;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.neumorphic {
  transition: transform 0.3s ease;
}

.neumorphic:hover {
  transform: scale(1.1);
}

.statutory-text {
  z-index: 1;
  position: relative;
  color: white;
  margin-top: -200px;
  margin-left: 120px;
}

.statutory {
  margin-top: 100px;
}

.factsandfigures {
  z-index: 1;
  color: white;
  margin-top: -170px;
  position: relative;
  margin-left: 130px;
}

.facts-text {
  margin-top: 120px;
}

.postionfix {
  margin-top: -900px;
}

.cardspostionfix {
  margin-top: -2000px !important;
}

.adminfooter {
  margin-top: -2000px !important;
}

.u-line {
  position: relative;
  padding: 10px 20px;
  padding-right: 18px;
}

.u-line {
  border-bottom: 1px solid gray !important;
  width: 200px;
}

.modal {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  z-index: 9999 !important;
}

.modal-content {
  padding: 0px !important;
  border-radius: 5px !important;
  top: 50px !important;
}

.close {
  position: absolute !important;
  top: 10px !important;
  right: 10px !important;
  cursor: pointer !important;
}

.italic {
  font-style: italic;
}

.blog-wrap {
  margin: 0px !important;
  padding: 0px !important;
  height: 100% !important;
  min-height: 100% !important;
  max-height: 100% !important;
}

@media screen and (max-width: 425px) {
  .res-project-list {
    width: 270px;
    height: 240px;
  }

  .statutory {
    margin-top: -30px !important;
  }

  .design-chair-texts {
    height: 50px !important;
    font-size: 26px;
  }

  .design-chair-author {
    margin-top: 150px !important;
  }

  .princi-image {
    width: 100% !important;
    margin-top: 10px !important;
  }

  .submenCon2 {
    margin-left: -50px;
    margin-top: -60px;
  }

  .factsandfigures1 {
    margin-top: -160px;
  }

  .vision {
    margin-top: -199px !important;
  }

  .goal {
    margin-top: -199px !important;
  }

  .mission-img {
    margin-left: -270px !important;
    margin-top: 210px !important;
  }

  .menu-list {
    margin-left: -310px !important;
  }

  .about-us-text {
    margin-top: -190px !important;
  }

  .about-us-text1 {
    margin-top: -160px !important;
  }

  .reset {
    margin-top: -120px;
  }

  .trusthome {
    margin-top: -310px !important;
  }

  .statutorycommittee {
    margin-top: -230px;
  }

  .committee-center-para {
    margin-top: 150px !important;
    margin-left: -200px !important;
  }

  .statutorycommittee1 {
    margin-top: -400px !important;
  }
}

@media screen and (min-width: 426px) and (max-width: 572px) {
  .factsandfigures1 {
    margin-top: -200px;
  }
}

.mission {
  cursor: pointer;
}

.vision {
  cursor: pointer;
}

.goal {
  cursor: pointer;
}

.about-us-text {
  margin-top: 100px;
}

@media screen and (max-width: 768px) {
  .goal {
    margin-top: -90px !important;
  }

  .mission {
    margin-top: -90px;
  }

  .vision {
    margin-top: -90px !important;
  }

  .about-us-text {
    margin-top: -70px;
  }

  .mission-img {
    margin-left: -270px;
    margin-top: 150px;
  }

  .menu-list {
    margin-left: -340px;
  }

  .submenCon2 {
    margin-left: -50px;
    margin-top: -60px;
  }
}

.parallax-container1 {
  position: relative;
  height: 600px;
}

.parallax-image1 {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-attachment: fixed;
  z-index: -1;
}

.parallax-image1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.line {
  margin-top: -19px;
  width: 230px;
}

.side-mission {
  margin-top: -20px;
}

.side-goal {
  margin-top: -10px;
}

.linee {
  margin-top: -19px;
  width: 310px;
}

.mission-img {
  margin-top: 40px;
  margin-left: 40px;
}

.menu-list {
  margin-left: 40px;
}

.reset {
  margin-left: -50px;
}

@media screen and (max-width: 1024px) {
  .timetable-years {
    background-color: #484e92;
    color: #fff;
  }

  .para-psg {
    margin-top: -10px;
  }

  .about-us-texts {
    margin-top: -40px;
  }

  .reset {
    margin-top: -130px;
  }

  .vision {
    margin-top: -100px !important;
  }

  .goal {
    margin-top: -100px !important;
  }

  .gooo {
    margin-top: 120px;
  }
}

@media screen and (min-width: 426px) and (max-width: 767px) {
  .mission-img {
    margin-top: 210px;
    margin-left: -270px;
  }

  .menu-list {
    margin-left: -270px;
  }
}

.card-container {
  background: white;
  cursor: pointer;
  border-radius: 0px;
  position: relative;
  padding: 25px 20px;
  color: #00cec9;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  overflow: hidden;
}

.card-container:hover::after {
  clip-path: circle(100%);
  opacity: 1;
}

.card-container::after {
  content: "";
  background: #484e92;
  border-radius: 0px;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  opacity: 0;
  z-index: 0;
  clip-path: circle(10% at 0% 0%);
  transition: all 0.5s ease-in;
}

.core-faculty-text::after {
  color: #fff !important;
}

.core-card-demo:hover img {
  transform: scale(1.05);
  transition: all 0.5s ease-in-out;
}

.content-3-demo img {
  border: 2px solid gainsboro;
  padding: 15px;
}

.content-3 {
  position: relative;
  z-index: 1;
  transition: all 0.4s ease-in;
}

.card-container:hover h6 {
  color: rgb(255, 255, 255) !important;
  transition: all 0.4s ease-in;
}

.content-3-demo p {
  padding-bottom: 7px;
}

.core-texts {
  margin-top: -3px;
  font-size: large;
}

.core-texts1 {
  text-align: center;
}

.card-4 {
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 0px;
  overflow: hidden;
  margin-bottom: 20px;
  cursor: pointer;
  padding: 20px;
}

.factsandfigures1 {
  margin-top: -100px;
}

.content-3 {
  text-align: left;
}

@media screen and (min-width: 768px) and (max-width: 1024) {
  .core-texts {
    font-size: 10px !important;
  }
}

.principal-text {
  font-size: 38px;
}

.facts {
  width: 60%;
  margin: 0 auto;
}

li.item-link.mb-15.current {
  position: relative;
}

.ytp-cued-thumbnail-overlay-image {
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
}

.paralax-image {
  translate: none;
  rotate: none;
  scale: none;
  transform: translate(0px, -57.5969);
  will-change: transform;
}

.img-scale1 {
  position: relative;
  height: 600px;
}

.img-scale1 .image {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.img-scale1 .vid-circle {
  width: 200px;
  height: 200px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  z-index: 5;
}

.go-moree {
  position: relative;
  padding: 0 20px;
  /* padding-right: 18px;
    margin-right: 840px; */
}

.go-moree img {
  width: 25px;
}

.go-moree:after {
  content: "";
  position: absolute;
  /* right: 5px; */
  top: -10px;
  width: 43px;
  height: 43px;
  /*  border-radius: 40px;*/
  background: rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.go-moree .icon {
  margin-left: 15px;
  bottom: -2px;
  position: relative;
  font-size: 18px;
}

.go-moree:hover:after {
  width: 23%;
  left: 0;
}

.sec-head .go-moree {
  margin-top: 10px;
}

.responsive-iframe {
  width: 100%;
  height: 100%;
}

@media (min-width: 768px) {
  .responsive-iframe {
    width: 560px;
    height: 315px;
  }
}

@keyframes zoom-in-up {
  0% {
    opacity: 0;
    transform: translateY(20px) scale(0.8);
  }

  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

.animate-zoom-in-up {
  animation: zoom-in-up 1s ease-out;
}

@media screen and (min-width: 1441px) {
  .code-wrap {
    display: block;
    margin-left: auto !important;
  }

  .static-text {
    white-space: nowrap;
    margin-top: -20px !important;
  }
}

@media screen and (max-width: 425px) {
  .statutorycommittee {
    margin-top: 80px !important;
  }

  .video-container-testimonial iframe {
    width: 100%;
    height: 100%;
  }
}

.video-content {
  min-width: 0 !important;
  min-height: 0 !important;
}

@keyframes slide-up {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.italic {
  opacity: 0;
  animation: slide-up 3.5s forwards ease-out;
}

.sub-title {
  opacity: 0;
  animation: slide-up 1.5s forwards ease-out;
}

.go-more-trust {
  position: relative;
  padding: 0 20px;
}

.go-more-trust img {
  width: 25px;
}

.go-more-trust:after {
  content: "";
  position: absolute;
  right: 5px;
  top: -10px;
  width: 43px;
  height: 43px;
  background: rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.go-more-trust .icon {
  margin-left: 15px;
  bottom: -2px;
  position: relative;
  font-size: 18px;
}

.go-more-trust:hover:after {
  width: 93%;
  left: 0;
}

.sec-head .go-more-trust {
  margin-top: 10px;
}

.go-more-administration {
  position: relative;
  padding: 0 20px;
}

.go-more-administration img {
  width: 25px;
}

.go-more-administration:after {
  content: "";
  position: absolute;
  right: 5px;
  top: -130px !important;
  width: 43px;
  height: 43px;
  /*  border-radius: 40px;*/
  background: rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.go-more-administration .icon {
  margin-left: 15px;
  bottom: -2px;
  position: relative;
  font-size: 18px;
}

.go-more-administration:hover:after {
  width: 93%;
  left: 0;
}

.sec-head .go-more-administration {
  margin-top: 10px;
}

.item-link .icon {
  position: relative;
  top: 29px !important;
}

.sec-head .go-more-administration {
  margin-top: 10px;
}

.go-more-psg-iap {
  position: relative;

  /* padding-right: 18px;
    margin-right: 840px; */
}

.go-more-psg-iap img {
  width: 25px;
}

.go-more-psg-iap:after {
  content: "";
  position: absolute;
  right: 268px;
  top: 20px;
  width: 43px;
  height: 53px;
  background: rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.go-more-psg-iap:hover:after {
  width: 99%;
  right: 0;
}

.go-more-psg-iap-goal {
  position: relative;
}

.go-more-psg-iap-goal img {
  width: 25px;
}

.go-more-psg-iap-goal:after {
  content: "";
  position: absolute;
  right: 268px;
  top: 2px;
  width: 43px;
  height: 53px;
  /*  border-radius: 40px;*/
  background: rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

.go-more-psg-iap-goal:hover:after {
  width: 99%;
  right: 0;
}

@media screen and (min-width: 992px) and (max-width: 1024px) {
  .side-mission {
    margin-top: -140px;
  }

  .side-goal {
    margin-top: -130px;
  }

  .go-more-psg-iap:after {
    content: "";
    position: absolute;
    left: 5px;
    top: 27px;
    width: 43px;
    height: 53px;
    /*  border-radius: 40px;*/
    background: rgba(0, 0, 0, 0.05);
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
  }

  .go-more-psg-iap-goal:after {
    content: "";
    position: absolute;
    left: 7px;
    top: 115px;
    width: 43px;
    height: 53px;
    background: rgba(0, 0, 0, 0.05);
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
  }

  .go-more-psg-iap:hover:after {
    width: 125% !important;
    right: 0;
  }

  .go-more-psg-iap-goal:hover:after {
    width: 180%;
    right: 0;
  }
}

@media screen and (min-width: 1400px) {
  .go-more-psg-iap-goal:after {
    content: "";
    position: absolute;
    left: 10px;
    top: 1px;
    width: 43px;
    height: 53px;
    background: rgba(0, 0, 0, 0.05);
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
  }

  .go-more-psg-iap:after {
    content: "";
    position: absolute;
    left: 7px;
    top: 18px;
    width: 43px;
    height: 53px;
    /*  border-radius: 40px;*/
    background: rgba(0, 0, 0, 0.05);
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
  }

  .go-more-psg-iap:hover:after {
    width: 80%;
    right: 0;
  }

  .go-more-psg-iap-goal:hover:after {
    width: 80%;
    right: 0;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1199px) {
  .go-more-psg-iap:after {
    content: "";
    position: absolute;
    right: 208px !important;
    top: 20px;
    width: 43px;
    height: 53px;
    /* border-radius: 40px; */
    background: rgba(0, 0, 0, 0.05);
    transition: all 0.4s;
  }

  .go-more-psg-iap-goal:after {
    content: "";
    position: absolute;
    right: 208px !important;
    top: 2px;
    width: 43px;
    height: 53px;
    /* border-radius: 40px; */
    background: rgba(0, 0, 0, 0.05);
    transition: all 0.4s;
  }

  .go-more-psg-iap-goal-line:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 4px;
    background-color: black;
  }

  .go-more-psg-iap-line:before {
    content: "";
    position: absolute;
    left: 0;
    margin-top: 23px;
    height: 70%;
    width: 4px;
    background-color: black;
  }

  .go-more-psg-iap-line1:before {
    content: "";
    position: absolute;
    left: 0;
    margin-top: 23px;
    height: 70%;
    width: 4px;
    background-color: black;
  }

  .go-more-psg-iap:hover:after {
    width: 150%;
    left: 0;
  }

  .go-more-psg-iap-goal:hover:after {
    width: 150%;
    left: 0;
  }
}

@media screen and (min-width: 992px) and (max-width: 1024px) {
  .go-more-psg-iap-goal-line:before {
    content: "";
    position: absolute;
    left: 0;
    margin-top: 1px;

    width: 4px;
    background-color: black;
    height: 0;
    transition: height 0.3s ease;
  }

  .go-more-psg-iap-line:before {
    content: "";
    position: absolute;
    left: 0;
    margin-top: 20px;

    width: 4px;
    background-color: black;
    height: 0;
    transition: height 0.3s ease;
  }

  .go-more-psg-iap-line1:before {
    content: "";
    position: absolute;
    left: 0;
    margin-top: 23px;

    width: 4px;
    background-color: black;
    height: 0;
    transition: height 0.3s ease;
  }

  .go-more-psg-iap-line.current:before {
    height: 72%;
  }

  .go-more-psg-iap-line1.current:before {
    height: 18%;
  }

  .go-more-psg-iap-goal-line.current:before {
    height: 98%;
  }
}

@media screen and (min-width: 769px) and (max-width: 991px) {
  .go-more-administration {
    margin-top: -60px;
    margin-left: 450px;
  }

  .go-more-administration::after {
    margin-top: 240px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1024px) {
  .go-more-administration {
    margin-top: -60px;
    margin-left: 40px;
  }

  .go-more-administration::after {
    margin-top: 230px;
  }
}

@media screen and (min-width: 426px) and (max-width: 767px) {
  .bord {
    flex-wrap: wrap;
  }

  .statutorycommittee1 {
    width: 100%;
    margin-bottom: 10px;
  }

  .committee-center {
    width: 100%;
  }
}

.go-more-psg-iap-goal-line:before {
  content: "";
  position: absolute;
  left: 0;
  margin-top: 1px;

  width: 4px;
  background-color: black;
  height: 0;
  transition: height 0.3s ease;
}

.go-more-psg-iap-line:before {
  content: "";
  position: absolute;
  left: 0;
  margin-top: 20px;

  width: 4px;
  background-color: black;
  height: 0;
  transition: height 0.3s ease;
}

.go-more-psg-iap-line1:before {
  content: "";
  position: absolute;
  left: 0;
  margin-top: 23px;

  width: 4px;
  background-color: black;
  height: 0;
  transition: height 0.3s ease;
}

.go-more-psg-iap-line.current:before {
  height: 72%;
}

.go-more-psg-iap-line1.current:before {
  height: 68%;
}

.go-more-psg-iap-goal-line.current:before {
  height: 98%;
}

.core-fac {
  margin-top: 70px;
}

.visit-fac {
  margin-top: 100px;
}

.allied-fac {
  margin-top: 70px;
}

.readmore-text {
  padding-top: 50px;
  padding-bottom: 50px;
}

.trustee-section {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.trustee-message {
  flex: 1;
  padding-right: 20px;
}

.trustee-image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}

.trustee-image {
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .trustee-section {
    flex-direction: column;
    align-items: flex-start;
  }

  .trustee-message,
  .trustee-image-container {
    width: 100%;
  }

  .trustee-message {
    padding-right: 0;
  }

  .gallery-text {
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-bottom: 23px !important;
    font-size: 18px;
  }
}

@media (max-width: 425px) {
  .main-grid {
    /* padding-top: 60px; */
    padding-bottom: 50px;
  }
}

.trustee-section {
  margin-bottom: 30px;
}

.trustee-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.trustee-image {
  max-width: 100%;
  height: auto;
}

.adminstrative-staff-img-card {
  position: relative;
  cursor: pointer;
}

.card-4 {
  position: relative;
  overflow: hidden;
}

.hover-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: black;
  color: white;
  padding: 10px 20px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.adminstrative-staff-img-card:hover .hover-text {
  opacity: 1;
}

.hamenu .menu-links .main-menu > li .link i:before {
  content: "";
  width: 1px;
  height: 16px;
  position: absolute;
  top: 41%;
  left: 50%;
  background: #1a1a1a;
  transition: transform 0.3s ease;
}

.hamenu .menu-links .main-menu > li .link i:after {
  content: "";
  width: 16px;
  height: 1px;
  position: absolute;
  top: calc(40% + 8px);
  left: calc(50% - 8px);
  background: #1a1a1a;
  transition: transform 0.3s ease;
}

.hamenu .menu-links .main-menu > li.open .link i:before {
  transform: rotate(90deg);
}

.hamenu .menu-links .main-menu > li.open .link i:after {
  transform: rotate(0deg);
}

@media (min-width: 768px) and (max-width: 1000px) {
  .core-card {
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
}

.sec-head h2 {
  font-weight: 400;
}

@media (min-width: 769px) {
  .sec-head h2 {
    font-weight: 400;
    font-size: 38px;
  }
}

.sec-head h2 {
  font-weight: 400;
}

.trustee-texts {
  margin-top: 9px;
  font-size: 17px;
  font-weight: 400 !important;
}

.trustee-name {
  font-size: 34px;
}

.text-zoomin {
  font-size: 25px;
  font-weight: 400;
}

.stu-proj-name {
  font-weight: 400;
  font-size: 25px;
}

.blo-text-na {
  font-weight: 400;
}

.sub-bg2 {
  background: #eee !important;
}

.sub-bg3 {
  background: white !important;
}

.administration-page {
  margin-top: -10px;
}

.administration-text {
  font-size: 36px;
  font-weight: 400;
}

.administration-secretary {
  margin-top: 9px;
  font-size: 20px;
  font-weight: 400;
}

@media screen and (max-width: 333px) {
  .adminstration-breadcrumbs {
    font-size: 15px;
  }

  .sub-bg {
    padding: 0 !important;
  }
}

.sct {
  font-size: 27px;
  font-weight: 400;
}

.committee-text {
  font-size: 27px;
}

@media screen and (max-width: 384px) {
  .core-home {
    font-size: 13px;
  }
}

@media screen and (max-width: 329px) {
  .core-home {
    font-size: 12px;
  }
}

.pointtxt {
  margin-bottom: -28px !important;
}

@media screen and (min-width: 768px) {
  .princi {
    font-weight: 400;
    font-size: 38px !important;
  }
}

.rwd-table th,
.rwd-table td {
  display: table-cell;
  padding: 0.25em 0.5em;
  text-align: center;
}

.rwd-table td:first-child {
  margin-top: 0.5em;
}

.rwd-table tr {
  border-color: #bfbfbf;
}

.rwd-table tr:first-child {
  border-top: none;
  /* background: #484e92; */
  color: #fff;
  color: #000;
}

.rwd-table th,
.rwd-table td {
  padding: 1em !important;
}

.rwd-table th,
.rwd-table td {
  display: table-cell;
  padding: 0.25em 0.5em;
  text-align: center;
}

.border-right {
  border-right: 1px solid #fff;
}

@media screen and(min-width:767px) {
  .rwd-table {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
      0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
      0 16px 16px rgba(0, 0, 0, 0.12);
  }
}

.rwd-table {
  color: #333;
  border-radius: 0.2em;
  overflow: hidden;
}

.rwd-table {
  margin: 0 auto;
  width: 100%;
  border-collapse: collapse;
}

.rwd-table td:last-child {
  margin-bottom: 0.5em;
}

.design-chair-text {
  font-size: 37px;
  font-weight: 400;
}

.syllabus-line {
  position: relative;
  width: 1px;
  padding: 0 5px;
  color: black;
}

.ca-main {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
  /* background-color: #e2e1dc; */
}

.left {
  width: 60%;
  padding: 20px;
}

.calendar {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  color: #878895;
  border-radius: 5px;
  background-color: #fff;
}

.calendar::before,
.calendar::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  width: 12px;
  height: 97%;
  border-radius: 0 5px 5px 0;
  background-color: #d3d4d6d7;
  transform: translateY(-50%);
}

.calendar::before {
  height: 94%;
  left: calc(100% + 12px);
  background-color: rgb(153, 153, 153);
}

.calendar .month {
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
  font-size: 1.2rem;
  font-weight: 500;
  text-transform: capitalize;
}

.calendar .month .prev,
.calendar .month .next {
  cursor: pointer;
}

.calendar .month .prev:hover,
.calendar .month .next:hover {
  color: var(--primary-clr);
}

.calendar .weekdays {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  font-size: 1rem;
  font-weight: 500;
  text-transform: capitalize;
  margin-top: -58px;
}

.weekdays div {
  width: 14.28%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar .days {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 20px;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 20px;
}

.calendar .days .day {
  width: 14.28%;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--primary-clr);
  border: 1px solid #f5f5f5;
}

.calendar .days .day:nth-child(7n + 1) {
  border-left: 2px solid #f5f5f5;
  margin-bottom: 15px;
}

.calendar .days .day:nth-child(7n) {
  border-right: 2px solid #f5f5f5;
  margin-bottom: 15px;
}

.calendar .days .day:nth-child(-n + 7) {
  border-top: 2px solid #f5f5f5;
  margin-bottom: 15px;
}

.calendar .days .day:nth-child(n + 29) {
  border-bottom: 2px solid #f5f5f5;
  margin-bottom: 15px;
}

.calendar .days .day:not(.prev-date, .next-date):hover {
  color: #000000;
  background-color: var(--primary-clr);
}

.calendar .days .prev-date,
.calendar .days .next-date {
  color: #b3b3b3;
}

.calendar .days .active {
  position: relative;
  font-size: 2rem;
  color: #000000;
  background-color: var(--primary-clr);
}

.calendar .days .active::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 10px 2px var(--primary-clr);
}

.calendar .days .today {
  font-size: 2rem;
  color: rgb(45 100 227);
}

.calendar .days .event {
  position: relative;
}

.calendar .days .event::after {
  content: "";
  position: absolute;
  bottom: 10%;
  left: 50%;
  width: 75%;
  height: 6px;
  border-radius: 30px;
  transform: translateX(-50%);
  background-color: var(--primary-clr);
}

.calendar .days .day:hover.event::after {
  background-color: #fff;
}

.calendar .days .active.event::after {
  background-color: #fff;
  bottom: 20%;
}

.calendar .days .active.event {
  padding-bottom: 10px;
}

.calendar .goto-today {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  padding: 0 20px;
  margin-bottom: 20px;
  color: var(--primary-clr);
}

.calendar .goto-today .goto {
  display: flex;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #000;
}

.calendar .goto-today .goto input {
  width: 100%;
  height: 30px;
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 0 20px;
  color: var(--primary-clr);
  border-radius: 5px;
}

.calendar .goto-today button {
  padding: 5px 10px;
  border: 1px solid var(--primary-clr);
  border-radius: 5px;
  background-color: transparent;
  cursor: pointer;
  color: #000;
}

.calendar .goto-today button:hover {
  color: #000000;
  background-color: var(--primary-clr);
}

.calendar .goto-today .goto button {
  border: none;
  border-left: 1px solid var(--primary-clr);
  border-radius: 0;
}

.container .right {
  position: relative;
  width: 40%;
  min-height: 100%;
  padding: 20px 0;
}

.right .today-date {
  width: 100%;
  height: 50px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  /* padding: 0 40px; */
  padding-left: 70px;
  margin-top: 50px;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.right .today-date .event-day {
  font-size: 2rem;
  font-weight: 500;
}

.right .today-date .event-date {
  font-size: 1rem;
  font-weight: 400;
  color: #ffffff;
}

.events {
  width: 100%;
  height: 100%;
  max-height: 600px;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding-left: 4px;
}

.events .event {
  position: relative;
  width: 95%;
  min-height: 70px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  padding: 0 20px;
  padding-left: 50px;
  color: #000000;
  background: linear-gradient(90deg, #fff, transparent);
  /* background: linear-gradient(90deg, #3f4458, transparent); */
  cursor: pointer;
}

.events .event:nth-child(even) {
  background: transparent;
}

.events .event .title {
  display: flex;
  align-items: center;
  pointer-events: none;
}

.events .event .title .event-title {
  font-size: 1.2rem;
  font-weight: 400;
  margin-left: 20px;
}

.events .event i {
  color: var(--primary-clr);
  font-size: 0.5rem;
}

.events .event .event-time {
  font-size: 0.8rem;
  font-weight: 400;
  color: #878895;
  margin-left: 15px;
  pointer-events: none;
}

.events .event::after {
  content: "✓";
  position: absolute;
  top: 50%;
  right: 0;
  font-size: 3rem;
  line-height: 1;
  display: none;
  align-items: center;
  justify-content: center;
  opacity: 0.3;
  color: var(--primary-clr);
  transform: translateY(-50%);
}

.add-event {
  position: absolute;
  bottom: 30px;
  right: 30px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: #878895;
  border: 2px solid #878895;
  opacity: 0.5;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
}

.add-event:hover {
  opacity: 1;
}

.add-event i {
  pointer-events: none;
}

.events .no-event {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 500;
  color: #ffffff;
}

.add-event-wrapper {
  position: absolute;
  bottom: 100px;
  left: 50%;
  width: 90%;
  max-height: 0;
  overflow: hidden;
  border-radius: 5px;
  background-color: #fff;
  transform: translateX(-50%);
  transition: max-height 0.5s ease;
}

.add-event-wrapper.active {
  max-height: 300px;
}

.add-event-header {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  color: #373c4f;
  border-bottom: 1px solid #f5f5f5;
}

.add-event-header .close {
  font-size: 1.5rem;
  cursor: pointer;
}

.add-event-header .close:hover {
  color: var(--primary-clr);
}

.add-event-header .title {
  font-size: 1.2rem;
  font-weight: 500;
}

.add-event-body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 20px;
}

.add-event-body .add-event-input {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.add-event-body .add-event-input input {
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  border-bottom: 1px solid #f5f5f5;
  padding: 0 10px;
  font-size: 1rem;
  font-weight: 400;
  color: #373c4f;
}

.add-event-body .add-event-input input::placeholder {
  color: #a5a5a5;
}

.add-event-body .add-event-input input:focus {
  border-bottom: 1px solid var(--primary-clr);
}

.add-event-body .add-event-input input:focus::placeholder {
  color: var(--primary-clr);
}

.add-event-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.add-event-footer .add-event-btn {
  height: 40px;
  font-size: 1rem;
  font-weight: 500;
  outline: none;
  border: none;
  color: #fff;
  background-color: var(--primary-clr);
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid var(--primary-clr);
}

.add-event-footer .add-event-btn:hover {
  background-color: transparent;
  color: var(--primary-clr);
}

/* media queries */

@media screen and (max-width: 1000px) {
  body {
    align-items: flex-start;
    justify-content: flex-start;
  }

  .calendar::before,
  .calendar::after {
    top: 100%;
    left: 50%;
    width: 97%;
    height: 12px;
    border-radius: 0 0 5px 5px;
    transform: translateX(-50%);
  }

  .calendar::before {
    width: 94%;
    top: calc(100% + 12px);
  }

  .events {
    padding-bottom: 340px;
  }

  .add-event-wrapper {
    bottom: 100px;
  }
}

@media screen and (max-width: 500px) {
  .calendar .weekdays {
    height: 50px;
    padding: 0 !important;
  }

  .left {
    padding: 0 !important;
  }

  .calendar .days {
    padding: 0 !important;
  }

  .calendar .days .day {
    height: 40px;
    font-size: 0.8rem;
  }

  .calendar .days .day.active,
  .calendar .days .day.today {
    font-size: 1rem;
  }

  .right .today-date {
    padding: 20px;
  }
}

.credits {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 10px;
  font-size: 12px;
  color: #fff;
  background-color: #b38add;
}

.credits a {
  text-decoration: none;
  font-weight: 600;
}

.credits a:hover {
  text-decoration: underline;
}

.events .no-event {
  width: 100%;
  height: 50%;
}

.event-day {
  color: #fff;
}

.academic-bg {
  background: url(../imgs/background/calendar-bg.webp) no-repeat center;
  width: 100%;
  height: auto;
  background-size: cover;
  position: relative;
  z-index: 2;
}

.academic-bg:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: #00000045;
  top: 0;
  z-index: -1;
}

i.fa-light.fa-up-right-from-square {
  font-family: "FontAwesome";
  padding-left: 2px;
}

.border-gray {
  border-right: 1px solid #8a8b8d;
}

.description {
  position: relative;
  z-index: 3;
}

.allied-faculty-wrap {
  padding-top: 50px;
}

.hov-line {
  display: inline-block;
  color: #000;
  text-decoration: none;
}

.hov-line::after {
  content: "";
  display: block;
  width: 0;
  height: 0.5px;
  background: #363030a4;
  transition: width 0.3s;
}

.hov-line:hover::after {
  width: 100%;
}

.accordion .title {
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0px;
  cursor: pointer;
  position: relative;
  margin-bottom: 10px;
  width: 530px;
  height: 59;
}

.accordion .title .icoo {
  position: absolute;
  bottom: 24px;
  right: 26px;
}

.table-wrapper {
  overflow-x: auto;
}

.responsive-table {
  width: 100%;
  border-collapse: collapse;
  margin: 0 auto;
  border: 1px solid #ddd;
}

.responsive-table th,
.responsive-table td {
  padding: 12px;
  text-align: left;
  border: 1px solid #ddd;
}

.responsive-table th {
  background-color: #f4f4f4;
}

.responsive-table td a {
  color: #007bff;
  text-decoration: none;
}

.responsive-table td a:hover {
  text-decoration: underline;
}

.email-container-design-chair {
  display: flex;
  flex-wrap: wrap;
}

.email-link-design-chair {
  display: inline-block;
  margin-right: 5px;
}

.heading-animation {
  animation: slideIn 1s ease-in-out forwards;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(100px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.sub-menu .sub-link {
  color: #999999;
  text-decoration: none;
}

.sub-menu .sub-link.active,
.sub-menu .sub-link:hover {
  color: #3a4197;
}

.content-3 .hover-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 10px 20px;

  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 10;
  pointer-events: none;
}

.content-3:hover .hover-text {
  opacity: 1;
}

/* Default color for menu items */
.hamenu .menu-links .main-menu > li .link .fill-text {
  color: #999999 !important;
}

/* Color when a menu item is hovered */
.hamenu .menu-links .main-menu > li:hover .link .fill-text {
  color: #3a4197 !important;
}

/* Color when a menu item is clicked */
.hamenu .menu-links .main-menu > li.open .link .fill-text {
  color: #3a4197 !important;
}

/* All menu items in the sidenav should be the default color when the sidenav is opened */
.sidenav.open .main-menu .link .fill-text {
  color: #3a4197 !important;
}

.core-card {
  position: relative;
}

.card-container {
  cursor: pointer;
}

.description-wrapper {
  margin-top: 20px;
  margin-bottom: 60px;
}

.card-description-4 {
  display: none;
  margin-top: 20px;
}

.card-description-4.visible {
  display: block;
}

.staff-description {
  padding: 10px;
  text-align: justify;
}

.programs-img {
  width: 500px;
  height: 400px;
  margin-top: -20px;
  margin-left: -20px;
}

.programs-texts {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 400;
}

.accorion-infoo {
  border-left: 40px solid #fff !important;
  border-radius: 0 !important;

  padding: 30px;
  background: rgba(255, 255, 255, 0.02);
  border-width: 15px;
  border-right: -50px !important;
}

.icoo {
  margin-bottom: -19px !important;
  font-weight: 200;
  font-size: 2em;
}

.breadcrumbClass {
  position: relative;
  z-index: 20;
}

.content-3 h6 {
  padding-top: 20px;
}

.expert-name {
  padding-top: 20px;
}

.administrative-staffss {
  height: 33px;
}

.design-chair-texts {
  height: 49px;
}

.design-chair-content {
  height: 486px;
  text-align: justify;
}

/* .design-chair-author {
  } */
.design-chair-quote {
  height: 65px;
}

.principal-animated-text {
  height: 65px;
}

.member-name {
  font-weight: 700;
  font-size: 17px;
}

.princi-image {
  width: 533px;
  /* margin-top: 100px; */
}

.princi-animated-text {
  font-size: 25px;
  font-weight: 400;
  margin-top: -90px;
}

.core-faculty-text {
  text-transform: uppercase;
}

.content-3 {
  position: relative;
  overflow: hidden;
}

.content-3 .hover-text {
  position: absolute;
  transform: translate(-50%, -50%);
  background-color: #000;
  color: #fff;
  padding: 10px 20px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 10;
  pointer-events: none;
}

.content-3:hover .hover-text {
  opacity: 1;
}

.visiting-fclty {
  text-transform: uppercase;
}

.visiting-faculty-texts {
  margin-top: -100px;
}

.adminstrative-staffs {
  text-transform: capitalize;
}

.neumorphic {
  height: 255px;
  width: 255px;
}

.timetable-sem {
  display: flex;
}

.menu-item {
  transition: transform 0.1s ease-in-out;
}

.menu-item:hover {
  transform: scale(1.1);
}

.secretary-message-paragraph {
  margin: 5px !important;
}

@keyframes zoomIn {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.academic-timetable-row:hover {
  background-color: #e0ffff;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInUp {
  animation: fadeInUp 0.5s cubic-bezier(0.3, 0, 0.7, 1) both;
}

.today,
.selected-day {
  font-size: 1.5em;
  color: #000;
  font-weight: 400;
}

.news-title {
  display: inline-block;
  width: 100%;
  word-wrap: break-word;
  white-space: normal;
}

@media screen and (min-width: 1440px) {
  .services-boxs .item-box.bg {
    background: #ffffff;
    height: 400px !important;
    overflow: hidden;
    width: 300px;
    border: 1px solid #ddd;

    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease, transform 0.3s ease;
  }

  .services-boxs .item-box.bg .icon img {
    /* height: 150px; */
    object-fit: cover;
    transition: all 1s ease;
    width: 250px;
  }
}

.items.d-rotate.animated {
  transform: translateY(30px) scale(0.9);
  opacity: 0;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.items.d-rotate.animated.news-animation {
  transform: translateY(0) scale(1);
  opacity: 1;
}

.card-container.selected {
  background-color: #484e92;
  color: white;
}

.card-container.selected h6 {
  color: white !important;
}

.card-description-4.hidden {
  display: none;
}

.card-description-4.visible {
  text-align: justify;
  display: block;
}

@media screen and (max-width: 320px) {
  .contact-numbers {
    display: flex;
    font-size: 12px;
    color: #ddd;
  }

  .contact-numbers a {
    white-space: nowrap;
  }

  .contact-numbers a span.num-footer {
    display: inline;
  }
}

@media screen and (max-width: 768px) {
  .monthly-lecture-para {
    margin-left: 16px;
    margin-right: 40px;
  }
}

.adminstrative-staffs {
  text-transform: uppercase;
}

/* .core-card img {
    width: 100%;
    height: 250px;
  } */

.expert-name,
.adminstrative-staffs {
  margin: 0;
  font-size: 16px;
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 425px) {
  .montly-lecture-text {
    font-size: 13px;
  }
}

@media screen and (max-width: 375px) {
  .lecture-description {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }

  .res-prj-list {
    margin-left: 30px !important;
  }

  .monthly-lecture-img {
    height: 211px !important;
    margin-left: 22px;
    width: 299px !important;
  }

  .montly-lecture-text {
    font-size: 12px;
  }
}

@media screen and (min-width: 320px) and (max-width: 357px) {
  .site-visits-texts {
    font-size: 10px !important;
  }
}

@media screen and (max-width: 320px) {
  .monthly-lecture-img {
    height: 250px !important;
    margin-left: 25px;
    width: 250px !important;
  }

  .montly-lecture-text {
    font-size: 10px;
  }
}

@media screen and (min-width: 321px) and (max-width: 502px) {
  .monthly-lecture-img {
    height: 250px !important;
    margin-left: 25px;
    width: 250px !important;
  }
}

@media screen and (min-width: 503px) and (max-width: 766px) {
  .monthly-lecture-img {
    height: 250px !important;
    margin-left: 22px;
    width: 405px !important;
  }
}

@media screen and (min-width: 767px) and (max-width: 1400px) {
  .monthly-lecture-img {
    height: 250px !important;
    margin-left: 0px;
    width: 200px !important;
  }
}

.contact .info-box {
  padding: 40px 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

.Components-title {
  padding: 18px 0;
  font-weight: 400;
  font-size: 18px;
}

.item .img img {
  height: 15rem;
  width: 100%;
}

.life-at-campus .items {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transform: scale(0.001);
  transition-property: opacity, transform;
  transition-duration: 0.6s;
  transition-timing-function: ease-in-out;
}

.life-at-campus .items.active {
  position: relative;
  opacity: 1;
  transform: scale(1);
}

.workwithus-container .items {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transform: scale(0.001);
  transition-property: opacity, transform;
  transition-duration: 0.6s;
  transition-timing-function: ease-in-out;
}

.workwithus-container .items.active {
  position: relative;
  opacity: 1;
  transform: scale(1);
}

.gallery {
  position: relative;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .go-more-psg-iap-line1.current:before {
    height: 105%;
  }

  .go-more-psg-iap-line1:before {
    content: "";
    position: absolute;
    left: 0;
    margin-top: -7px;
    width: 4px;
    background-color: black;
    height: 0;
    transition: height 0.3s ease;
  }

  .go-more-psg-iap:after {
    content: "";
    position: absolute;
    left: 0px !important;
    top: 0px;
    width: 43px;
    height: 51px;
    /* border-radius: 40px; */
    background: rgba(0, 0, 0, 0.05);
    transition: all 0.4s;
  }
}

@media screen and (max-width: 425px) {
  .life-at-campus .items.active {
    text-align: center !important;
  }

  .lecture-description {
    margin-left: 12px !important;
    margin-right: 12px !important;
  }

  .res-prj-list {
    margin-left: 65px;
  }

  .serv-tab-link {
    margin-top: -50px;
  }

  .iqac-texts {
    font-weight: 400 !important;
    font-size: 23px !important;
  }
}

@media screen and (max-width: 768px) {
  .serv-tab-link {
    margin-top: -50px;
  }

  .iqac-texts {
    font-weight: 400 !important;
    font-size: 19px !important;
  }
}

.iqac-icon {
  margin-top: -10px;
}

@media screen and (min-width: 769px) and (max-width: 991px) {
  .iqac-right {
    margin-top: 50px;
  }

  .go-more-psg-iap.current:after {
    width: 102%;
    right: 0;
    height: 57px;
    top: -10px;
  }

  .go-more-psg-iap:after {
    left: 0px;
    top: 0px;
    width: 43px;
    height: 33px;
  }

  .go-more-psg-iap {
    margin-top: 50px;
  }

  .go-more-psg-iap-line1.current:before {
    height: 198%;
    top: -30px;
    left: -7px;
  }

  .serv-tab-link {
    margin-top: -50px;
  }

  .iqac-texts {
    font-size: 23px !important;
    font-weight: 400 !important;
  }
}

.psgiap-icon {
  margin-top: 5px !important;
}

@media screen and (max-width: 1024px) {
}

@media screen and (max-width: 1024px) {
  .tabss-psg {
    margin-top: 0px !important;
  }
}

@media screen and (max-width: 1199px) {
  .connect-with-us a {
    font-size: 18px;
  }
}

@media (min-width: 320px) and (max-width: 479px) {
  .section-padding {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .section-padding {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .section-padding {
    padding-top: 70px;
    padding-bottom: 100px;
  }

  .services-boxs .item-box.bg {
    background: #ffffff;
    height: 500px;
    overflow: hidden;
    width: 320px;
  }

  .services-boxs .item-box.bg .icon img {
    /* height: 150px; */
    object-fit: cover;
    transition: all 1s ease;
    width: 285px;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .section-padding {
    padding-top: 80px;
    padding-bottom: 100px;
  }
}

@media (min-width: 1200px) and (max-width: 1439px) {
  .sec-pad-vid {
    padding-bottom: 60px !important;
  }

  .section-padding {
    padding-top: 80px;
    padding-bottom: 100px;
  }
}

@media (min-width: 1440px) {
  .section-padding {
    padding-top: 55px;
    padding-bottom: 80px;
  }
}

@keyframes fadeInUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.fadeInUp {
  visibility: visible;
  animation-name: fadeInUp;
  animation-duration: 3s;
  animation-fill-mode: both;
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  .accordion .title {
    width: 230px !important;
  }
}

@media screen and (max-width: 320px) {
  .programs-texts {
    font-size: 10px !important;
  }

  .accordion .title .icoo {
    position: absolute;
    bottom: 28px !important;
    right: 12px !important;
    font-size: 25px !important;
  }
}

@media screen and (max-width: 540px) {
  .accordion .title {
    width: 350px;
  }

  .programs-texts {
    font-size: 14px;
  }

  .program-description-prg {
    padding-left: 0;
  }

  .prgrms-tabs {
    padding: 0 !important;
    margin-top: -52px !important;
  }
}

@media screen and (max-width: 320px) {
  .vid-txtes {
    font-size: 26px !important;
    font-weight: 400;
  }

  .accordion .title {
    width: 246px !important;
  }

  .programs-texts {
    font-size: 10px;
  }
}

@media screen and (max-width: 375px) {
  .accordion .title {
    width: 250px !important;
  }

  .programs-texts {
    font-size: 10px !important;
  }

  .accordion .title .icoo {
    right: 5px;
    bottom: 29px;
  }

  .icoo {
    font-size: 1.5em;
  }
}

@media screen and (max-width: 425px) {
  .vid-txtes {
    font-size: 26px !important;
    font-weight: 400;
  }

  .accordion .title {
    width: 265px;
  }

  .programs-texts {
    font-size: 10px;
  }

  .accordion .title .icoo {
    bottom: 29px;
  }

  .icoo {
    font-size: 1.5em;
  }
}

@media screen and (max-width: 375px) {
  .site-visits-texts {
    font-size: 12px;
  }

  .programs-texts {
    font-size: 11px;
  }

  .accordion .title .icoo {
    position: absolute;
    bottom: 29px;
    right: 12px;
    font-size: 25px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .accorion-infoo {
    /* border-left: 97px !important; */
    border-radius: 0 !important;
    padding: 30px;
    background: rgba(255, 255, 255, 0.02);
    /* border-width: 15px; */
    border-right: -50px !important;
    margin-left: 15px !important;
  }

  .accordion .title {
    margin-left: 32px;
    width: 444px;
  }

  .bord-thin-lefts {
    border-left: 2px solid #e3e2e2 !important;
    padding-left: 10px !important;
    margin-left: 89px !important;
  }

  .accordion .title .icoo {
    position: absolute;
    bottom: 28px;
    right: 48px;
  }

  .programs-texts {
    font-size: 16px;
  }

  .programs-img {
    width: 452px;
    height: 452px;
    margin-left: -20px;
  }
}

@media screen and (min-width: 541px) and (max-width: 645px) {
  .accordion .title {
    width: 350px;
  }

  .programs-texts {
    font-size: 14px;
  }

  .program-description-prg {
    padding-left: 0;
  }

  .program-heading-prg {
    padding-left: 0;
  }

  .prgrms-tabs {
    padding: 0 !important;
    margin-top: -52px !important;
  }
}

.calendar {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.month {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px;
  background-color: #f4f4f4;
}

.date {
  font-size: 0.8em;
  font-weight: bold;
  line-height: 1;
}

.weekdays {
  display: flex;
  justify-content: space-around;
  padding: 2px;
  background-color: #f4f4f4;
}

.weekdays div {
  font-size: 0.7em;
  font-weight: bold;
  line-height: 1;
}

.days {
  display: flex;
  flex-wrap: wrap;
  padding: 2px;
}

.day {
  width: calc(100% / 7 - 2px);
  height: 20px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1px 0;
  cursor: pointer;
  font-size: 0.8em;
  line-height: 1;
}

.day.today {
  color: #333;
  border-radius: 4px;
}

.day.event {
  background-color: #03a9f4;
  color: #fff;
  border-radius: 4px;
}

.goto-today {
  display: flex;
  justify-content: space-between;
  padding: 2px;
}

.goto input {
  width: 70px;
  padding: 2px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.8em;
}

.goto button,
.today-btn {
  padding: 2px 5px;
  background-color: #03a9f4;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.8em;
}

.add-event-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  display: none;
}

.add-event-wrapper.active {
  display: block;
}

.add-event-wrapper .close {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.add-event-wrapper input,
.add-event-wrapper button {
  display: block;
  width: 100%;
  margin-bottom: 5px;
  padding: 5px;
  /* Minimum padding */
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 0.8em;
  /* Smaller font size */
}

.add-event-wrapper button.save {
  background-color: #03a9f4;
  color: #fff;
  border: none;
  cursor: pointer;
}

.calendar::before,
.calendar::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  width: 12px;
  height: 97%;
  border-radius: 0 5px 5px 0;
  background-color: #d3d4d6d7 !important;
  transform: translateY(-50%);
}

.fac-icon-calender {
  font-size: 25px;
  margin: 15px;
}

@media screen and (min-width: 376px) and (max-width: 401px) {
  .accordion .title {
    width: 312px;
  }

  .accordion .title .icoo {
    position: absolute;
    bottom: 27px;
    right: 7px;
  }
}

.lecture-description {
  padding-right: 0 !important;
  line-height: 1.8;
  text-align: justify;
}

.bord-thin-lefts {
  border-left: 2px solid #e3e2e2 !important;
  padding-left: 10px !important;
  margin-left: 40px !important;
}

.ca-flex {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.ca-flex .left,
.ca-flex .right {
  flex: 1;
}

@media (max-width: 768px) {
  .vid-txtes {
    font-size: 26px !important;
    font-weight: 400;
  }

  .ca-flex {
    flex-direction: column;
  }

  .ca-flex .left,
  .ca-flex .right {
    width: 100%;
    margin: 0;
  }

  .ca-flex .left {
    margin-bottom: 20px;
  }

  .calendar .month .date {
    font-size: 15px;
  }

  .events .event-title {
    font-size: 1em;
  }

  .events .event-time {
    font-size: 0.9em;
  }
}

.calendar .month {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.calendar .weekdays,
.calendar .days {
  display: flex;
  flex-wrap: wrap;
}

.calendar .weekdays div,
.calendar .days div {
  width: 14.28%;
  text-align: center;
  padding: 10px 0;
}

.calendar .days .day {
  cursor: pointer;
  padding: 10px 0;
  position: relative;
}

.calendar .days .day.event::after {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.events .event .title {
  display: flex;
  align-items: center;
  margin-right: 10px;
  /* Base styles */
}

.academic-timetable {
  font-weight: bold;
}

.pdf-links-cell a {
  display: block;
}

/* Responsive table */
@media screen and (max-width: 768px) {
  .timetable-res {
    width: 100%;
    border-collapse: collapse;
  }

  .timetable-res th,
  .timetable-res td {
    padding: 10px;
    display: block;
    text-align: left;
  }

  .timetable-res th {
    background: #f2f2f2;
  }

  .timetable-res td {
    border: none;
    border-bottom: 1px solid #ddd;
  }

  .timetable-res td:before {
    content: attr(data-th);
    font-weight: bold;
    text-transform: uppercase;
    display: inline-block;
    width: 100%;
    margin-bottom: 5px;
  }

  .timetable-res td:hover {
    background-color: #deeff5;
  }

  .timetable-res td:active,
  .timetable-res td:focus {
    background-color: #deeff5;
  }

  .timetable-res td[data-th="3'rd Year"],
  .timetable-res td[data-th="4'th Year"],
  .timetable-res td[data-th="Final Year"] {
    background-color: #fff !important;
  }

  /* On hover */
  .timetable-res td[data-th="3'rd Year"]:hover,
  .timetable-res td[data-th="4'th Year"]:hover,
  .timetable-res td[data-th="Final Year"]:hover {
    background-color: #deeff5 !important;
  }
}

@media screen and (max-width: 768px) {
  .timetable-res thead {
    display: none;
  }

  .timetable-res tr {
    margin-bottom: 10px;
    display: block;
    border-bottom: 2px solid #ddd;
  }

  .timetable-res td {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .timetable-res td[data-th="Year"],
  .timetable-res td[data-th="1'st year"],
  .timetable-res td[data-th="2'nd year"],
  .timetable-res td[data-th="3'rd Year"],
  .timetable-res td[data-th="4'th Year"],
  .timetable-res td[data-th="Final Year"] {
    background-color: #f7f7f7;
    font-weight: bold;
  }
}

@media screen and (max-width: 768px) {
  .page-header-cerv {
    padding: 50px 0;
  }

  .core-home .container {
    padding-top: 50px;
  }
}

@media screen and (max-width: 768px) {
  .academic-calendar-responsive {
    background-color: #484e92 !important;
    color: white;
  }

  .acd-yr {
    flex-direction: column;
  }

  .academic-calendar-responsive {
    display: inline-block;
    white-space: nowrap;
  }

  .res-sem {
    font-weight: 300;
  }

  .res-cells {
    background-color: #fff;
  }

  .separator {
    margin-top: 10px;
    margin-right: 10px;
  }

  .academic-timetable {
    margin-bottom: 20px !important;
  }
}

.sylb {
  background-color: #484e92;
  color: #fff !important;
}

.calendar .days .active {
  position: relative;
  font-size: 2rem;
  color: #000000;
  background-color: var(--primary-clr);
}

@media (min-width: 991px) and (max-width: 1024px) {
  .testimonial-global .ti-arrow-circle-left {
    margin-top: 10px;
  }

  .testimonial-global .ti-arrow-circle-right {
    margin-top: 10px;
  }
}

@media screen and (min-width: 426px) and (max-width: 767px) {
  .res-prj-list {
    margin-left: 45px;
  }

  .res-project-list {
    width: 320px;
    height: 240px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .res-project-list {
    width: 240px;
    height: 240px;
  }
}

.search-form {
  position: relative;
  margin-left: auto;
}

.search-form.open .search-icon .pe-7s-close {
  display: block;
}

.search-form.open .search-icon .pe-7s-search {
  display: none;
}

.form-group {
  display: flex;
  align-items: center;
}

.search-input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 5px;
}

.search-button {
  background: transparent;
  border: none;
  cursor: pointer;
}

.pe-7s-search,
.pe-7s-close {
  font-size: 24px;
}

.search-dropdown {
  position: absolute;
  top: 70px;
  right: 55px;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  width: 235px;
  z-index: 1000;
}

.search-results {
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow-y: scroll;
  max-height: 200px;
}

.search-item {
  padding: 5px;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
}

.search-item:hover {
  background-color: #f0f0f0;
}

.blog-fin {
  margin-top: -135px;
}

@media (min-width: 992px) and (max-width: 1024px) {
  .iaq-padding {
    margin-top: 160px !important;
  }
}

.go-more-psg-iap.current:after {
  width: 100% !important;
  right: 3px;
}

@media screen and (min-width: 993px) and (max-width: 1024px) {
  .go-more-psg-iap.current:after {
    width: 123% !important;
    right: 3px;
    margin-top: -6px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1199px) {
  .go-more-psg-iap.current:after {
    width: 122% !important;
    right: -60px !important;
  }
}

@media screen and (min-width: 1400px) {
  .go-more-psg-iap.current:after {
    width: 83% !important;
    right: -60px !important;
  }
}

@media screen and (min-width: 1400px) {
  .cards-space-res {
    gap: 4rem !important;
  }
}

.loader-container-testimonial {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.loader-testimonial {
  width: 50px;
  height: 50px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #3a4197;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-image {
    margin-left: 89px;
  }
}

@media screen and (min-width: 426px) and (max-width: 450px) {
  .services-boxs .item-box.bg .icon img {
    height: 150px;
    object-fit: cover;
    transition: all 1s ease;
    width: 21rem;
  }

  .res-text {
    width: 380px !important;
  }
}

@media screen and (min-width: 451px) and (max-width: 475px) {
  .services-boxs .item-box.bg .icon img {
    height: 150px;
    object-fit: cover;
    transition: all 1s ease;
    width: 23rem;
  }

  .res-text {
    width: 410px !important;
  }
}

@media screen and (min-width: 476px) and (max-width: 510px) {
  .services-boxs .item-box.bg .icon img {
    /* height: 150px; */
    object-fit: cover;
    transition: all 1s ease;
    width: 24rem;
  }

  .res-text {
    width: 420px !important;
  }
}

@media screen and (min-width: 511px) and (max-width: 610px) {
  .services-boxs .item-box.bg .icon img {
    /* height: 150px; */
    object-fit: cover;
    transition: all 1s ease;
    width: 26rem;
  }

  .res-text {
    width: 460px !important;
  }
}

@media screen and (min-width: 611px) and (max-width: 675px) {
  .services-boxs .item-box.bg .icon img {
    /* height: 150px; */
    object-fit: cover;
    transition: all 1s ease;
    width: 27rem;
  }

  .res-text {
    width: 480px !important;
  }
}

@media screen and (min-width: 676px) and (max-width: 700px) {
  .services-boxs .item-box.bg .icon img {
    height: 150px;
    object-fit: cover;
    transition: all 1s ease;
    width: 28rem;
  }

  .res-text {
    width: 490px !important;
  }
}

@media screen and (min-width: 701px) and (max-width: 767px) {
  .services-boxs .item-box.bg .icon img {
    /* height: 150px; */
    object-fit: cover;
    transition: all 1s ease;
    width: 29rem;
  }

  .res-text {
    width: 500px !important;
  }
}

@media screen and (min-width: 992px) and (max-width: 1024px) {
  .services-boxs .item-box.bg .icon img {
    height: 150px;
    object-fit: cover;
    transition: all 1s ease;
    width: 12rem;
  }

  .res-text {
    width: 225px !important;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .services-boxs .item-box.bg .icon img {
    /* height: 150px; */
    object-fit: cover;
    transition: all 1s ease;
    width: 12rem;
  }

  .res-text {
    width: 225px !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 515px) {
  .social-icons {
    justify-content: flex-start;
    align-items: flex-start;
    position: absolute;
    bottom: -51px;
    left: 39px;
  }

  .social-icons li {
    margin-bottom: 13px;
  }

  .hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.3s linear;
  }
}

@media screen and (min-width: 320px) and (max-width: 425px) {
  .navbar .menu-icon {
    padding: 0 !important;
  }

  .pe-7s-search,
  .pe-7s-close {
    font-size: 22px;
  }

  .navbar .search-form .search-icon {
    padding: 20px 20px !important;
  }
  .testimonials .content {
    padding-left: 0px !important;
    overflow: visible !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 425px) {
  .search-bar-mbl {
    right: -81px !important;
    bottom: -50px !important;
  }
  .team-iap-res {
    margin-left: 1px !important;
  }
  .navbar .search-form .form-group button {
    position: absolute;
    top: 16px;
    right: 22px;
    padding: 7.5px 13px;
    background: #ccc;
    border: 0;
    border-radius: 0;
  }
}

@media screen and (min-width: 426px) and (max-width: 650px) {
  .search-bar-mbl {
    right: -88px !important;
    bottom: -60px !important;
  }
}

@media screen and (min-width: 651px) and (max-width: 767px) {
  .search-bar-mbl {
    right: -90px !important;
    bottom: -40px !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 999px) {
  .search-bar-mbl {
    right: -90px !important;
    bottom: -40px !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 425px) {
  .animate-charcter {
    font-size: 15px !important;
  }

  .header-icons {
    font-size: 15px !important;
  }
}

@media (min-width: 500px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
    top: 130px !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 991px) {
  .trustee-name {
    font-size: 29px;
  }

  .trustee-texts {
    font-size: 13px;
  }

  .trustee-image {
    margin-top: 150px;
    height: 300px;
  }
}

@media screen and (min-width: 426px) and (max-width: 450px) {
  .princi-image {
    width: 394px;
  }
}

@media screen and (min-width: 451px) and (max-width: 472px) {
  .princi-image {
    width: 414px;
  }
}

@media screen and (min-width: 473px) and (max-width: 500px) {
  .princi-image {
    width: 444px;
  }
}

@media screen and (min-width: 501px) and (max-width: 525px) {
  .princi-image {
    width: 474px;
  }
}

@media screen and (min-width: 526px) and (max-width: 549px) {
  .princi-image {
    width: 498px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1000px) {
  .card-ress {
    width: 221%;
  }
}

@media screen and (min-width: 425px) and (max-width: 768px) {
  .separator {
    margin: 0 5px;
  }

  .pdf-links-cell {
    white-space: nowrap;
  }

  .hov-line {
    text-decoration: none;
  }

  .hov-line:hover {
    text-decoration: underline;
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .core-texts {
    font-size: 12px;
  }

  .core-faculty-text {
    font-size: 11px;
  }
}

.header-icons {
  margin-top: 29px;
  height: 50px;
  font-size: 17px;
}

.h-work-icon {
  margin-right: 20px;
}

.h-contact-icon {
  margin-right: 13px;
}

/* @media screen and  (min-width:1024px) and (max-width:1440px) {
    .modal-content {
      margin-bottom: 50px;
      }
      .home-gap{
        margin-top: -90px;
      }
      .home-gap-testimonial-text{
      margin-top: -92px;
      }
      .home-gap-testimonial-img{
        margin-top: -62px;
        z-index: 10 !important;
      }
  
  } */

/* .image-newsandevents{
    height: 336px;
    width: 446px;
  } */

.stu-proj-name {
  font-size: 1.3rem;
  /* Ensure consistent font size */
}

/* .news-thumbnail {
    width: 100%;
    height: 100%;
    object-fit: contain !important;
  } */
.para-psg,
.trustee-texts,
.program-description-prg {
  text-align: justify;
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
  /* .home-gap-testimonial-text{
      margin-top: -92px;
      } */
  .home-gap-testimonial-img {
    /* margin-top: -62px; */
    z-index: 100 !important;
  }

  .home-gap-team {
    margin-top: -100px;
  }

  .admin-top {
    margin-top: -80px !important;
  }

  .design-chair-quote {
    margin-top: -80px;
  }
}
@media screen  and (min-width:320px) and (max-width:520px) {
  .social-icons {
  gap: 1px !important;
  }
}
.social-icons {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 135px;
  top: 110px;
  gap: 10px;
  margin-right: -30px;
}

.social-toggle-icon {
  font-size: 1.3rem;
  margin-left: 10px;
  cursor: pointer;
  margin-top: 18px;
  margin-top: 28px;
  margin-right: -30px;
}

@media screen and (min-width: 320px) and (max-width: 575px) {
  .social-toggle-icon {
    font-size: 1rem;
    margin-right: 0px;
    margin-top: 30px;
  }

  .topnav {
    font-size: 1rem;
    margin-left: 9px;
  }
}

.trust-paragraph {
  text-align: justify;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  /* .design-img {
    margin-top: 120px !important;
  } */
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .princi-image {
    margin-left: 100px;
  }
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .prgrms {
    margin-top: -70px;
  }
}

@media screen and (min-width: 991px) and (max-width: 1199px) {
  .bord-thin-lefts {
    margin-left: 92px !important;
  }
}

@media screen and(min-width:900px) {
  .monthly-lecture-img {
    width: 100% !important;
  }
}

@media screen and (min-width: 426px) {
  .sec-pad-vid {
    margin-bottom: -90px;
  }
}

.testimonials .content {
  padding-left: 20px;
  overflow: visible !important;
}

/* .testimonials .content p {
    white-space: normal;
    word-break: break-word; 
  } */

.skeleton-card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.skeleton-card {
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skeleton-card .skeleton {
  margin-bottom: 10px;
}

.testimonial-point {
  line-height: 1.8 !important;
  text-align: justify;
}

.archi-card-container {
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Retaining the box-shadow */
  background-color: #fff;
  /* Removed border and border-radius */
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

/* Box-shadow effect on hover */
.archi-card-container:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
  /* Enhanced shadow on hover */
}

/* Image inside card */
.archi-card-image {
  background-size: cover;
  background-position: center;
  height: 130px;
  width: 100%;
  transition: transform 0.3s ease, opacity 0.3s ease;
  z-index: -1;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

/* Hover effect for image */
.archi-card-container:hover .archi-card-image {
  transform: scale(1.05);
  opacity: 0.9;
}

/* Content inside the card */
.archi-card-content {
  position: relative;
  z-index: 2;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  text-align: center;
}

.archi-card-author {
  font-size: 16px;
  font-weight: 700;
  color: #333333;
}

.archi-card-title {
  font-size: 14px;
  font-weight: 500;
  color: #555555;
  line-height: 1.5;
}

.archi-card-date {
  font-size: 12px;
  color: #777777;
}

/* Responsive Design */
@media (max-width: 991px) {
  .archi-card {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 767px) {
  .archi-card {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .go-more-psg-iap-edit {
    content: "";
    position: absolute;
    left: 0px !important;
    top: 0px;
    width: 43px;
    height: 51px;
    /* border-radius: 40px; */
    background: rgba(0, 0, 0, 0.05);
    transition: all 0.4s;
  }

  .editorial-text {
    font-weight: 400 !important;
    font-size: 17px !important;
  }
}

/* Slide-up animation for text (h1 and p) */
@keyframes slideUpText {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Apply animation to the h1 and p elements */
.gallery-heading,
.gallery-head p {
  opacity: 0;
  animation: slideUpText 0.6s ease-out forwards;
}

/* Slight delay for the title */
.gallery-heading {
  animation-delay: 0.2s;
}

/* Slight delay for the paragraph */
.gallery-head p {
  animation-delay: 0.4s;
}

/* Loader Spinner Styles */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  text-align: center;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.ourvisiontxt {
  font-weight: 600;
  font-style: italic;
}

.custom-bullet-list {
  list-style: none;
  padding-left: 20px;
}

.custom-bullet-list li {
  position: relative;
  margin-bottom: 8px;
  font-size: 16px;
}

.custom-bullet-list li::before {
  content: "";
  position: absolute;
  left: -15px;
  top: 7px;
  width: 4px;
  height: 4px;
  background-color: black;
  border-radius: 50%;
}

/* Banner wrap should cover full viewport */
.banner-wrap {
  height: 100vh !important;
  width: 100% !important;
  overflow: hidden !important;
  position: relative !important;
}

/* Video container */
.bg-video-wrap {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  overflow: hidden !important;
}

/* Video settings */
video {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  margin: 0 !important;
  padding: 0 !important;
}
.cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.card-item {
  background: #484e92;

  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 15px;
  text-align: center;
  width: 36% !important;
}

.cards-examcell {
  width: 100%;
  height: auto;
  background-color: #f9f9f9fa;
  font-size: 20px;
  font-weight: 700;
  color: #555;
  text-align: left;
  justify-content: center;
  padding: 13px;
  flex-direction: column;
  min-height: 195px;
}

/* * {
  scrollbar-width: none;
} */
.c-natification__item .link {
  width: 100%;
  display: block;
  padding: 12px;
  padding-right: 30px;
  padding-top: 17px;
  position: relative;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  font-size: 0.9375rem;
  line-height: 1.125rem;
}
.c-natification__item .link .date {
  padding: 6px;
  font-size: 0.75rem;
  line-height: 0.9rem;
  position: absolute;
  left: 8px;
  top: -15px;
  color: #fff;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  /* background-color: #df6f1c; */
  background-color: #484e92;
}

.c-natification__item .link {
  width: 100%;
  display: block;
  padding: 12px;
  padding-right: 30px;
  padding-top: 17px;
  position: relative;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  font-size: 0.9375rem;
  line-height: 1.125rem;
}

.panel-title > a {
  display: block;
  padding: 15px;
  text-decoration: none;
}
.panel-title {
  font-size: 15px;
  /* background-color: #203e54 !important; */
  background-color: #484e92 !important;
  color: #f9f1f1;
  font-weight: 600;
  border-left: 5px solid #b73538;
}

.panel-default > .panel-heading {
  padding: 0;
  border-radius: 0;
  color: #212121;
  background-color: #fafafa;
  border-color: #eeeeee;
}
/* .examcell-circular{
    background-color: #efefef;
  height: 1268px;
  width: 800px;
  } */

.usefullinks-examcell:hover {
  color: #0056b3 !important;
  text-decoration: underline !important;
}
.about-examcell {
  margin-left: -30px;
}
.shareiconres {
  height: 20px;
  width: 20px;
  margin-right: -10px;
  margin-top: -5px;
}
.life-at-campus-image {
  margin-top: 30px;
}

.archi-card-image-lifeatcampus {
  background-size: cover;
  background-position: center;
  height: 208px;
  width: 100%;
  transition: transform 0.3s ease, opacity 0.3s ease;
  z-index: -1;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.curricular-deatilpg {
  margin-top: 330px !important;
}

/* .design-img{
    height: 570px;
    width: 550px;
    margin-left: 33px;
  } */

@media (max-width: 1200px) {
  .card-curr {
    flex: 1 1 calc(50% - 16px); /* 2 columns */
  }
}

@media (max-width: 768px) {
  .card-curr {
    flex: 1 1 100% !important; /* 1 column on smaller screens */
  }
}

.card-image img {
  object-fit: cover;
}

.news-thumbnail1 {
  width: 100%;
  height: 256px;
  object-fit: cover;
}

.filter-section {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.year-filter-dropdown {
  padding: 5px 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
}

.filter-section label {
  font-size: 14px;
  font-weight: 500;
  margin-right: 8px;
  color: #333;
}

.news-thumbnail2 {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.life-at-campus-height {
  width: 100%;
  height: 100% !important;
}

.marquee-scroll {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  width: 100%;
}

.marquee-content {
  display: inline-block;
  white-space: nowrap;
  animation: marquee-scroll 20s linear infinite;
}

.marquee-scroll:hover .marquee-content {
  animation-play-state: paused;
}

@keyframes marquee-scroll {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

button.go-more {
  border: none;
  background: none;
  padding: 0;
  outline: none;
}

button.news-title-newsevents {
  border: none;
  background: none;
  padding: 0;
  outline: none;
  text-align: left;
  cursor: pointer;
}

button.news-title-newsevents:hover {
  text-decoration: underline;
}
.para-psg,
.psg-iap-contents,
.trustee-section {
  text-align: justify;
}

@media screen and (min-width: 376px) and (max-width: 485px) {
  .trustee-name {
    font-size: 28px !important;
  }
}
@media screen and (min-width: 769px) and (max-width: 991px) {
  /* Trustee message and image layout adjustments */
  .trustee-section {
    flex-direction: column;
    align-items: center;
  }

  .trustee-message,
  .trustee-image-container {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  .trustee-message h2 {
    font-size: 1.8rem; /* Adjust font size */
    line-height: 1.4; /* Adjust line height */
  }

  .trustee-message .info p {
    font-size: 1rem; /* Adjust font size for contact details */
  }

  .trustee-image {
    max-width: 80%; /* Reduce image size */
    height: auto; /* Maintain aspect ratio */
    margin: 0 auto; /* Center image */
  }

  /* Services details adjustments */
  .services-details .sec-head {
    margin: 0 auto;
    padding: 10px;
  }

  .services-details .italic.text-zoomin {
    font-size: 1.5rem;
  }

  .services-details .sub-title {
    font-size: 1rem;
  }
  .trustee-message {
    text-align: left;
    margin-bottom: 20px;
  }

  .trustee-message h6.sub-title {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }

  .trustee-message h2 {
    font-size: 1.5rem;
    line-height: 1.4;
  }

  .trustee-message .info p {
    display: flex;
    align-items: center;
    font-size: 1rem;
  }

  .trustee-message .info p span {
    display: inline-flex;
    margin-right: 10px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1005px) {
  .trustee-name {
    font-size: 25px;
  }
  .trustee-image {
    margin-top: 130px;
  }
}
@media screen and (min-width: 1006px) and (max-width: 1199px) {
  .trustee-image {
    margin-top: -24px;
    height: 500px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .img-secratary {
    margin-top: 152px;
    height: 571px;
    width: 473px;
  }
}
@media screen and (min-width: 375px) and (max-width: 425px) {
  /* .stat-cards {
    margin-left: 50px;
  } */
}
@media screen and (min-width: 496px) and (max-width: 767px) {
  .cards-space-res {
    gap: 110px;
  }
}
@media screen and (min-width: 426px) and (max-width: 479px) {
  /* .stat-cards {
    margin-left: 50px;
  } */
}

@media screen and (min-width: 480px) and (max-width: 537px) {
  /* .stat-cards {
    margin-left: 90px;
  } */
}

.committee-center-para1 {
  text-align: justify;
}

@media screen and (min-width: 320px) and (max-width: 375px) {
  .design-img {
    margin-top: 200px !important;
  }
}
@media screen and (min-width: 376px) and (max-width: 425px) {
  .design-img {
    margin-top: 130px !important;
  }
}
@media screen and (min-width: 426px) and (max-width: 547px) {
  .design-img {
    margin-top: 80px !important;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .design-img {
    margin-top: 15px !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1063px) {
  .princi-image {
    width: 480px;
    /* margin-top: 100px; */
  }
}
@media screen and (min-width: 992px) and (max-width: 2560px) {
  .exam-cell-card {
    width: 190%;
  }
  .cards-people {
    justify-content: space-around;
  }
}
.infrastructure-text {
  margin-top: 5px !important;
  font-size: 18px;
  font-weight: 700;
  color: #333333;
  text-align: center;
}
.infrastructure-img {
  object-fit: cover;
}
.pointtxt {
  text-align: justify;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ff0000;
  border: none;
  border-radius: 10px;
  width: 75px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.play-button:hover {
  background-color: #cc0000;
}

.play-icon {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 10px 18px;
  border-color: transparent transparent transparent #ffffff;
}

@media screen and (min-width: 320px) and (max-width: 575px) {
  .design-chair-res {
    margin-left: 10px !important;
  }
}
@media screen and (min-width: 426px) and (max-width: 600px) {
  .design-chair-quote {
    margin-bottom: 40px;
  }
}

.fancybox__container {
  z-index: 1050;
}
.fancybox__content {
  background-color: #000;
}

.infra-subfolder-title {
  color: black;
}
@media screen and (min-width: 992px) {
  .blo-text-na {
    margin-left: -250px;
  }
}
@media screen and (min-width: 425px) {
  .blo-text-na {
    font-size: 20px !important;
    font-weight: 500 !important;
  }
}

.h-work-icon:hover::before,
.h-library-icon:hover::before,
.h-contact-icon:hover::before {
  position: absolute;
  white-space: nowrap;
  padding: 4px 8px;
  background: #000;
  color: #fff;
  font-size: 12px;
  border-radius: 4px;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
  content: attr(data-tooltip);
}

.h-work-icon:hover::before,
.h-library-icon:hover::before {
  opacity: 1;
  transform: translateY(5px);
}

.h-work-icon:hover .h-library-icon:hover {
  position: relative;
}

.h-contact-icon:hover::before {
  opacity: 1;
  transform: translateY(5px);
}

.h-contact-icon:hover {
  position: relative;
}

.h-social-links:hover::before {
  opacity: 1;
  transform: translateY(5px);
}

.h-social-links:hover {
  position: relative;
}

.h-work-icon:hover::before,
.h-library-icon:hover::before,
.h-contact-icon:hover::before,
.h-social-links:hover::before {
  bottom: auto;
  top: calc(100% + 1px);
}

.home-heading-text {
  font-weight: 400 !important;
}
.submenu-heading-text {
  font-weight: 300 !important;
  margin-top: -50px;
}
.home-heading-text-testimonial {
  font-size: 30px;
  font-weight: 400;
}
@media screen and (min-width: 768px) and (max-width: 2560px) {
  .facts-img {
    margin-top: -10px;
  }
}
@media screen and (max-width: 425px) {
  .fifth-brother {
    margin-bottom: 50px;
  }
}
.internal-link {
  color: black;
  text-decoration: none;
  position: relative;
}

.internal-link:hover {
  color: #007bff;
  text-decoration: underline;
}

.internal-link::after {
  content: "Open PDF";
  position: absolute;
  top: 100%;
  left: 0;
  background: #f5f5f5;
  color: #333;
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 3px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  display: none;
  white-space: nowrap;
}

.internal-link:hover::after {
  display: block;
}

.internal-link,
.circular-link {
  color: black;
  text-decoration: none;
  position: relative;
}

.internal-link:hover,
.circular-link:hover {
  color: #007bff !important; /* Change to blue on hover */
  text-decoration: underline !important;
}

.internal-link::after,
.circular-link::after {
  content: "Open PDF";
  position: absolute;
  top: 100%;
  left: 0;
  background: #f5f5f5;
  color: #333;
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 3px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  display: none;
  white-space: nowrap;
}

.internal-link:hover::after,
.circular-link:hover::after {
  display: block;
}

.circular-link {
  display: block !important;
  text-align: left !important;
}
@media screen and (min-width: 769px) {
  .psgiap-submenu {
    margin-top: -45px;
  }
  .para-psg {
    margin-top: -48px !important;
  }
}

.page-header-cerv {
  background-size: cover; /* Ensures the image covers the entire header */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents repetition of the image */
}

@media (max-width: 768px) {
  .page-header-cerv {
    background-size: cover; /* Adjusts the size for smaller screens */
  }
}
.page-header-cerv {
  background-size: cover;
}

@media (max-width: 768px) {
  .page-header-cerv {
    min-height: 40vh; /* Adjust height for smaller screens */
  }
}
.timetable-year,
.timetable-years {
  background-color: #484e92;
  color: #fff;
}
.psgiap-submenu {
  font-weight: 300;
}
@media screen and (min-width: 1440px) {
  .psgiap-submenu {
    margin-top: -10px;
  }
}
@media screen and (min-width: 300px) and (max-width: 768px) {
  .para-psg {
    margin-top: -40px !important;
  }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .psgiap-submenu {
    margin-top: -130px !important;
  }
}
.committee-center-para1 {
  margin-top: -16px !important;
}

@media screen and (min-width: 300px) and (max-width: 425px) {
  .committee-center-para1 {
    margin-left: 200px !important;
    margin-top: -170px !important;
  }
  .statutory-comm {
    margin-top: 20px;
  }
}
@media screen and (min-width: 426px) {
  .committee-center-para1 {
    margin-top: -25px !important;
  }
}
@media screen and (min-width: 300px) and (max-width: 768px) {
  .prgrams-text {
    margin-top: -20px;
  }
}
@media screen and (min-width: 769px) {
  .prgrams-text {
    margin-top: -50px !important;
  }
}
.academic-tt {
  margin-top: -25px !important;
}
@media screen and (min-width: 1025px) {
  .Examcell-submenu {
    margin-top: -45px;
  }
  .exam-cell-tabs {
    margin-top: -60px !important;
  }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .Examcell-submenu {
    margin-top: -105px !important;
  }
}
@media screen and (max-width: 768px) {
  .exam-cell-res {
    margin-top: -82px !important;
  }
  .exam-cell-content-res {
    margin-top: 30px !important;
  }
}
@media screen and (min-width: 769px) {
  .admission-tabs {
    margin-top: -50px !important;
  }
}
@media screen and (min-width: 426px) {
  .cocurricular-title {
    margin-top: -25px !important;
  }
}
@media screen and (max-width: 425px) {
  .cocurricular-title {
    margin-top: 15px !important;
  }
}

/* Wrapper for the search bar */
.search-container {
  display: flex;
  justify-content: left; /* Align to the left */
  width: 100%;
  max-width: 200px; /* Adjust width of the container */
  margin-bottom: 20px; /* Space below the search bar */
}

/* Wrapper for the input and icon */
.search-input-wrapper {
  position: relative;
  width: 100%; /* Make it responsive */
}

/* Input field styling */
.search-input-wrapper input {
  width: 100%; /* Full width of the container */
  padding: 10px 35px 10px 15px; /* Add padding for the icon */
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 20px; /* Rounded corners */
  box-sizing: border-box;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

/* Search icon styling */
.search-icon-archive {
  position: absolute;
  top: -25%;
  left: 70px; /* Adjust the position inside the search box */
  transform: translateY(-50%);
  color: #888; /* Icon color */
  font-size: 16px;
  cursor: pointer;
}

/* Add focus effects */
.search-input-wrapper input:focus {
  border-color: #3a4197; /* Highlight border on focus */
  box-shadow: 0 0 5px rgba(58, 65, 151, 0.5); /* Optional shadow */
}
.archivee-tabss {
  margin-top: -50px !important;
}

.custom-modal .modal-dialog {
  max-width: 600px; /* Adjust width */
  width: 90%;
}

.custom-modal .modal-content {
  border-radius: 10px;
  padding: 20px;
  min-height: 400px; /* Adjust height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 335px;
  width: 519px;
}

.custom-modal .form-control {
  margin-bottom: 15px;
}

.custom-modal .modal-header {
  border-bottom: none;
}

.custom-modal .modal-body {
  padding: 20px 30px;
}

.custom-modal .modal-footer {
  border-top: none;
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

@media screen and (min-width: 438px) {
  .text-msg {
    margin-left: 53px;
  }
  .sub-contactus {
    margin-left: 62px;
  }
  .email-contact {
    margin-left: 80px;
  }
  .name-cntctus {
    margin-left: 45px;
  }
}

/* Modal Styling */
.custom-modal .modal-dialog {
  max-width: 600px;
  width: 90%;
  margin: auto;
}

.custom-modal .modal-content {
  border-radius: 10px;
  padding: 20px;
}

.custom-modal .modal-header {
  border-bottom: none;
}

.custom-modal .modal-body {
  padding: 20px;
}

/* Form Styling */
.form-container {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Even spacing between form fields */
}

.form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 70px;
}

.form-group label {
  font-weight: 600;
  margin-bottom: 5px;
}

.form-control {
  height: 40px;
  border-radius: 5px;
  font-size: 1rem;
  padding: 5px 10px;
  border: 1px solid #ccc;
}

textarea.form-control {
  height: auto;
  resize: none;
}

.submit-btn {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border-radius: 5px;
}

.text-danger {
  font-size: 0.9rem;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .form-group {
    height: 54px;
  }
  .custom-modal .modal-content {
    height: 200px !important;
  }
}
@media screen and (min-width: 320px) and (max-width: 500px) {
  .custom-modal .modal-content {
    width: 300px !important;
    margin-bottom: 110px;
  }
  .recaptcha-container {
    width: 290px !important;
    margin-left: 5px !important;
}
}
@media screen and (min-width: 1024px) {
  .trustee-image {
    height: 434px;
  }
}
/* @media screen and (min-width:320px) and (max-width:575px) {
  .princi-res-fixing{
    margin-left: 15px !important; 
  }
  .princi-name-ress{
    font-size: 21px !important;
  }
} */
.fifth-brother-btnn {
  color: #d8d1d1 !important;
  background-color: #484e92 !important;
  border-color: #484e92 !important;
}

.spinner-admission {
  border: 4px solid #f3f3f3 !important;
  border-top: 4px solid #3498db !important;
  border-radius: 50% !important;
  width: 30px !important;
  height: 30px !important;
  animation: spin 1s linear infinite !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinner-container-admission {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100px !important; /* Adjust based on your layout */
}

@media screen and (min-width: 376px) and (max-width: 500px) {
  .iap-docutainment {
    font-size: 20px !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 375px) {
  .iap-docutainment {
    font-size: 14px !important;
  }
}

@media screen and (min-width: 992px) and (max-width: 2560px) {
  .exam-cell-peoples {
    width: 200px;
  }
  .peoples-cards {
    gap: 70px !important;
  }
}

@media screen and (min-width: 426px) and (max-width: 768px) {
  .gallery-heading {
    margin-top: 40px !important;
    font-size: 18px !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1400px) {
  .gallery-heading {
    margin-top: 60px !important;
    font-size: 25px !important;
  }
}

.vm-margin {
  margin-right: 10px;
}

.testimonials .row {
  display: flex;
  align-items: center;
}

.left-clumn {
  max-height: 600px;
  overflow: auto;
}

.testimonial-text {
  max-height: 500px;
  overflow-y: auto;
  scrollbar-width: none !important;
}

.right-clumn .testimonial-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.background-img {
  object-fit: contain !important;
}
@media screen and (min-width: 1209px) {
  .trustee-image {
    margin-top: -32px;
  }
}
@media screen and (max-width: 576px) {
  .responsive-principal {
    padding-left: 10px !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1007px) {
  .code-wrap p {
    font-size: 10px !important;
  }
}

.no-data-message {
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: #999999;
  padding: 20px;
  margin-top: 50px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 768px) {
  .no-data-message {
    font-size: 1.2rem;
    padding: 15px;
  }
}

@media screen and (max-width: 768px) {
  /* .title-res-fixin {
    margin-top: -74px;
  } */
  .portfolio-title-res {
    margin-top: -4px !important;
  }
  .student-list-project-res {
    margin-top: -39px !important;
  }
}

@media screen and (min-width: 1400px) {
  .gallery-heading {
    margin-top: 50px !important;
  }
}
@media screen and (max-width: 768px) {
  .gallery-text {
    margin-top: -25px !important;
    text-align: justify;
  }
}
.gallery-text {
  text-align: justify;
}
@media screen and (max-width: 500px) {
  .student-list-project-res {
    margin-top: -30px !important;
  }
}
@media screen and (max-width: 425px) {
  .stu-proj-name {
    font-size: 12px !important;
  }
  .portfolio-tab-res-name {
    font-size: 10px !important;
  }
  .view-more-text-res {
    font-size: 12px !important;
  }
}

.core-card {
  min-height: 300px !important; /* Ensures the card space is reserved */
}
.card-description-4 {
  min-height: 100px !important; /* Prevents shifting */
}

.fifth-brother {
  margin-top: -140px;
}
.core-faculty {
  width: 100% !important;
  height: auto !important;
}
.archi-card-container-clubs {
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  background-color: #fff;
  overflow: hidden;
  /* border: 1px solid rgba(0, 0, 0, 0.1);*/
}

.home-pg-common-mt {
  padding-top: 50px;
  padding-bottom: 50px;
}
.home-pg-common-mt-testimonial {
  padding-top: 100px;
}
.home-pg-common-mt-princi {
  padding-top: 110px;
  /* margin-right: 45px !important; */
}
.home-pg-common-mt-blog {
  padding-top: 50px;
  padding-bottom: 100px;
}
@media screen and (min-width: 426px) and (max-width: 576px) {
  .home-pg-common-mt-princi {
    margin-left: 15px !important;
  }
}
.home-heading-text-blog {
  margin-top: 35px !important;
}
@media screen and (max-width: 496px) {
  .psgiap-submenu {
    font-size: 17px;
  }
}
@media screen and (min-width: 425px) and (max-width: 768px) {
  .academic-timetable-row {
    max-width: 500px !important ;
  }
}

@media screen and (max-width: 425px) {
  .filter-by-res {
    margin-top: 15px;
  }
}
@media screen and (max-width: 370px) {
  .res-project-list {
    margin-left: -26px !important;
  }
}
@media screen and (max-width: 991px) {
  .portfolio-tabss {
    margin-top: -55px !important;
  }
  .res-blog-box {
    margin-top: -30px !important;
  }
}
.heading-submenu-res {
  font-weight: 400 !important;
}
@media screen and (min-width: 1200px) {
  .principal-img-ressp {
    /* width: 624px; */
    margin-top: -36px;
    height: 398px;
  }
}

.core-fac-heading-res {
  margin-top: -33px !important;
}
.adminsttrative-staffs {
  margin-top: -33px !important;
}
@media screen and (min-width: 768px) {
  .administrative-staffs-contents {
    margin-top: -83px;
  }
}
@media screen and (min-width: 1400px) {
  .core-fac-heading-res {
    margin-top: -20px !important;
  }
}
@media screen and (max-width: 768px) {
  .programs-res-text {
    margin-top: 10px !important;
  }
}
.study-tour-ress {
  margin-top: 3px !important;
}
.overall-submenu-heading {
  font-size: 30px;
}
.hidescrollbar {
  scrollbar-width: none !important;
}
.highlight-text-iap {
  font-weight: bold !important;
}
.fontWeght-lifeatcampus {
  font-weight: 500;
}
.description-link {
  color: inherit !important;
  text-decoration: none !important;
}

.description-link:hover {
  color: blue !important;
  text-decoration: underline !important;
}
.hide-scroll-bar {
  scrollbar-width: none !important;
}

.admission-li ul li {
  position: relative;
}
.admission-li ul li:before {
  content: "";
  width: 8px;
  height: 7px;
  background: #656560;
  position: absolute;
  top: 8px;
  left: -15px;
  border-radius: 50%;
}
.admission-li li {
  line-height: 1.5;
  color: #424242;
  font-family: Poppins, sans-serif;
  font-size: 13.3333px;
  text-align: justify;
}

.caption {
  overflow: hidden !important;
}

@media screen and (max-width: 425px) {
  .admission-ress-view {
    font-size: 12px !important;
  }
}
.heading-submenu-res {
  text-transform: uppercase;
}
.cnct-icon:before {
  font-family: "Font Awesome 5 Free";
  content: "\f095";
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
}
.admissions-font-sizee span,
p,
li {
  font-size: 15px !important;
}
.admission-li span,
p,
li {
  font-size: 15px !important;
}
.caption {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 10px;
}
.x-logo {
  width: 12px;
  height: 12px;
  filter: invert(1);
  margin-top: -5px;
}
@media (max-width: 576px) {
  .form-group input,
  .form-group textarea {
    width: 100%;
  }
  .recaptcha-container {
    display: flex;
    justify-content: center;
  }
}
.loader-container11 {
  opacity: 1;
  transition: opacity 0.5s ease-out;
}

.loader-container11.fade-out {
  opacity: 0;
}
@media screen and (min-width: 992px) {
  .second-last-paragraph {
    margin-top: -30px !important;
  }
}

.design-chair-img {
  max-width: 100% !important;
  height: 434px !important;
}
.circle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 600px;
  height: 500px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #f0f0f0;
}

.circle-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media screen and (min-width: 576px) {
  .updated-logo {
    width: 180px;
    height: auto;
    padding: 5px;
    filter: contrast(1.2) brightness(1.1);
  }
}

@media screen and (min-width: 320px) and (max-width: 375px) {
  .design-img {
    margin-top: 70px !important;
  }
  .design-chair-img {
    max-width: 100% !important;
    height: 297px !important;
  }
}
@media screen and (min-width: 375px) and (max-width: 767px) {
  .design-img {
    margin-top: 0px !important;
  }

}
@media screen and (min-width:500px) and (max-width:991px){
  .design-img-res {
    margin-top: -130px !important;
  }
}
@media screen and (max-width: 425px) {
  .overall-submenu-heading {
    font-size: 16px;
  }
  .text-zoomin {
    font-size: 14px;
    font-weight: 400;
    text-align: justify;
  }
}
@media screen and (min-width: 426px) and (max-width: 767px) {
  .text-zoomin {
    font-size: 17px;
    font-weight: 400;
    text-align: justify;
  }
}

@media screen and (min-width: 426px) and (max-width: 560px) {
  .res-logo {
    width: 113px !important;
  }
}
@media screen and (min-width: 426px) and (max-width: 767px) {
  .overall-submenu-heading {
    font-size: 20px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .trustee-image {
    margin-top: 117px;
    height: 394px;
  }
}
@media screen and (min-width: 1006px) and (max-width: 1023px) {
  .trustee-image {
    margin-top: 101px;
    height: 468px;
  }
}
@media screen and (min-width: 1200px) {
  .img-secratary {
    height: 434px;
    max-width: 100%;
    margin-top: 125px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .img-secratary {
    margin-top: -65px;
    height: 400px;
    width: 453px;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .neumorphic {
    margin-left: 101px;
  }
}
@media screen and (min-width: 369px) and (max-width: 420px) {
  .email-container-design-chair-text {
    font-size: 13px !important;
  }
}
@media screen and (min-width: 320px) and (max-width: 368px) {
  .email-container-design-chair-text {
    font-size: 11px !important;
  }
}
@media screen and (min-width: 379px) and (max-width: 425px) {
  .principal-email-text {
    font-size: 13px !important;
  }
}
@media screen and (min-width: 326px) and (max-width: 378px) {
  .principal-email-text {
    font-size: 11px !important;
  }
}
@media screen and (min-width: 320px) and (max-width: 325px) {
  .principal-email-text {
    font-size: 10px !important;
  }
}
@media screen and (min-width: 992px) and (max-width: 1005px) {
  .principal-img-ressp {
    margin-top: 5px !important;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .principal-img-ressp {
    margin-top: 56px;
    height: 383px;
  }
}
@media screen and (min-width: 1200px) {
  .principal-img-ressp {
    width: 505px;
    margin-top: -19px;
    height: 393px;
  }
}
@media screen and (max-width: 320px) {
  .accordion .title {
    margin-left: 22px !important;
  }
}
@media screen and (min-width: 646px) and (max-width: 767px) {
  .accordion .title {
    margin-left: -15px;
  }
}
@media screen and (max-width: 767px) {
  .static-text {
    text-align: center;
  }
}
@media (max-width: 767px) {
  .contact-item {
    display: block;
    margin-bottom: 10px;
  }

  .contact-numbers {
    margin-bottom: 10px;
  }

  .footer-text {
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 768px) {
  .useful-link-footer {
    text-align: center;
  }
}
@media screen and (max-width: 767px) {
  .card-gap-res {
    margin-bottom: -50px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .card-large-gap {
    gap: 50px !important;
  }
  .card-large-gap-monthly-lecture {
    gap: 60px;
  }
  .news-thumbnail1 {
    width: 117%;
    height: 279px;
    object-fit: cover;
  }
  .card-gap-res {
    margin-bottom: -70px !important;
  }
}
@media screen and (max-width:305px) {
  .trustee-name {
font-size: 19px !important;
  }
  .administration-text{
    font-size: 19px !important;
      }
}
@media screen and (min-width: 375px) and (max-width: 767px) {
  .design-chair-img {
      max-width: 100% !important;
      height: 297px !important;
  }
}

@media screen and (min-width:320px) and (max-width: 991px) {
  .responsive-style{
    padding-right: 6px !important; 
  }
  .administrations{
    margin-top: -35px;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .cards-space-res{
    display: flex;
    justify-content: center; 
    align-items: center;
    flex-wrap: wrap; 
  }
  .cards-responsive{
    display: flex;
    justify-content: center; 
    align-items: center;
    flex-wrap: wrap;
  }
}

